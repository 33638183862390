import { Component, OnInit} from '@angular/core';
import { CommonService,AlertService } from '../_services';
import { first } from 'rxjs/operators';
import { GlobalVariable } from '../globalvariables';
import { NgxSmartModalService } from 'ngx-smart-modal';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
campaignslist= [];
photos=[];
campaign_title:string='';
campaignslist_path:string='';
  constructor(public cs:CommonService,public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
  	this.getCompainsList();
  }

getCompainsList(){
  this.cs.getCompains().pipe(first()).subscribe( res=>{
    if(res.status==GlobalVariable.api_success){
    	this.campaignslist=res.campaigns;
      this.campaignslist_path=res.file_path
    }
  });
}

campaignModel(data) {
  if(data.photos.length>0){
      this.photos=data.photos;
      this.campaign_title=data.title;
      this.ngxSmartModalService.getModal('campaignModal').open();
   }
     
 }
 closeModal(id:string) {
      this.ngxSmartModalService.getModal(id).close();
    }

}
