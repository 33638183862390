import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import {HelperService} from '../_helpers/helper'
import * as $ from 'jquery';
import {PoliceServiceService} from '../_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../globalvariables';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs;  reportObject:{[k: string]: any} = {};
  currentuser:object;
  public breadcrumbrouteData:any;
  public display_val=true;active:any;public display_navbar=true;
  constructor(public activatedRoute: ActivatedRoute,
    public router: Router,public hs:HelperService,public psService:PoliceServiceService) { }

  ngOnInit() {
    this.currentuser=this.hs.getObj("currentuser");
    //console.log(this.currentuser);
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .pipe(map(() => this.activatedRoute)) 
    .pipe(map((route) => {
      while (route.firstChild) { route = route.firstChild; }
      return route;
    }))
    .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
    .subscribe(route => {
      //console.log(route);
      let snapshot = this.router.routerState.snapshot;
      this.breadcrumbs = [];  
      if(this.router.routerState.snapshot.url == '/' || this.router.routerState.snapshot.url == '/home' || this.router.routerState.snapshot.url == '/login' || this.router.routerState.snapshot.url == '/register' || this.router.routerState.snapshot.url == '/forgotPassword' || this.router.routerState.snapshot.url == '/profile/myprofile'){
        this.display_navbar=false;
      }else{
        this.display_navbar=true;
      }
      this.breadcrumbrouteData = route.snapshot.data;
      
      if(this.breadcrumbrouteData['breadcrumb'] != ""){
        //console.log(this.router.routerState.snapshot.url);
        if (this.router.routerState.snapshot.url.includes('/quickreport?response') || this.router.routerState.snapshot.url.includes('/anonymousReport?response')) {  
            this.active = 0; 
        }else{this.active = 1;}
        //console.log(this.active);
        if(this.router.routerState.snapshot.url == '/quickreport' || this.active == 0 || this.router.routerState.snapshot.url == "/anonymousReport" || this.router.routerState.snapshot.url == "/login" || this.router.routerState.snapshot.url == "/register" || this.router.routerState.snapshot.url == "/forgotPassword"){
          this.display_val = true;
        }else{this.display_val = false;}

        this.breadcrumbs.push({
          url: this.router.routerState.snapshot.url,
          label: this.breadcrumbrouteData['breadcrumb'],
          params: this.router.routerState.snapshot.root.params
        });
      }
    });    
    this.getreportCategory();
  }
  // report a problem
  reportactivity(){
    this.router.navigate(['/quickreport']);
  }
  // report category list
  getreportCategory(){
    this.psService.reportCategory().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.reportObject.category = res.data;
        //console.log(this.reportObject.category );
        // console.log(res.message);
      }
    });
  }
   // get id's
   getCategoryID(cid){  
    this.router.navigate(['/reportCategories'],{ queryParams: { response: cid } });
  }
}
