import { Component, OnInit } from '@angular/core';
import {CommonService } from '../../_services';
import { GlobalVariable } from '../../globalvariables';
import { Router, ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';
@Component({
  selector: 'app-fire',
  templateUrl: './fire.component.html',
  styleUrls: ['./fire.component.css']
})
export class FireComponent implements OnInit {
  contactObject:{[k: string]: any} = {};
  constructor(public cs:CommonService,public router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    this.contactus();
  }

  // contact us
  contactus(){
    this.cs.getContacts().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.contactObject.contacts=res.contacts;
        console.log(res.contacts);
      }
    });
  }
}
