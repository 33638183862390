import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HelperService } from '../_helpers/helper'
import { BehaviorSubject, Observable,Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { GlobalVariable,DataService } from '../globalvariables';
import { CommonService } from './common.service';
import { first } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  isActive: Subject<any> = new Subject<any>();
  notifyChange: Subject<any> = new Subject<any>();
  profilePercentageChange: Subject<any> = new Subject<any>();
  searchResponse: Subject<any> = new Subject<any>();
  constructor(private http:HttpClient,public helper:HelperService,private router: Router,public hs:HelperService,public cs: CommonService) {
    this.getUserSubscriptionData();
    this.getUserProfileSubscriptionData(); 
    this.userIsActive();
   }
  // user subscribe
  userSubscribe(input): Observable<any>{
    return this.http.post(`${environment.apiUrl + environment.paths.newsletterSubscribe}`,input);
  }
  // register user
  userRegister(input): Observable<any>{
    return this.http.post(`${environment.apiUrl + environment.paths.register}`,input);
  }
  // otp validate
  validateotp(input): Observable<any>{
    return this.http.post(`${environment.apiUrl + environment.paths.otpValidate}`,input);
  }
  // resend otp
  resendotp(input): Observable<any>{
    return this.http.post(`${environment.apiUrl + environment.paths.resendOTP}`,input);
  }
  // create password
  createPassword(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.createPassword}`,input);
  }
  // login
  dologin(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.login}`,input);
  }
  // logout
  logout(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.logout}`);
  }
  // forgot password
  forgotPassword(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.forgetPassword}`,input);
  }
  // password Change
  passwordChange(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.changePassword}`,input);
  }
  // terms and conditions
  termsConditions(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.termsAndConditions}`,input);
  }
  // get feedback questions
  getFeedbackQuestionarie(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.getfeedbackQuestions}`);
  }
  // give feedback
  writeUserFeedback(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.writeFeedback}`,input);
  }
  // get user details
  getUserDetails(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.userDetails}`);
  }

  getUserSubscriptionData(){
    this.getUserDetails().pipe().subscribe( res=>{
       if(res.status==GlobalVariable.api_success){ 
            this.notifyChange.next(res);
        }
      })
  }
   change(){
    this.getUserSubscriptionData();  
    this.getUserProfileSubscriptionData();  
  }
  /* Response for searching */
  rechange(value){
    var input = {"search_by": value};
    this.cs.getResult(input).pipe(first()).subscribe( res => {
     // console.log(res);
      if (res.status == GlobalVariable.api_success) {
        this.searchResponse.next(res);
      }
    });
   }
   /* Response for searching */
/*profile settings*/

  //profile_settings
getUserProfileSettings(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.profile_settings}`,input);
  }


/*Profile Persentage*/
UserProfilePercentage(input):Observable<any>{
  return this.http.post(`${environment.apiUrl+environment.paths.user_profileinfo}`,input);
}

getUserProfilePercentage(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.user_profileinfo}`);
  }

  getUserProfileSubscriptionData(){
    this.getUserProfilePercentage().pipe().subscribe( res=>{
       if(res.status==GlobalVariable.api_success){ 
            this.profilePercentageChange.next(res);
        }

      })
  }
   changeProfilePercentage(){
    this.getUserProfileSubscriptionData();     
  }
/*Profile Persentage*/
/*check User is active*/
 userIsActive(){
   if(this.hs.getObj("currentuser")!=null){
     this.getUserDetails().pipe().subscribe( res=>{
       if(res.status==GlobalVariable.api_success){
            this.isActive.next(res);
        }else{
          this.isActive.next('');
        }
      });
   }else{
     this.isActive.next("");
   }
 }
/*check User is active*/ 




}
