import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import {CommonService, AlertService} from '../../_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../../globalvariables';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { google } from "google-maps";
import * as $ from 'jquery';
declare var google : google;
@Component({
  selector: 'app-citizenalert',
  templateUrl: './citizenalert.component.html',
  styleUrls: ['./citizenalert.component.css']
})
export class CitizenalertComponent implements OnInit {
  public notifyObject:{[k: string]: any} = {}; public numberOfItems:Array<any>;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;private geoCoder;  public searchElementRef: ElementRef;
  constructor(public cs:CommonService,public alertService:AlertService,public ngxSmartModalService: NgxSmartModalService,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone) { this.notifyObject.pageLimit=GlobalVariable.limit_page;}

  ngOnInit() {
    this.getAppNotification();
    this.getlocationDetails();
    this.getlatestNews();
    this.getcommunityNotifications();
    // maps start
    this.geoCoder = new google.maps.Geocoder;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
      //  console.log(this.latitude, this.longitude);
        this.getopAlerts(this.latitude, this.longitude);
        this.getrecentAlerts(this.latitude, this.longitude);
        this.geoCoder.geocode({ 'location': { lat: this.latitude, lng: this.longitude } }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              this.zoom = 12;
              this.address = results[0].formatted_address;
              $('.maplocinput').val(this.address);
            } else {
             window.alert('No results found');
        
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
            
          }
    
        });
      });
    }
        //map end
  }
  getNotifyId(nId){
    //console.log(nId);
    this.notifyObject.nId=nId;
    this.notifyObject.details=1;
    this.notifyObject.cdetails=0;
    this.getAppNotification();
    this.ngxSmartModalService.getModal('notificationModal').open();
  }
  getRecentAlertId(rId){
    //console.log(rId);
    this.notifyObject.rId=rId;
    this.getrecentAlerts("17.4371074","78.4415592");
    this.ngxSmartModalService.getModal('recentalertsModal').open();
  }
  getNewsId(newsId){
    this.notifyObject.newsId=newsId;
    this.getlatestNews();
    this.ngxSmartModalService.getModal('latestnewsModal').open();
  }
  getCommunityNotifyId(cnId){
    //console.log(cnId);
    this.notifyObject.cnId=cnId;
    this.notifyObject.cdetails=1;
    this.notifyObject.details=0;
    this.getcommunityNotifications();
    this.ngxSmartModalService.getModal('notificationModal').open();
  }
  getAppNotification(){
    this.cs.appNotification().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.notifyObject.notification = res.notification;       
        this.notifyObject.notificationlength=this.notifyObject.notification.length;
      }
    });
  }
  getlocationDetails(){
    var input=  {"latitude":GlobalVariable.latitude, "longitude":GlobalVariable.longitude };
    this.cs.getDefaultLocation(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.notifyObject.location = res.location;
       // console.log(res.location)
        this.getopAlerts(GlobalVariable.latitude,GlobalVariable.longitude);
        this.getrecentAlerts(GlobalVariable.latitude,GlobalVariable.longitude);
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        this.alertService.error(res.message);
       }
    },error => {                  
     this.alertService.error( error); 
    });
  }
  getlatestNews(){
    this.cs.newsUpdates().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.notifyObject.newsUpdates = res.latest_news;
        this.notifyObject.newsUpdateslength=this.notifyObject.newsUpdates.length;
        this.notifyObject.filepath = res.file_path;
      }
    });
  }

  getopAlerts(latitude,longitude){
    var input=  {"city_id":2} 
    //var input= { "latitude":latitude, "longitude":longitude }; 
    this.cs.topAlert(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.notifyObject.topalert = res.alerts;
        this.notifyObject.topalertlength = this.notifyObject.topalert.length;
        this.notifyObject.topalert_filepath=res.file_path;
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
       // console.log(res.message); 
       this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error(error); 
    });
  }
  getrecentAlerts(latitude,longitude){
    var input={"city_id":2 }
    //var input= { "latitude":latitude, "longitude":longitude }; 
    this.cs.recentAlert(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.notifyObject.recentalert = res.alerts;
        this.notifyObject.recentalertlength=this.notifyObject.recentalert.length;
        this.notifyObject.filepath=res.file_path;
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        //console.log(res.message); 
       this.alertService.error(res.message);
       }
    },error => {                  
     this.alertService.error(error); 
    });
  }
  // community Notifications
  getcommunityNotifications(){
    var input={"city_id":2}; 
    this.cs.communityNotificationUpdates(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.notifyObject.notificationUpdates = res.alerts;
        this.notifyObject.notificationUpdateslength=this.notifyObject.notificationUpdates.length;
        this.notifyObject.file_path = res.file_path;
      }
    });
  }

  closeModal(id:string){
    this.ngxSmartModalService.getModal(id).close();
  }
  /*pagination*/
 onChangePage(numberOfItems: Array<any>) {
  this.numberOfItems = numberOfItems;
  }
}
