import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { QuickreportRoutingModule } from './quickreport-routing.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material'; //for datepicker 
import {AgmCoreModule} from "@agm/core";//map loc search
// datepicker for a month
import { DateTimePickerModule  } from '@syncfusion/ej2-angular-calendars';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    QuickreportRoutingModule,
    FormsModule,
    ReactiveFormsModule,DateTimePickerModule ,
    NgxDaterangepickerMd.forRoot({
      format: 'DD/MM/YYYY',
    direction: 'ltr',   
    separator: ' To ', 
    cancelLabel: 'Cancel', 
    applyLabel: 'Okay', 
    clearLabel: 'Clear'   
    }),
    // maps
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA1C4_YZHU9LLFjwCiwFobpgCREkQUv44Q',
    }),
  ]
})
export class QuickreportModule { }
