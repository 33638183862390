import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable,Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PoliceServiceService {

  constructor(private http:HttpClient) { }
  // report type
  getreportSubCategory(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.reportSubCategory}`,input);
  }
 
  // report category
  reportCategory(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.reportCategory}`);
  }
  // subject list
  getsubjectList(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.subjectList}`,input);
  }
  // create report
  createReport(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.genReport}`,input);
  }
  // view report
  viewReport(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.viewReport}`,input);
  }
  // team detail
  teamDetails(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.teamDetails}`,input);
  }

  // get Police Stations
  policeStations(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.getPoliceStations}`,input);
  }
  // get Police Officers
  policeOfficers(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.getPoliceOfficer}`,input);
  }
  // get safety tips
  getsafetyTips(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.safetyTips}`);
  }
  //request Appointment
  requestAppointment(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.getrequestAppointment}`,input);
  }
  //view Appointment
  viewAppointment(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.getviewAppointment}`,input);
  }
   //view Permit
   getviewPermit(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.viewPermit}`,input);
  }

  // permit category
  permitCategory(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.permitCategory}`);
  }
   //get Permit question list
   getPermitQuestions(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.permitQuestions}`,input);
  }
  // apply permit
  applyPermitDetails(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.applyPermit}`,input);
  }
  // get otp
  getapplyPermitOTP(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.permitOtp}`,input);
  }
  // verify otp
  applyPermitOTPValid(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.permitOtpvalid}`,input);
  }
  // check Apply Permit Status
  checkApplyPermitStatus(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.permitAppstatus}`,input);
  }
  downloadFile(file,path){
    //console.log(file,path);
    console.log(path+'/'+file);
    return this.http.post(path+'/',file,{
      responseType : 'blob',
      headers : new HttpHeaders().append('content-type','application/json')
    });
  }
}
