import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportCategoriesRoutingModule } from './report-categories-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReportCategoriesRoutingModule
  ]
})
export class ReportCategoriesModule { }
