import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule,FormBuilder, FormGroup, Validators ,ReactiveFormsModule } from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';

const routes: Routes = [

];

@NgModule({
  imports: [RouterModule.forChild(routes),
    FormsModule, 
    ReactiveFormsModule,
      // ngx-translate and the loader module       
      TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  exports: [RouterModule]
})
export class ForgotPasswordRoutingModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}