import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';
import {UserAuthService,AlertService,AuthenticationService } from '../../app/_services';
import { GlobalVariable } from '../globalvariables';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import { common } from '../../assets/i18n/en.json'; 
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule } from '@angular/forms';
import {HelperService} from '../_helpers/helper'
import {TranslateService} from '@ngx-translate/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
	loginError = false ;
  submitted = false;
  currentuser:object;

  constructor(public authservice:UserAuthService,private alertService: AlertService,private formBuilder: FormBuilder, private route: ActivatedRoute,public hs:HelperService, private router: Router,private authenticationService: AuthenticationService, public translate:TranslateService) { 
    if (this.authenticationService.currentUserValue) { 
      //this.router.navigate(['/home']);
  }
  }
  get f() { return this.loginForm.controls; }

  ngOnInit() {
    // this.currentuser=this.hs.getObj("currentuser");
    // console.log(this.currentuser);
    $(".modal-backdrop").remove(); 
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
  });
    //this.forgotPassword();
    //this.changePassword();
    //this.termsAndConditions();
  }
 // login
 login(){
   this.submitted = true;
  if (this.loginForm.invalid) {
    this.loginError = true;
    console.log('form invalid'); 
      }
  else{
  var input = {"username":this.f.username.value,"password":this.f.password.value};
  this.authservice.dologin(input).pipe(first()).subscribe( res=>{
    if(res.status==GlobalVariable.api_success){
      // console.log(res.data);
     this.hs.setObj("currentuser",res.data);
     window.location.reload();
      this.router.navigate(['/home']);
      var messageBoxContent = _('common.server_issue');
      this.alertService.error(messageBoxContent); 
    }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
     this.alertService.error(res.message);
    }
  },error => {                  
    this.alertService.error( _(common.server_issue)); 
  });
  }
}
// logout
logout(){
  this.authservice.logout().pipe(first()).subscribe( res=>{
    if(res.status==GlobalVariable.api_success){
      // console.log(res.data);
      // console.log(res.message);
    }
  });
}


// terms and conditions
 termsAndConditions(){
  var input= {"status":1};
  this.authservice.termsConditions(input).pipe(first()).subscribe( res=>{
    if(res.status==GlobalVariable.api_success){
      // console.log(res.message);
      // console.log(res.data);
    }
  });
 }

 useLanguage(language: string) {
  this.translate.use(language);
}
}
