import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET, RoutesRecognized } from '@angular/router';
import {PoliceServiceService,CommonService, AlertService } from '../../../../app/_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../../../globalvariables';
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-permit-category',
  templateUrl: './permit-category.component.html',
  styleUrls: ['./permit-category.component.css']
})
export class PermitCategoryComponent implements OnInit {
  permitCategoryObject:{[k: string]: any} = {};submitted = false;selectionModel:any;
  personalDetailsForm: FormGroup;particularsForm: FormGroup;QuestionarieForm: FormGroup;
  applyPermitForm: FormGroup;other:any;state:any;city:any;shortgun:any;handgun:any;
  selected:any;selectedDate:any;minDate:any;maxDate:any;permitListjsonArrayObject=[];
  locale: any = {
    format: 'YYYY-MM-DD',
    displayFormat: 'DD MMMM YYYY',   
    applyLabel: 'Okay',
    autoUpdateInput: false
   };
  stateslist: any;
  citylist: any;
  yearselected: any;
  showDate: boolean;
  constructor(private route: ActivatedRoute,public cs:CommonService,private formBuilder: FormBuilder,public psService:PoliceServiceService,public alertService:AlertService,public ngxSmartModalService: NgxSmartModalService,public router: Router) { 
    //let date = new Date();
    this.selected={
       startDate:  moment().subtract('years', 18),
       endDate: moment().subtract('years', 18)
     }
    this.selectedDate= moment().subtract('years', 18);
    this.minDate = moment().subtract('120', 'years');
    this.maxDate = moment().subtract('years', 18);
    //console.log( this.selected.startDate);
  }
  get f() { return this.personalDetailsForm.controls; }
  get g() { return this.particularsForm.controls; }
  get h() { return this.QuestionarieForm.controls; }
  get m() { return this.applyPermitForm.controls; }
ngOnInit(): void {
    this.getStatesList();
    this.getCitiesList(2671);
    this.permitCategoryObject.step=1;this.permitCategoryObject.proofs=false;
    this.permitCategoryList();this.permitCategoryObject.proofinvalid=false;
    this.permitCategoryObject.questionchecked1 ='checked';
    this.permitCategoryObject.questioncheckbox1=false;
    this.permitCategoryObject.questionchecked2 ='checked';
    this.permitCategoryObject.questioncheckbox2=false;
    this.permitCategoryObject.questionchecked3 ='checked';
    this.permitCategoryObject.questioncheckbox3=false;
    this.permitCategoryObject.questionchecked4 ='checked';
    this.permitCategoryObject.questioncheckbox4=false;
    this.permitCategoryObject.questionchecked5 ='checked';
    this.permitCategoryObject.questioncheckbox5=false;
    this.permitCategoryObject.questionchecked6 ='checked';
    this.permitCategoryObject.questioncheckbox6=false;
    this.permitCategoryObject.questionchecked7 ='checked';
    this.permitCategoryObject.questioncheckbox7=false;
    this.permitCategoryObject.responseMessage = this.route
    .queryParams
    .subscribe(params => {
      //console.log(params['response']);
      if(params['response'] != ''){
        this.permitCategoryObject.categoryid=params['response'];
        this.getQuestionaries(this.permitCategoryObject.categoryid);
      }else{
        this.permitCategoryObject.categoryid='';
      }
    });
    this.personalDetailsForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      parentname: ['', Validators.required],      
      gender: ['', Validators.required],
      idproof: ['', Validators.required],
      image: ['', Validators.required],
      date: ['',  Validators.required],
      mobile: ['', [Validators.required, Validators.minLength(10)]],
      idproofnumber:['', Validators.required],
  });
  
  this.particularsForm = this.formBuilder.group({
    socialStatus: ['', Validators.required],
    companyname: ['', Validators.required],
    ofcaddr: ['', Validators.required],
    address: ['', Validators.required],
    station: ['', Validators.required],
    city: ['',Validators.required],
    state: ['',Validators.required],
   // companymobile: ['', [Validators.required, Validators.minLength(10),Validators.pattern('[6-9]\\d{9}')]],
   companymobile: ['', [Validators.required, Validators.minLength(10)]]
  });
  this.QuestionarieForm = this.formBuilder.group({
    question1: ['', Validators.required],
    question2: ['', Validators.required],
    question3: ['', Validators.required],
    question4: ['', Validators.required],
    question5: ['', Validators.required],
    question6: ['', Validators.required],
    question7: ['',  Validators.required],
  });
  this.applyPermitForm = this.formBuilder.group({
    armsdesc: ['', Validators.required],
    other: ['', ''],
    description: ['', Validators.required],
    terms: ['', Validators.required],
  });
  }

  //get states
  getStatesList(){
    var input = {"country_id":160};
    this.cs.getcities(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.stateslist = res.success;
        //console.log(res.success);
      }else{ 
        this.alertService.error(res.message);
        }
    });
  }

  //get cities
    getCitiesList(val){
    var input = {"state_id":+val};
    this.cs.getcitiesList(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        
         this.citylist = res.cities;
        // console.log(res.cities)
      }else{ 
        
        this.alertService.error(res.message);
        }
    });
  }
getcitysofState(val){
  this.getCitiesList(2671);
}
   // mobile number validation
   keyPress(event: any) {
    //console.log(event);
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // file upload
  processFile(inputValue: any): void {
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();
    myReader.onloadend = (e) => {
       var image = myReader.result;
       //console.log(image);
       this.permitCategoryObject.fileuploadsource = image;
    }
    myReader.readAsDataURL(file);
  }
   // datepicker keyevent 
  //  keyDateEvent($event: any) {
  //        //this.roomsFilter.date = event;
  //   const pattern = /[]/;
  //   let inputChar = String.fromCharCode($event.charCode);
  //   if ($event.keyCode != 8 && !pattern.test(inputChar)) {
  //     event.preventDefault();
  //   }
  // }
  ngModelChange(e){
    this.selected=e;
    //console.log(this.selected);
  }
  
  changeListener($event) : void {
    //console.log($event);
    this.permitCategoryObject.file = $event.target.files[0];
    //console.log('file',this.file.type);
    this.permitCategoryObject.filetype = this.permitCategoryObject.file.type;
    if(this.permitCategoryObject.file.size >  5242880)  
    // validation according to file size
    //5242880 - 5MB //1048576 - 1MB
    {
     this.alertService.error('Please upload image below 5 MB '); 
    }else{
      this.processFile($event.target);
    } 
   }
  // file upload end
  // permit category
  permitCategoryList(){
    this.psService.permitCategory().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.permitCategoryObject.category = res.category_list;
        //console.log(this.permitCategoryObject.category );
      }
    });
  }
  personalDetailsSubmit(){
    this.submitted = true;
    //console.log(this.personalDetailsForm.value);
    if (this.personalDetailsForm.invalid) {
      console.log('form invalid'); 
      this.submitted = true;
      this.permitCategoryObject.proofinvalid=true;
        }
    else{
    this.permitCategoryObject.proofinvalid=false;
    this.submitted = false;
    this.permitCategoryObject.step=2;

    }
  }
 particularsSubmit(){
    //this.submitted = true;
    //console.log(this.particularsForm.value);
    if (this.particularsForm.invalid) {
      this.submitted = true;
      console.log('form invalid'); 
        }
    else{
      this.submitted = false;
    this.permitCategoryObject.step=3;
    }
  }
permitQuestionarie(questioncheckbox,question){
    //console.log(questioncheckbox,question);

  }

  permitQuestionarieSubmit(){
    //this.submitted = true;
    //console.log(this.QuestionarieForm.value);
    if (this.QuestionarieForm.invalid) {
      this.submitted = true;
      console.log('form invalid'); 
        }
    else{
      this.submitted = false;
    this.permitCategoryObject.step=4;
    //this.getApplyPermit();
    }
  }

  goBack(step){
    this.permitCategoryObject.step=step;
  }

  proofsIDUpload(pid){
    // console.log(pid);
    if(pid !='' || pid !=undefined){
      this.permitCategoryObject.proofs=true;
      // this.personalDetailsForm.value.idproof='';
    }else{
      this.permitCategoryObject.proofs=false;
      this.permitCategoryObject.proofinvalid=false;
     }
  }
  selectGender(gender){
    //console.log(gender);
  }
  selectSocialStatus(status){
    //console.log(status);
  }
  ischecked(){
    // console.log('ischecked');
    // console.log(this.applyPermitForm.value.description);
  }
  isarmschecked(){
    // console.log('isarmschecked');
    // console.log(this.applyPermitForm.value.armsdesc);
  }
  getQuestionaries(Qid){
    var input={ "category_id":Qid};
    this.psService.getPermitQuestions(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.permitCategoryObject.questionList = res.list;
        //console.log(this.permitCategoryObject.questionList);
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        //console.log(res.message); 
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error(error); 
    });
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
     console.log([date.getFullYear(), mnth, day]);
    return [date.getFullYear(), mnth, day];
  }
  
  applyPermitSubmit(){
    if (this.applyPermitForm.invalid) {
      this.submitted = true;
      console.log(this.personalDetailsForm.value.idproof);
      console.log('form invalid'); 
        }
    else{
      this.submitted = false;
      if(this.applyPermitForm.value.description=='state'){
        this.permitCategoryObject.description="0";
      }else if(this.applyPermitForm.value.description=='city'){
        this.permitCategoryObject.description="1";
      }else{this.permitCategoryObject.description="2";}
    var input={"category":this.permitCategoryObject.categoryid, "firstname":this.personalDetailsForm.value.firstname, "lastname":this.personalDetailsForm.value.lastname, "parentname":this.personalDetailsForm.value.parentname, "dob":moment(this.personalDetailsForm.value.date).format('YYYY-MM-DD'), "gender":this.personalDetailsForm.value.gender, "id_proof":this.personalDetailsForm.value.idproofnumber,
    // this.personalDetailsForm.value.idproof, 
    "photo":this.permitCategoryObject.fileuploadsource != ''?this.permitCategoryObject.fileuploadsource:this.personalDetailsForm.value.image, 
    "mobile":this.personalDetailsForm.value.mobile, "occupation":this.particularsForm.value.socialStatus, "company_name":this.particularsForm.value.companyname, "ofc_number":this.particularsForm.value.companymobile, "ofc_address":this.particularsForm.value.ofcaddr, "permanent_address":this.particularsForm.value.address, "police_station":this.particularsForm.value.station,"city":this.particularsForm.value.city, "state":this.particularsForm.value.state,"question1":this.QuestionarieForm.value.question1 == false?"1":"0", "question2":this.QuestionarieForm.value.question2 == false?"1":"0", "question3":this.QuestionarieForm.value.question3 == false?"1":"0", "question4":this.QuestionarieForm.value.question4 == false?"1":"0", "question5":this.QuestionarieForm.value.question5 == false?"1":"0", "question6":this.QuestionarieForm.value.question6 == false?"1":"0", "question7":this.QuestionarieForm.value.question7 == false?"1":"0", "description_ofarms":this.applyPermitForm.value.armsdesc=='handgun'?"1":"0", "other_description":this.applyPermitForm.value.other, "area_carryarms":this.permitCategoryObject.description, "tnc":this.applyPermitForm.value.terms== true?"1":"0"  }
    console.log(input);
    // this.permitListjsonArrayObject.push(input);
    // console.log(this.permitListjsonArrayObject);
    this.psService.applyPermitDetails(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
       // console.log(res.permit);
        this.permitCategoryObject.responseList = res.permit;
        this.permitCategoryObject.filepath=res.file_path;
        //this.applyPermitReview();
        this.alertService.success(res.message);
        //this.permitCategoryObject.step=4;
        this.closeModal('applyPermitModal');    
        this.ngxSmartModalService.getModal('reqstsuccess').open();
        this.personalDetailsForm.reset(); this.particularsForm.reset(); 
        this.QuestionarieForm.reset(); this.applyPermitForm.reset(); this.submitted=false;
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        //console.log(res.message); 
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error(error); 
    });
    }
  }

  // review apply permit
  applyPermitReview(){
    if(this.applyPermitForm.value.description=='state'){
      this.permitCategoryObject.description=0;
    }else if(this.applyPermitForm.value.description=='city'){
      this.permitCategoryObject.description=1;
    }else{this.permitCategoryObject.description=2;}
    this.permitCategoryObject.permitList={"category":this.permitCategoryObject.categoryid, "firstname":this.personalDetailsForm.value.firstname, "lastname":this.personalDetailsForm.value.lastname, "parentname":this.personalDetailsForm.value.parentname, "dob":moment(this.personalDetailsForm.value.date).format('DD/MM/YYYY'), "gender":this.personalDetailsForm.value.gender, "id_proof":this.personalDetailsForm.value.idproof, 
    "photo":this.permitCategoryObject.fileuploadsource != ''?this.permitCategoryObject.fileuploadsource:this.personalDetailsForm.value.image, 
    "mobile":this.personalDetailsForm.value.mobile, "occupation":this.particularsForm.value.socialStatus, "company_name":this.particularsForm.value.companyname, "ofc_number":this.particularsForm.value.companymobile, "ofc_address":this.particularsForm.value.ofcaddr, "permanent_address":this.particularsForm.value.address, "police_station":this.particularsForm.value.station,"state":this.particularsForm.value.state,"city":this.particularsForm.value.city,"question1":this.QuestionarieForm.value.question1 == false?1:0, "question2":this.QuestionarieForm.value.question2 == false?1:0, "question3":this.QuestionarieForm.value.question3 == false?1:0, "question4":this.QuestionarieForm.value.question4 == false?1:0, "question5":this.QuestionarieForm.value.question5 == false?1:0, "question6":this.QuestionarieForm.value.question6 == false?1:0, "question7":this.QuestionarieForm.value.question7 == false?1:0, "description_ofarms":this.applyPermitForm.value.armsdesc=='handgun'?1:0, "other_description":this.applyPermitForm.value.other, "area_carryarms":this.permitCategoryObject.description, "tnc":this.applyPermitForm.value.terms== true?1:0  };
    //console.log(this.permitCategoryObject.permitList);
    this.ngxSmartModalService.getModal('applyPermitModal').open();
  }
  requestSubmit(){
    this.ngxSmartModalService.getModal('reqstsuccess').close();
    this.router.navigate(['policeServices/permit']);
  }
  closeModal(id:string){
    this.ngxSmartModalService.getModal(id).close();
    this.permitCategoryObject.step=4;

  }

}
