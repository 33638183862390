import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET, RoutesRecognized } from '@angular/router';
import * as $ from 'jquery';
import { UserAuthService, CommonService} from '../_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../globalvariables';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  searchresObject: {[k: string]: any} = {};
  newsObject: {[k: string]: any} = {}; public display_navbar = true;
  constructor(public activatedRoute: ActivatedRoute, public cs: CommonService, public router: Router, public authservice: UserAuthService ) {
   }
  ngOnInit() {
  
    this.getlatestNews();
    // this.openNav();
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .pipe(map(() => this.activatedRoute)) 
    .pipe(map((route) => {
      while (route.firstChild) { route = route.firstChild; }
      return route;
    }))
    .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
    .subscribe(route => {
      //console.log(this.router.routerState.snapshot.url);
      if (this.router.routerState.snapshot.url == '/login' || this.router.routerState.snapshot.url == '/register' || this.router.routerState.snapshot.url == '/forgotPassword'){
        this.display_navbar = false;
      }else{
        this.display_navbar = true;
      }
    });
  }
  openNav() {
    $("#myNav").css('width', '100%');
  }
  getlatestNews(){
    this.cs.newsUpdates().pipe(first()).subscribe( res => {
      if (res.status == GlobalVariable.api_success){
        this.newsObject.newsUpdates = res.latest_news;
        this.newsObject.filepath = res.file_path;
      }
    });
  }
  getAlerts(){
    this.router.navigate(['/policeServices/alert']);
  }
  values = '';
  searchOpen(event: any){
    if (event.keyCode == 13){
      this.values = event.target.value;
      this.authservice.rechange(this.values);
      this.router.navigate(['/SearchBar']);
  }

  }
  
}
