import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { CommonService } from '../_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../globalvariables';
import {PoliceServiceService ,AlertService} from '../_services';
import { Router, ActivatedRoute } from '@angular/router';
import {HelperService} from '../_helpers/helper';
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl, NgForm } from '@angular/forms';
import moment from 'moment';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { google } from "google-maps"; 
import * as $ from 'jquery';
declare var google : google;

@Component({
  selector: 'app-report-anonymous',
  templateUrl: './report-anonymous.component.html',
  styleUrls: ['./report-anonymous.component.css']
})
export class ReportAnonymousComponent implements OnInit {
anonymousObject:{[k: string]: any} = {};
reportObject:{[k: string]: any} = {};
currentuser:object;
createAnonymousReportForm: FormGroup;
submitted = false;
selected:any;
  minDate:any;
  maxDate:any;
latitude: number;
longitude: number;
zoom: number;
address: string;
private geoCoder;
public image:any;
public fileuploadsource:any;
@ViewChild("search",{static:true})
public searchElementRef: ElementRef;
//public witnesscheckbox=false;public suspectcheckbox=true;
public witnesscheckbox:any;public suspectcheckbox:any;public file:any;
selectedSubject:any;selectedCategoryType:any;selectedSubCategory:any;selectedCategory:any;
locale: any = {
  format: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
  displayFormat: 'DD MMMM YYYY HH:mm:ss',   
  applyLabel: 'Okay',
};
  constructor(public cs:CommonService,public psService:PoliceServiceService,public router: Router,public hs:HelperService,private formBuilder: FormBuilder,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,public alertService: AlertService) {
    this.selected= moment(new Date()).format('YYYY-MM-DD hh:mm:ss a');
    this.selected= moment().format('DD MMMM YYYY HH:mm:ss');
    this.minDate = moment().subtract(60, 'days');
    this.maxDate = moment();
   }
   get f() { return this.createAnonymousReportForm.controls; }

  ngOnInit() {
      this.currentuser=this.hs.getObj("currentuser");
      //console.log(this.currentuser);
      this.reportObject.filetype='';
      this.reportObject.suspect=this.hs.getObj("suspiciousReport");
      this.reportObject.witness=this.hs.getObj("secretWitness");
      //console.log(this.reportObject.suspect,this.reportObject.witness);
       this.reportObject.title = 'Report as Anonymous';
      if(this.reportObject.suspect == 1 && this.reportObject.witness == 0){
        this.reportObject.suspectchecked ='checked';
        this.reportObject.witnesschecked = '';
        this.reportObject.witness = 0;
        this.reportObject.witnessType = 0;
        this.witnesscheckbox = false;
        this.suspectcheckbox = true;
      }else if(this.reportObject.witness == 1 && this.reportObject.suspect == 0){
        this.reportObject.witnessType = 1
        this.reportObject.suspect = 0;
        this.reportObject.suspectchecked ='';
        this.reportObject.witnesschecked = 'checked';
        this.witnesscheckbox = true;
        this.suspectcheckbox = false;
      }
      else{
        this.reportObject.witness = 0;
        this.reportObject.suspectchecked ='checked';
        this.reportObject.witnesschecked = '';   
        this.reportObject.witnessType = 0; 
        this.witnesscheckbox = false;
        this.suspectcheckbox = false;  
      }
      // console.log(this.witnesscheckbox);
      // console.log(this.suspectcheckbox);
      this.reportObject.forminvalid=false;
      this.reportObject.invalidSubcategory = false;
      this.reportObject.invalidWitnesstype = false;
    //dropdown  items list
      this.getreportCategory();
      this.getcategoryType();
      this.createAnonymousReportForm = this.formBuilder.group({
        category : ['', Validators.required],
        subcategory : ['', ''],
        categoryType:['', ''],
        suspectstatus:['',''],status:['',''],
        subject : ['',Validators.required],
        date : ['',Validators.required],
        place : ['',Validators.required],
        imgupload : ['',''],
        desc : ['',Validators.required],
        terms : ['',Validators.required]
      });
  
      // maps
          //load Places Autocomplete
          this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
            this.geoCoder = new google.maps.Geocoder;
      
            let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
              types: ["address"]
              
            });
            autocomplete.addListener("place_changed", () => {
              this.ngZone.run(() => {
                //get the place result
                let place: google.maps.places.PlaceResult = autocomplete.getPlace();
      
                //verify result
                if (place.geometry === undefined || place.geometry === null) {
                  return;
                }
      
                //set latitude, longitude and zoom
                this.latitude = place.geometry.location.lat();
                this.longitude = place.geometry.location.lng();
                this.zoom = 12;
                this.getAddress(this.latitude, this.longitude);
              });
            });
          });
  
          $('.maplocinput').val('');
        
  }
 // datepicker keyevent 
 keyDateEvent(event: any) {
  const pattern = /[]/;
  let inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode != 8 && !pattern.test(inputChar)) {
    event.preventDefault();
  }
}
  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
        $('.maplocinput').val('');
      });
    }
  }

//draggable events part
  markerDragEnd($event: MouseEvent) {
    //console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    //console.log(latitude,longitude);
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          //console.log(this.address);
          this.getmapDetails(latitude,longitude,this.address);
          $('.maplocinput').val(this.address);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  // maps end
  // witness type
  getcategoryType(){
    this.reportObject.categoryType = [
      {id:1 ,name:'Hostile'},{id:2 ,name:'Related'},{id:3 ,name:'Eye'},
      {id:4 ,name:'Independent'},{id:5 ,name:'Solitary'},{id:6 ,name:'Material'},
      {id:7 ,name:'Trap'}
    ];
  }

  // report category
  getreportCategory(){
    this.psService.reportCategory().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.reportObject.category = res.data;
        //console.log(this.reportObject.category );
        // console.log(res.message);
      }
    });
  }

  //get id's
  getCategoryID(cid){  
    this.getReportSubCategory(cid);
    this.getSubjectList(cid);
   // return cid;
}
 // report type
 getReportSubCategory(sub_cat_id){
  var input= {"category_id":sub_cat_id};
  this.psService.getreportSubCategory(input).pipe(first()).subscribe( res=>{
    if(res.status==GlobalVariable.api_success){
      this.reportObject.subcategory = res.data;
      //console.log(res.data);
    }
  });
}
getSubCategoryID(scid){  
  this.reportObject.invalidSubcategory = false;
  this.reportObject.invalidWitnesstype = false;
  return scid;
  }
     
  // subject list
  getSubjectList(sub_id){
    var input= {"reporttype_id":sub_id};
    this.psService.getsubjectList(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
       // console.log(res.success);
        this.reportObject.subject = res.success;
      }
    });
  }
  // get id's
  getSubjectID(sid){  
    return sid;
 }
    getCategoryTypeID(ctid){  
    this.reportObject.invalidSubcategory = false;
    this.reportObject.invalidWitnesstype = false;
    return ctid;
    }

// end get id's

getmapDetails(latitude,longitude,address){
  this.reportObject.latitude = latitude;
  this.reportObject.longitude = longitude;
  this.reportObject.address = address;
  //console.log(this.reportObject.latitude,this.reportObject.longitude);
}

// file upload
processFile(inputValue: any): void {
  var file:File = inputValue.files[0];
  var myReader:FileReader = new FileReader();
  myReader.onloadend = (e) => {
     var image = myReader.result;
     //console.log(image);
     this.fileuploadsource = image;
    // this.someService.uploadImage(image).subscribe(
    //   (res) => {
    //     if(res.statusCode==200){
    //      console.log(res);
    //     }          
    //   },
    //   (err) => {
    //     console.log(err);
    //   });
  }
  myReader.readAsDataURL(file);
}
changeListener($event) : void {
  this.file = $event.target.files[0];
  this.reportObject.filetype = this.file.type;
  //console.log(this.reportObject.filetype);
  //console.log(this.file);
  if(this.file.size >  5242880)  // validation according to file size
  {
   this.alertService.error('Please upload image below 5 MB '); 
  }else{
    this.processFile($event.target);
  } 
 }
// file upload end

previewReport(form:NgForm){
  //console.log(form);
  if(form['date'] == '' || form['date'] == null){
    this.selected = '';
  }else{
    this.selected = moment(form['date']).format('YYYY-MM-DD hh:mm:ss a');
  }
  this.reportObject.proofs = this.fileuploadsource;//form['imgupload'];
  //console.log(this.reportObject.proofs);
  this.reportObject.mobile = form['mobile'];
  this.reportObject.email = form['email'];
  this.reportObject.desc = form['desc'];      
  this.reportObject.terms = form['terms']  == true?'Accepted terms & conditions':'User did not accept terms & conditions';
}
 
  // create report
  genReport(form:NgForm){
    //console.log(form);
    //console.log(this.reportObject.latitude,this.reportObject.longitude);
    this.submitted = true;
    //console.log( this.reportObject.forminvalid);
    //console.log(this.createAnonymousReportForm.value);
    //console.log(this.image);
    if (this.createAnonymousReportForm.invalid) {
        console.log('form invalid'); 
        this.reportObject.forminvalid=true;
        this.alertService.error('Please fill the required fields');
        if(this.createAnonymousReportForm.value.subcategory == undefined || this.createAnonymousReportForm.value.categoryType == undefined){
          this.reportObject.invalidSubcategory = true;
          this.reportObject.invalidWitnesstype = true;
        }else{
          this.reportObject.invalidSubcategory = false;
          this.reportObject.invalidWitnesstype = false;
        }
        $('.modal-backdrop').hide();
        $('.yessubmit').hide();$('.modal-open').css('overflow','auto');$('#previewreport').hide();
    }
    else{
      // console.log('vsfdfsd', this.reportObject.forminvalid);
      // console.log(this.createAnonymousReportForm.value.suspectstatus,this.createAnonymousReportForm.value.status);
            // console.log(this.createAnonymousReportForm.value.subcategory,this.createAnonymousReportForm.value.categoryType)
            this.reportObject.forminvalid=false;
            this.reportObject.invalidSubcategory = false;
            this.reportObject.invalidWitnesstype = false;
            //console.log(this.createAnonymousReportForm.value);
            this.createAnonymousReportForm.value.place = this.reportObject.address;
            //console.log(this.createAnonymousReportForm.value.place);
      if(this.createAnonymousReportForm.value.imgupload == undefined){
        this.createAnonymousReportForm.value.imgupload = '';
      }else{
        this.createAnonymousReportForm.value.imgupload = this.fileuploadsource;
      }
      if(this.createAnonymousReportForm.value.suspectstatus == true && this.createAnonymousReportForm.value.status == false){
        var input= {"report_type":this.createAnonymousReportForm.value.category,"sub_category": this.createAnonymousReportForm.value.subcategory, "report_subject":this.createAnonymousReportForm.value.subject, "dateof_occurence":moment(this.createAnonymousReportForm.value.date).format('YYYY-MM-DD hh:mm:ss'), "placeof_occurence":this.createAnonymousReportForm.value.place,"latitude":this.reportObject.latitude,"longitude":this.reportObject.longitude, 
      "proofs":this.fileuploadsource != ''?this.fileuploadsource:this.createAnonymousReportForm.value.imgupload, 
      "details_any":this.createAnonymousReportForm.value.desc, "tnc":this.createAnonymousReportForm.value.terms == true?'1':'0'}
      //console.log(input);
      this.cs.getAnonymousReport(input).pipe(first()).subscribe( res=>{
        if(res.status==GlobalVariable.api_success){
          //console.log(res.report,res.message,res.report.case_no);
          this.submitted = false;
          $('.modal-backdrop').hide();$('.modal-open').css('overflow','auto');$('.yessubmit').hide();$('#previewreport').hide();
            this.createAnonymousReportForm.reset();
            this.selectedCategory = undefined;
            this.selectedSubCategory = undefined;
            this.selectedSubject = undefined;
            this.suspectcheckbox = 1;
            this.witnesscheckbox = 0;this.fileuploadsource='';
            // this.createAnonymousReportForm.reset();
             this.setCurrentLocation();
            this.alertService.success('Report case no: '+ res.report.case_no +' ,please keep this reference for further communication with wepolice team. For any other details please mail to XXXXXX');
            this.router.navigate(['anonymousReport']);
          //this.router.navigate(['profile/myreport'],{ queryParams: { response: res.message } });
        }
        else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
          this.alertService.error(res.message);
        }else{ 
          this.alertService.error(res.message);
          }
      }, error=>{
        this.alertService.error(error['error']['Message']);          
      });
      }else if(this.createAnonymousReportForm.value.suspectstatus == false && this.createAnonymousReportForm.value.status == true){
      //console.log(form);
        
        // witness report
        var inputData= {"report_type":this.createAnonymousReportForm.value.category,"typeof_witness": this.createAnonymousReportForm.value.categoryType, "report_subject":this.createAnonymousReportForm.value.subject, "dateof_occurence":moment(this.createAnonymousReportForm.value.date).format('YYYY-MM-DD hh:mm:ss'), "placeof_occurence":this.createAnonymousReportForm.value.place,"latitude":this.reportObject.latitude,"longitude":this.reportObject.longitude, 
        "proofs":this.fileuploadsource != ''?this.fileuploadsource:this.createAnonymousReportForm.value.imgupload, 
        "description":this.createAnonymousReportForm.value.desc,"court_formalities":"0", "tnc":this.createAnonymousReportForm.value.terms == true?'1':'0'}
        //console.log(inputData);
        this.cs.getWitnessAnonymousReport(inputData).pipe(first()).subscribe( res=>{
          if(res.status==GlobalVariable.api_success){
            //this.alertService.success(res.message);
            this.submitted = false;
          $('.modal-backdrop').hide();$('.modal-open').css('overflow','auto');$('.yessubmit').hide();$('#previewreport').hide();
            this.createAnonymousReportForm.reset();
            this.selectedCategory = undefined;
            this.selectedCategoryType = undefined;
            this.selectedSubject = undefined;
            this.witnesscheckbox = 1;
            this.suspectcheckbox = 0;this.fileuploadsource='';
            //this.createAnonymousReportForm.reset();
             this.setCurrentLocation();
            this.alertService.success('Report case no: '+ res.report.case_no +',please keep this reference for further communication with wepolice team. For any other details please mail to XXXXXX');
            this.router.navigate(['anonymousReport']);
          }
          else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
            this.alertService.error(res.message);
          }else{ 
            this.alertService.error(res.message);
            }
        }, error=>{
          this.alertService.error(error['error']['Message']);          
        });
      }
    }
  }

  // media upload
    // image to base 64
  onSelectFile(event) {
    let reader = new FileReader();
    var self = this;
    reader.onload = function(){
      let output: any = document.getElementById('fileupload');
      self.image = output.src = reader.result;
    }
    if(event.target.files[0]){
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  // suspect activity
  suspectCategoryType(suspectType){
    //console.log(suspectType);
    if(suspectType == true || suspectType == undefined){
      this.reportObject.witnessType = 1;
      this.witnesscheckbox = true;
      
    }else{
      this.reportObject.witnessType = 0;
      this.witnesscheckbox = false;
    }
  }
  // witness activity
  witnessCategoryType(witnessType){
    //console.log(witnessType);
    if(witnessType == true || witnessType == undefined){
      this.reportObject.witnessType = 0;
      this.suspectcheckbox = true;
    }else{
      this.reportObject.witnessType = 1;
      this.suspectcheckbox = false;
    }
  }
}
