import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HelperService} from './_helpers/helper';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    public hs:HelperService,public ngxSmartModalService: NgxSmartModalService
  ){
    translate.setDefaultLang('en');
  }
  title = 'we-police';
  reportObject:{[k: string]: any} = {};
  reportObject_title='';
  ngOnInit() {
    this.reportObject.suspect=this.hs.getObj("suspiciousReport");
    this.reportObject.witness=this.hs.getObj("secretWitness");
    //console.log(this.reportObject.suspect,this.reportObject.witness);
    
    // if(this.reportObject.suspect == 1 || this.reportObject.witness == 1){
    //   this.reportObject_title = 'Report as Anonymous'
    // }else{
    //   this.reportObject_title = 'Report a Problem'
    // }
  }
}
