import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService ,PoliceServiceService} from '../../_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../../globalvariables';
import {HelperService} from '../../_helpers/helper';

@Component({
  selector: 'app-kidnap',
  templateUrl: './kidnap.component.html',
  styleUrls: ['./kidnap.component.css']
})
export class KidnapComponent implements OnInit {
  myreportObject:{[k: string]: any} = {};  currentuser:object;

  constructor(private route: ActivatedRoute,public alertService:AlertService,private router: Router,public psService:PoliceServiceService,public hs:HelperService) { }

  ngOnInit() {
    this.currentuser=this.hs.getObj("currentuser");
    //console.log(this.currentuser);
    this.getreportCategory();
    this.myreportObject.responseMessage = this.route
      .queryParams
      .subscribe(params => {
        //console.log(params['response']);
        this.myreportObject.catId = params['response'];
      });
  }
// report category list
getreportCategory(){  
  this.psService.reportCategory().pipe(first()).subscribe( res=>{
    if(res.status==GlobalVariable.api_success){
      this.myreportObject.category = res.data;
      this.myreportObject.file_path = res.file_path;
      //console.log(this.myreportObject.category );
      // console.log(res.message);
    }
  });
}
// quick report
reportProblem(catId){
  //console.log(this.currentuser);
  if(this.currentuser == null){
    this.router.navigate(['/login']);
  }else{
    this.router.navigate(['/quickreport'],{ queryParams: { response: catId } });
  }
}
}
