import {Injectable} from "@angular/core";
import {DataService } from "./../globalvariables";

@Injectable()
export class HelperService {
    public caseNo:any=''; 
    public reportActivity:{[k: string]: any} = {};
   constructor(public ds: DataService){
    localStorage.setItem("viewReportCaseNo", this.caseNo);
    localStorage.setItem("suspiciousReport", this.reportActivity.s_report);
    localStorage.setItem("secretWitness", this.reportActivity.w_report);
    localStorage.setItem("reportType", this.reportActivity.reportType);
    this.ds.reportViewCaseNumber=this.caseNo;
    this.ds.suspectActivity = this.reportActivity.s_report;
    this.ds.witnessActive =this.reportActivity.w_report;
    this.ds.reportType=this.reportActivity.reportType;
   }

   //to set the session value
   setObj(key,data){
       //console.log(data);
     //window.sessionStorage.setItem(btoa(key), btoa(JSON.stringify(data)));
     //localStorage.setItem(btoa(key), btoa(JSON.stringify(data)));
     localStorage.setItem(btoa(key), btoa(JSON.stringify(data)));
   }
   
   removeObj(key){
    localStorage.removeItem(btoa(key)); 
   }

   //to get the encoded session value
   getObj(key){
    var obj = localStorage.getItem(btoa(key));  //btoa is for encoding
    if (obj) {
        var obj = atob(obj);  //atob is for decoding
        try {
            return JSON.parse(obj);
        } catch (e) {
            return null;
        }
    } else {
        return null;
    }
   }

   //to get the quarter of the given date.
    getQuarter(dt){
        var month = dt.getMonth() + 1;
        return (Math.ceil(month / 3));
    }

    
}