import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalVariable } from '../globalvariables';
import { first, map } from 'rxjs/operators';
import {CommonService,UserAuthService,AlertService } from '../../app/_services';
import {HelperService} from '../_helpers/helper';
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  currentuser:any;
  registerForm: FormGroup;
  verifyOTP: FormGroup;
  createPassword: FormGroup;
  submitted = false;
  signupObject:{[k: string]: any} = {};
  private c:AbstractControl;
  constructor(public commonservice:CommonService,private router: Router,public helper:HelperService,public authservice:UserAuthService,private formBuilder: FormBuilder, private route: ActivatedRoute,public as:AlertService) { 
    this.signupObject.signup_step=1;
		this.signupObject.show=false;
    this.signupObject.hideNext=false;
    this.signupObject.otpError = false;
    this.signupObject.passwordError = false;
    this.signupObject.cpasswordError = false;
  }

  get f() { return this.registerForm.controls; }
  get g() { return this.verifyOTP.controls; }
  get h() { return this.createPassword.controls; }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      nId: ['', Validators.required],
      email : ['', Validators.required],
      mobile: ['', [Validators.required, Validators.minLength(10),Validators.pattern('[6-9]\\d{9}')]],
  });
  this.verifyOTP = this.formBuilder.group({
    otpcode: ['', Validators.required]
  });
  this.createPassword = this.formBuilder.group({
    mobile: ['', ''],
    password : ['', Validators.required],
    confirmPassword : ['', Validators.required]
  },{validator: this.passwordConfirming});
    this.getLanguagesList();
    this.getCountriesList();
    this.getCitiesList();
    // this.currentuser=this.helper.getObj("currentuser");

  }

  // password confirmation check
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirmPassword').value) {
        return {invalid: true  };
    }
}
  // LanguagesList
  getLanguagesList(){
    this.commonservice.getlanguages().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        //console.log(res.success);
      }
    });
  }
  // countries list
  getCountriesList(){
    this.commonservice.getcountries().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        //console.log(res.success);
      }
    });
  }
  // cities list
  getCitiesList(){
    var input = {"country_id":101};
    this.commonservice.getcities(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        //console.log(res.success);
      }
    });
  }

  goBack(step){ this.signupObject.signup_step=step;}

  // mobile number validation
  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // register
  registerUser(){
    this.submitted = true;
    if (this.registerForm.invalid) {
      console.log('form invalid'); 
        }
    else{
    //console.log(this.registerForm.value);
    var input ={"username":this.registerForm.value.username,"email":this.registerForm.value.email,"nationalid_number":this.registerForm.value.nId,"mobile":this.registerForm.value.mobile,"area_code":"+91","status":1}
    this.authservice.userRegister(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.as.success(res.message+'. Your OTP is : '+res.data['mobile_otp']);
        this.signupObject.userDetails = res.data;
        this.signupObject.signup_step=2;
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken|| res.status == GlobalVariable.multipleUser_login || res.status==GlobalVariable.api_inputvalid_error){
        this.as.error(res.error);
        this.signupObject.signup_step=1;
      }
      else{
        this.as.error(res.error);
        this.signupObject.signup_step=1;
      }
    },error=>{
      this.as.error(error);
      this.signupObject.signup_step=1;
    });
  }
  }
  // validate otp
  otpValidation(){
    this.submitted = true;
    if (this.verifyOTP.invalid) {
      console.log('form invalid');
      this.signupObject.otpError = true; 
        }
    else{
      this.signupObject.otpError = false; 
     // console.log(this.verifyOTP.value);
   var input = {"mobile":this.registerForm.value.mobile,"otp":this.verifyOTP.value.otpcode}
    this.authservice.validateotp(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.signupObject.userDetails = res.data;
        this.signupObject.signup_step=3;
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        this.as.error(res.message);
        this.signupObject.signup_step=2;
      }
      else{
        this.as.error(res.message);
        this.signupObject.signup_step=2;
      }
    },error=>{
      this.as.error(error);
      this.signupObject.signup_step=2;
    });
    }
  }
  // resend otp
  otpResend(){
    // email or mobile can be given as input
    var input = {"area_code":"+91","mobile":this.registerForm.value.mobile}
    this.authservice.resendotp(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.signupObject.userDetails.mobile_otp = res.otp;
        this.as.success(res.message+'. Your OTP is : '+res.otp);
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        this.as.error(res.message);
        this.signupObject.signup_step=2;
      }
      else{
        this.as.error(res.message);
        this.signupObject.signup_step=2;
      }
    },error=>{
      this.as.error(error);
      this.signupObject.signup_step=2;
    });
  }
  // create password
  generatePassword(){
    this.submitted = true;
    if (this.createPassword.invalid) {
      console.log('form invalid'); 
      this.signupObject.passwordError = true;
      this.signupObject.cpasswordError = true;
        }
    else{
        this.signupObject.passwordError = false;
        this.signupObject.cpasswordError = false;
    // email or mobile can be given as input
    var input = {"mobile":this.registerForm.value.mobile,"password":this.createPassword.value.password,"password_confirmation":this.createPassword.value.confirmPassword}
    this.authservice.createPassword(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.signupObject.userDetails = res.data;
        window.location.reload();
        this.router.navigate(['/home']);
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        this.as.error(res.error);
        this.signupObject.signup_step=3;
      }
      else{
        this.as.error(res.error);
        this.signupObject.signup_step=3;
      }
    }, error=>{
      this.as.error(error);  
      this.signupObject.signup_step=3;        
    });
  
  }
  }
 
}
