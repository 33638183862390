import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {HelperService} from '../../_helpers/helper'
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl } from '@angular/forms';
import {UserAuthService, AlertService,CommonService } from '../../../app/_services';
import { GlobalVariable } from '../../globalvariables';
import { first, map } from 'rxjs/operators';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})
export class MyprofileComponent implements OnInit {
  currentuser:object;details:any;terms:any;
  changePasswordForm: FormGroup;profileInfoForm: FormGroup;nationalInfoForm: FormGroup;
  accountSeiingsForm: FormGroup;
  submitted = false;
  signupObject:{[k: string]: any} = {};
  male:any;female:any;other:any;
  stateslist:any=[];
   citylist:any=[];
   countryId:number=0;
   options=["yes","no"];
   alert='';
   push_notifi='';
   newsletter='';
  constructor( private route: ActivatedRoute,private router: Router,public hs:HelperService,private formBuilder: FormBuilder,public authservice:UserAuthService,public alertService: AlertService,public cs:CommonService,public ngxSmartModalService: NgxSmartModalService) {
    this.signupObject.oldpasswordError  = false;this.signupObject.passwordError = false;
    this.signupObject.cpasswordError = false;
    this.signupObject.profileInfo=true;
    this.signupObject.nationalIdInfo=false;
    this.signupObject.changePassword=false;
    this.signupObject.notification=false;        
    this.signupObject.termsError=false;
    this.signupObject.settings=false;
    this.signupObject.activeTab="";
    this.signupObject.profilePercentage="";
    //console.log('sadsadsa');
   }
  get f() { return this.profileInfoForm.controls; }
  get g() { return this.nationalInfoForm.controls; }
  get h() { return this.changePasswordForm.controls; }
  get i(){ return this.accountSeiingsForm.controls;}

  ngOnInit() {
    this.getlocationDetails();
    this.currentuser=this.hs.getObj("currentuser");
    //console.log(this.currentuser);
    this.signupObject.editname=false;this.signupObject.editfullname=true;
    this.signupObject.fullname=this.currentuser['user'].username;
    this.savedUserDetails();
   this.profuleUpdatePercentage('id');
   
     
    // if (this.currentuser == null || this.currentuser == undefined) {
    //   this.router.navigate(['/home']);
    // }else{
    //  console.log(this.currentuser['user'],this.currentuser['token']);
    // }
    // get notifications
    this.signupObject.responseMessage = this.route
    .queryParams
    .subscribe(params => {      
      this.signupObject.count = params['response'];
      //console.log(this.signupObject.count);
      if(this.signupObject.count == undefined || this.signupObject.count < 0){
        this.signupObject.profileInfo=true;this.signupObject.nationalIdInfo=false;
        this.signupObject.changePassword=false;this.signupObject.notification=false;
      }else{
        this.signupObject.profileInfo=false;this.signupObject.nationalIdInfo=false;
        this.signupObject.changePassword=false;this.signupObject.notification=true;
      }
    });
    // get notifications end

    this.getNotifyList();
    this.profileInfoForm = this.formBuilder.group({
      image: ['', ''],
      name : ['', Validators.required],
      gender: ['', ''],
      email: ['', ''],
      mobile: ['', ''],
      city:['', Validators.required],
      state:['', Validators.required],
      address:['', Validators.required],
    });
    this.nationalInfoForm = this.formBuilder.group({
      nationalId: ['', ''],
      name : ['', Validators.required],
      image: ['', Validators.required],
      terms: ['', Validators.required],
    });
    
    this.changePasswordForm = this.formBuilder.group({
      mobile: ['', ''],
      old_password : ['', Validators.required],
      password : ['', Validators.required],
      confirmPassword : ['', Validators.required]
    },{validator: this.passwordConfirming});

    this.accountSeiingsForm = this.formBuilder.group({
      alert : ['', Validators.required],
      location: ['', Validators.required],
      push_notifi: ['', Validators.required],
      newsletter:['',Validators.required]
    })
  }
    // password confirmation check
    passwordConfirming(c: AbstractControl): { invalid: boolean } {
      if (c.get('password').value !== c.get('confirmPassword').value) {
          return {invalid: true  };
      }
  }
  // get notifications
  getNotifyList(){
    this.cs.getNotifications().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.signupObject.notifyList = res.notifications_list;
      }
    });
  }
  // get user details
  savedUserDetails(){
    this.authservice.getUserDetails().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.signupObject.userDetails = res.user_details;  
       
        /*setting*/  
        this.signupObject.userDetails.preferred_location = res.user_details[0].preferred_location == null ? " " :  res.user_details[0].preferred_location; 
        this.alert=res.user_details[0].alert_type;
        this.push_notifi=res.user_details[0].push_notifications;
        this.newsletter=res.user_details[0].news_subscribe;
       /*setting*/ 

       // console.log(res.user_details);
       this.getCitysList(this.signupObject.userDetails[0].state);
        this.signupObject.userDetails.forEach(element => {
          this.currentuser['user'].gender=element.gender;
        });
        this.signupObject.userDetails.address="Another";
        this.signupObject.userDetailsIdproof = res.idproof_filepath;
        this.signupObject.userDetailsFilepath = res.photo_filepath;
      }
    });
  }
  getNotifyListId(nId){
    // console.log(nId);
    this.getDetailList(nId);
  }
  // get detail notification
  getDetailList(notifyId){
    var input={"notify_id":notifyId } 
    this.cs.getdetailNotifyList(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.ngxSmartModalService.getModal('notificationModal').open();
        this.signupObject.detailnotifyList = res.notification;
        this.signupObject.notifyId=notifyId;
        // console.log(this.signupObject.notifyId);
        }else if(res.status == GlobalVariable.api_token_error || res.status == GlobalVariable.multipleUser_login || res.status == GlobalVariable.unauthorizedError){
          this.alertService.error(res.error);
        }else{ 
          this.alertService.error(res.error);
          }
      },
      error=>{
        this.alertService.error(error);          
      });
    }

  //edit name
  editdetails(data){
    if(data=='editfullname'){
      this.signupObject.editname=true;
      this.signupObject.fullname=this.profileInfoForm.value.name;
    }
  }

  ischecked(){
    this.currentuser['user'].gender='';
  }
  termscheck(data){
    //console.log(data);
    if(data==true){
      this.signupObject.termsError=true;}else{ this.signupObject.termsError=false;}
  }
  // close
  close(data){
    if(data=='editfullname'){
      this.signupObject.editname=false;
    }else{
      this.signupObject.editname=true;
    }
  }
      // file upload
      processFile(inputValue: any): void {
        var file:File = inputValue.files[0];
        var myReader:FileReader = new FileReader();
        myReader.onloadend = (e) => {
           var image = myReader.result;
           //console.log(image);
           this.signupObject.fileuploadsource = image;
        }
        myReader.readAsDataURL(file);
      }
      changeListener($event) : void {
        //console.log($event);
        this.signupObject.file = $event.target.files[0];
        //console.log('file',this.file.type);
        this.signupObject.filetype = this.signupObject.file.type;
        if(this.signupObject.file.size >  5242880)  
        // validation according to file size
        //5242880 - 5MB //1048576 - 1MB
        {
         this.alertService.error('Please upload image below 5 MB '); 
        }else{
          this.processFile($event.target);
        } 
       }
      // file upload end
  // profile Info
  profileInfo(){
    this.submitted = true;
    if(this.profileInfoForm.invalid) {
      console.log('form invalid'); 
    }else{
      this.profileInfoForm.value.image = this.signupObject.fileuploadsource;
      //console.log(this.signupObject.fileuploadsource);
      //console.log(this.profileInfoForm.value.address);
      if(this.profileInfoForm.value.gender=='male'){
        this.profileInfoForm.value.gender=1;
      }else if(this.profileInfoForm.value.gender=='female'){
        this.profileInfoForm.value.gender=2;
      }else if(this.profileInfoForm.value.gender=='other'){
        this.profileInfoForm.value.gender=3;
      }else{
        this.profileInfoForm.value.gender=this.currentuser['user'].gender;
      }
      //alert(this.profileInfoForm.value.address);
      var input={"upload_pic":this.signupObject.fileuploadsource != ''?this.signupObject.fileuploadsource:this.profileInfoForm.value.image,"fullname":this.profileInfoForm.value.name,"gender":this.profileInfoForm.value.gender,"address":this.profileInfoForm.value.address,"city":+this.profileInfoForm.value.city,"state":+this.profileInfoForm.value.state} ;
      //console.log(input);
      this.cs.profileInfoSave(input).pipe(first()).subscribe( res=>{
        if(res.status==GlobalVariable.api_success){
          this.alertService.success(res.message);
          //console.log(this.currentuser);
          this.signupObject.editname=false;
          this.signupObject.profile_filepath=res.file_path;
          this.postUserDetails(this.profileInfoForm.value.name);
          this.authservice.change();
          this.profileInfoForm.reset();
          this.submitted=false;         
        }else{ 
          this.alertService.error(res.message);
          }
    }, error=>{
      this.alertService.error(error);          
    });
  }
  }

// post User Details
postUserDetails(username){
  var input={"fullname":username};
  this.cs.updateUserDetails(input).pipe(first()).subscribe( res=>{
    if(res.status==GlobalVariable.api_success){
      //alert("address");
      this.signupObject.userDetails = res.user_details; 
      //console.log(res.user_details);     
    
    }else{ 
      this.alertService.error(res.message);
      }
}, error=>{
  this.alertService.error(error);          
});

}

  // national id info
  nationalInfo(){
    this.submitted = true;
    //console.log(this.nationalInfoForm.value);
    if(this.nationalInfoForm.invalid) {
      console.log('form invalid'); 
      if(this.nationalInfoForm.value.terms==false || this.nationalInfoForm.value.terms==undefined){
        this.signupObject.termsError=true;}else{ this.signupObject.termsError=false;}
    }else{
      this.nationalInfoForm.value.image = this.signupObject.fileuploadsource;
      var input={"upload_proof":this.signupObject.fileuploadsource != ''?this.signupObject.fileuploadsource:this.nationalInfoForm.value.image,"fullname":this.nationalInfoForm.value.name,"tnc":this.nationalInfoForm.value.terms==true?1:0} ;
      //console.log(input);
      this.cs.profileInfoSave(input).pipe(first()).subscribe( res=>{
        if(res.status==GlobalVariable.api_success){
          this.signupObject.termsError=false;
          this.alertService.success(res.message);
          //console.log(this.currentuser);
          this.signupObject.profile_filepath=res.file_path;
          this.nationalInfoForm.reset();
          this.authservice.changeProfilePercentage();
          this.savedUserDetails();
          this.submitted=false;         
        }else{ 
          this.alertService.error(res.message);
          }
    }, error=>{
      console.log(error['message']);
      this.alertService.error(error['message']);          
    });
    }
  }
  // changePassword
  changePassword(){
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
    this.signupObject.oldpasswordError  = true;
    this.signupObject.passwordError = true;
    this.signupObject.cpasswordError = true;
      console.log('form invalid'); 
        }
    else{
    var input= {"old_password":this.changePasswordForm.value.old_password,"new_password":this.changePasswordForm.value.password,"confirm_password":this.changePasswordForm.value.confirmPassword};
    //console.log(input);
    this.authservice.passwordChange(input).pipe(first()).subscribe( res=>{      
      if(res.status==GlobalVariable.api_success){       
        this.alertService.success(res.message);
        this.changePasswordForm.reset();
        this.savedUserDetails();
      }else{ 
        
        this.alertService.error(res.message);
        }
      },
      error=>{
        this.alertService.error(error['error']['Message']);          
      });
  }
  }
  getMyprofileView(data){
    //console.log(data);
   // this.activeTab(data)
    if(data == 'profileInfo'){
      this.signupObject.profileInfo=true;this.signupObject.nationalIdInfo=false;
      this.signupObject.changePassword=false;this.signupObject.notification=false;
      this.signupObject.settings=false
    }else if(data == 'nationalIdInfo'){
      this.signupObject.profileInfo=false;this.signupObject.nationalIdInfo=true;
      this.signupObject.changePassword=false;this.signupObject.notification=false;
      this.signupObject.settings=false
    }else if(data == 'changePassword'){
      this.signupObject.profileInfo=false;this.signupObject.nationalIdInfo=false;
      this.signupObject.changePassword=true;this.signupObject.notification=false;
      this.signupObject.settings=false
    }else if(data == 'settings'){
      this.signupObject.profileInfo=false;this.signupObject.nationalIdInfo=false;
      this.signupObject.changePassword=false;this.signupObject.notification=false;
      this.signupObject.settings=true
    }else{
      this.signupObject.profileInfo=false;this.signupObject.nationalIdInfo=false;
      this.signupObject.changePassword=false;this.signupObject.notification=true;
      this.signupObject.settings=false
    }
  }
  closeModal(id:string){
    this.ngxSmartModalService.getModal(id).close();
    this.getNotifyList();
  }

   // cities list
  getStatesList(){
    var input = {"country_id":this.countryId};
    this.cs.getcities(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.stateslist = res.success;
      }else{ 
        this.alertService.error(res.message);
        }
    });
  }
  getCitysList(val){
    var input = {"state_id":+val};
    this.cs.getcitiesList(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        //console.log(res.cities);
         this.citylist = res.cities;
      }else{ 
        
        this.alertService.error(res.message);
        }
    });
  }
getcitysofState(val){
  this.getCitysList(val);
}

getlocationDetails(){
    var input=  {"latitude":GlobalVariable.latitude, "longitude":GlobalVariable.longitude };
    this.cs.getDefaultLocation(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
         this.countryId=res.location.country_id;
         this.getStatesList();
      }else {
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error( error); 
    });
  }
 activeTab(tab){
this.signupObject.activeTab=tab;
 }

  updateSettings(){
    this.submitted = true;
    if(this.accountSeiingsForm.invalid) {
      console.log('form invalid'); 
    }else{
    let req= { "alert_type":this.accountSeiingsForm.value.alert, "preferred_location":this.accountSeiingsForm.value.location,"push_notifications":this.accountSeiingsForm.value.push_notifi,"news_subscribe":this.accountSeiingsForm.value.newsletter} 
    this.authservice.getUserProfileSettings(req).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
         this.alertService.success(res.message);
         this.authservice.changeProfilePercentage();
      }
    });
    }
  }

 profuleUpdatePercentage(id){
    this.authservice.UserProfilePercentage(id).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
      this.signupObject.profilePercentage=res.profile;
      //console.log(res.profile);
      }else {
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error( error); 
    });
 }

}
