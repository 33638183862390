import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HelperService} from '../_helpers/helper';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    currentUser:object;routeUrl:any;
    constructor(private router: Router,private hs: HelperService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.currentUser = this.hs.getObj("currentuser");
        //console.log(this.currentUser);
        //console.log(route);
        if (this.currentUser) {    
            this.routeUrl=route['_routerState'].url;
            //console.log(this.routeUrl);  
            this.hs.setObj("routeUrl", this.routeUrl);     
            //return this.router.navigate([this.routeUrl]);
            return true;
        }
    }
}