import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { KnowareaRoutingModule } from './knowarea-routing.module';
import {AgmCoreModule} from "@agm/core";//map loc search
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnowareaRoutingModule,
    BrowserAnimationsModule,BrowserModule,
    FormsModule, ReactiveFormsModule,
    NgxSmartModalModule.forRoot(),
    // maps
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA1C4_YZHU9LLFjwCiwFobpgCREkQUv44Q',
    }),
  ]
})
export class KnowareaModule { }
