import { Component, OnInit } from '@angular/core';
import {PoliceServiceService, AlertService } from '../../../app/_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../../globalvariables';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as $ from 'jquery';

@Component({
  selector: 'app-permit',
  templateUrl: './permit.component.html',
  styleUrls: ['./permit.component.css']
})
export class PermitComponent implements OnInit {
  permitObject:{[k: string]: any} = {};  submitted = false;
  checkStatusForm: FormGroup;validateOTPForm: FormGroup;
  constructor(public psService:PoliceServiceService,public router: Router,public alertService:AlertService,private formBuilder: FormBuilder,public ngxSmartModalService: NgxSmartModalService) { 
    this.permitObject.step = 'checkStatus';
    this.permitObject.otpvalidatestep=false;this.permitObject.otpvalidate=false;
    this.permitObject.otpinvalid=false;
  }

  get f() { return this.checkStatusForm.controls; }
  get g() { return this.validateOTPForm.controls; }

  ngOnInit() {
    this.permitCategoryList();
    this.checkStatusForm = this.formBuilder.group({
      application: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.minLength(10),Validators.pattern('[6-9]\\d{9}')]],
  });
  this.validateOTPForm = this.formBuilder.group({
    otpvalid: ['', '']
});
  }
  // permit category
  permitCategoryList(){
    this.psService.permitCategory().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.permitObject.category = res.category_list;
        //console.log(this.permitObject.category );
      }
    });
  }
  // on select category create permit
  permitgetId(pid){
    //console.log(pid);
    this.router.navigate(['policeServices/permitCategory'],{ queryParams: {'response':pid} });
  }
  // mobile number validation
  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // get otp
  verifyOTP(){
    this.submitted = true;
    if (this.checkStatusForm.invalid) {
      console.log('form invalid'); 
      this.permitObject.otpvalidatestep=false;
      this.validateOTPForm.reset();
      this.permitObject.otpvalidate=false;
      this.permitObject.otpinvalid=false;
      //this.submitted = false;
        }
    else{
      //console.log(this.checkStatusForm.value);
    var input={ "app_no":this.checkStatusForm.value.application, "mobile":this.checkStatusForm.value.mobile, "area_code":"+91" };
    this.psService.getapplyPermitOTP(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.permitObject.permitOtp = res.otp;
        //console.log(this.permitObject.permitOtp);
        this.alertService.success(res.message + '. OTP is : ' + this.permitObject.permitOtp);
        this.permitObject.step = 'checkStatus';
        this.permitObject.otpvalidatestep=true;
        //this.permitObject.step='verifyOTP';
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        //console.log(res.message); 
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error(error); 
    });
  }
  }
  keyvalidate(data:any){
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(data.charCode);
    if (data.keyCode != 8 && !pattern.test(inputChar)) {
      data.preventDefault();
    }
  }
  // validate otp
  otpvalidate(){
    //console.log(this.validateOTPForm.value.otpvalid);
    this.submitted = true;
    if (this.validateOTPForm.invalid) {
      console.log('form invalid'); 
      this.permitObject.otpinvalid=true;
        }
    else{
      if(this.validateOTPForm.value.otpvalid=='' || this.validateOTPForm.value.otpvalid==null){
        this.permitObject.otpinvalid=true;
        this.permitObject.otpvalidatestep=true;
      }else{
        this.permitObject.otpinvalid=false;
      }
      if(this.validateOTPForm.value.otpvalid==this.permitObject.permitOtp){
        this.permitObject.otpvalidate=false;
        this.permitObject.otpvalidatestep=false;
        this.otpSubmit();
      }else{
        this.permitObject.otpvalidate=true;
        this.permitObject.otpvalidatestep=true;

      }
    }
  }
//  verify otp
  otpSubmit(){
    this.submitted = true;
    if (this.validateOTPForm.invalid) {
      console.log('form invalid'); 
        }
    else{
    this.permitObject.otpvalidatestep=false;
    var input={ "app_no":this.checkStatusForm.value.application, "mobile":this.checkStatusForm.value.mobile, "otp":this.permitObject.permitOtp };
    //console.log(input);
    this.psService.applyPermitOTPValid(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        //this.alertService.success(res.message);
        this.checkApplicationStatus(this.checkStatusForm.value.application);
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        //console.log(res.message); 
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error(error); 
    }); 
  }
  }
  // check Application Status
  checkApplicationStatus(appNumber){
    //console.log(appNumber);
    var input={"app_no":appNumber}; 
    this.psService.checkApplyPermitStatus(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.permitObject.permitDetails = res.details;
        this.permitObject.filepath = res.file_path;
        //console.log(this.permitObject.permitDetails);
        this.alertService.success(res.message+' is pending.');
        this.permitObject.step = 'checkStatus';
        this.ngxSmartModalService.getModal('verifyModal').open();
        this.validateOTPForm.reset();
        this.checkStatusForm.reset();
        this.submitted=false;
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        //console.log(res.message); 
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error(error); 
    }); 
  }
  // close popup modal
  closeModal(id:string){
    this.ngxSmartModalService.getModal(id).close();
    this.validateOTPForm.reset();
    this.checkStatusForm.reset();
    this.submitted=false;
  }
}
