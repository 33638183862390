import { Component, OnInit,Injectable  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {HelperService} from '../_helpers/helper';
import {UserAuthService} from '../_services';
import {Subscription} from 'rxjs';
const MINUTES_UNITL_AUTO_LOGOUT = 30;//in mins
const CHECK_INTERVAL = 1800;//in ms
const STORE_KEY =  'lastAction';
@Injectable()
@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {
  currentuser:object;public helpFunction;islogin:{[k: string]: any} = {};
  checkisUserActive:Subscription;
  isUserActive:boolean;
  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }
  constructor( private route: ActivatedRoute,private router: Router,public hs:HelperService,public auth:UserAuthService) { 
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY,Date.now().toString());
    this.islogin.route=false;
    this.checkisUserActive=auth.isActive.subscribe((user)=>{
      if(user!='')
        this.isUserActive=true;
      else
         this.isUserActive=false;
    });
    
  }

  ngOnInit() {
    this.auth.userIsActive();
    this.currentuser=this.hs.getObj("currentuser");
    //console.log(this.currentuser);
    if(this.currentuser != null){
      this.helpFunction=this.hs.getObj("routeUrl");  
      //console.log(this.helpFunction);
      this.islogin.route=true;
      this.router.navigate(["'"+this.helpFunction+"'"]);
    }
    else{
      this.islogin.route=false;
      this.router.navigate(['/home']);
    }
    
  }
  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover',()=> this.reset());
    document.body.addEventListener('mouseout',() => this.reset());
    document.body.addEventListener('keydown',() => this.reset());
    document.body.addEventListener('keyup',() => this.reset());
    document.body.addEventListener('keypress',() => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }
  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout)  {
      localStorage.clear();
      window.location.reload();
      this.router.navigate(['/home']);
    }
  }
}
