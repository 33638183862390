import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable,Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HelperService } from '../_helpers/helper'

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  constructor(private http:HttpClient,public helper:HelperService,private router: Router) { }
  
    getLeagalIssueTypes(): Observable<any>{
      return this.http.get(`${environment.apiUrl+environment.paths.legal_issues_type}`);
    }

    getLeagalSubCat(int): Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.issue_regards}`,int);
    }

    saveLegalAdvice(int): Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.legal_advice}`,int);
    }

    getAllLegalIssues(req): Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.view_legaladvice}`,req);
    }

}
