import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { UserAuthService } from '../_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
 export class SearchBarComponent implements OnInit {
  searchresObject: {[k: string]: any} = {};
  public _response : Subscription;

  constructor(public ngxSmartModalService: NgxSmartModalService, private router: Router, public authservice: UserAuthService) {
    this._response = authservice.searchResponse.subscribe((val) => {
      console.log(val);
      this.searchresObject.stips = val.safety_tips;
      this.searchresObject.tipspath = val.safetytips_file;
      this.searchresObject.alertspath = val.alerts_file;
      this.searchresObject.alerts = val.alerts;
      this.searchresObject.notifi = val.notifications;
      this.searchresObject.cmspage = val.cmspages;
     });

  }
   ngOnInit(): void {
   }
  expandResult(data) {
    this.searchresObject.data = data;
    this.searchresObject.image = data.image;
    this.ngxSmartModalService.getModal('expandModal').open();
  }
  closeModal(id: string) {

    this.ngxSmartModalService.getModal(id).close();
  }



}
