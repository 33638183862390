import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable,Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HelperService } from '../_helpers/helper'

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http:HttpClient,public helper:HelperService,private router: Router) { }
  
  // languages list
  getlanguages(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.languagesList}`);
  }
  // countries list
  getcountries(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.countriesList}`);
  }
  // cities list
  getcities(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.statesList}`,input);
  }

  // help line
  gethelpline(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.helpline}`);
  }

  // pin red flag
  pinredflag(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.pinRedFlag}`,input);
  }

  // detail notification
  notificationDetails(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.notifyDetail}`,input);
  }
  // get notifications
  getnotifications(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.notifyCount}`);
  }

  // report category
  reportCategory(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.reportCategory}`);
  }

  //crimetrends
  getCrimetrends(input):Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.crimetrends}`,input);
  }

  // know your area
  getAreaDetails(input):Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.areadetail}`,input);
  }
  // get anonymous report
  getAnonymousReport(input):Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.anonymousReport}`,input);
  }
   //User feedback
   userfeedback(): Observable<any>{
    return this.http.get(`${environment.apiUrl+environment.paths.UserFeedback}`);
  }
    // local activity
    getlocalActivities(): Observable<any>{
      return this.http.get(`${environment.apiUrl+environment.paths.localactivities}`);
    }
    // witness api
    getWitnessAnonymousReport(input):Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.reportWitness}`,input);
    }
    // notification count
    notifyCount(): Observable<any>{
      return this.http.get(`${environment.apiUrl+environment.paths.notificationCount}`);
    }
    // latest news
    newsUpdates(): Observable<any>{
      return this.http.get(`${environment.apiUrl+environment.paths.latestNews}`);
    }
    // app notification
    appNotification(): Observable<any>{
      return this.http.get(`${environment.apiUrl+environment.paths.appNotifications}`);
    }
    // top alerts
    topAlert(input):Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.topAlerts}`,input);
    }
    // recent alerts
    recentAlert(input):Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.recentAlerts}`,input);
    }
    //get Default Location
    getDefaultLocation(input):Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.getlocation}`,input);
    }
    // community Notifications
    communityNotificationUpdates(input): Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.communityNotifications}`,input);
    }
    // notification list
    getNotifications(): Observable<any>{
      return this.http.get(`${environment.apiUrl+environment.paths.notificationsList}`);
    }
    // detail notifications
    getdetailNotifyList(input): Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.detailNotificationList}`,input);
    }
    // save profile info
    profileInfoSave(input): Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.profileInfo}`,input);
    }
    // update User Details
    updateUserDetails(input): Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.postProfileInfo}`,input);
    }
    // contact us
    getContacts(): Observable<any>{
      return this.http.get(`${environment.apiUrl+environment.paths.contactus}`);
    }
    

    getcitiesList(input): Observable<any>{
      return this.http.post(`${environment.apiUrl+environment.paths.citiesList}`,input);
    }

getRolls(): Observable<any>{
      return this.http.get(`${environment.apiUrl+environment.paths.officer_roles}`);
    }
 getCompains(): Observable<any>{
      return this.http.get(`${environment.apiUrl+environment.paths.campaigns}`);
    }
  getResult(input): Observable<any>{
    return this.http.post(`${environment.apiUrl+environment.paths.search_bar}`,input);
  }
}
