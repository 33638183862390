import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl } from '@angular/forms';
import {UserAuthService,AlertService,AuthenticationService } from '../../app/_services';
import { GlobalVariable } from '../globalvariables';
import { Router, ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  verifyOTP: FormGroup;
  createPassword: FormGroup;
  submitted = false;
  signupObject:{[k: string]: any} = {};
  private c:AbstractControl;
  selectedCategory:any;
  mobile:any;
  constructor(public formBuilder: FormBuilder,public authservice:UserAuthService,private alertService: AlertService,public router: Router,private authenticationService: AuthenticationService, public route: ActivatedRoute) { 
    this.signupObject.signup_step=1;
		this.signupObject.show=false;
    this.signupObject.hideNext=false;
    this.signupObject.mobileOremail = false;
    this.signupObject.otpError = false;
    this.signupObject.passwordError = false;
    this.signupObject.cpasswordError = false;
  }
  get f() { return this.forgotPasswordForm.controls; }
  get g() { return this.verifyOTP.controls; }
  get h() { return this.createPassword.controls; }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      mobileOremail: ['', Validators.required]
    });
    this.verifyOTP = this.formBuilder.group({
      otpcode: ['', Validators.required]
    });
    this.createPassword = this.formBuilder.group({
      mobile: ['', ''],
      password : ['', Validators.required],
      confirmPassword : ['', Validators.required]
    });
    this.createPassword = this.formBuilder.group({
      mobile: ['', ''],
      password : ['', Validators.required],
      confirmPassword : ['', Validators.required]
    },{validator: this.passwordConfirming});
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirmPassword').value) {
        return {invalid: true  };
    }
}
  // forgot password
  forgotPassword(){
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      console.log('form invalid');
      this.signupObject.mobileOremail = true; 
        }
    else{
      this.signupObject.mobileOremail = false;
      var input = {"area_code":"+91","username":this.f.mobileOremail.value};
      this.authservice.forgotPassword(input).pipe(first()).subscribe( res=>{
        if(res.status==GlobalVariable.api_success){
           this.alertService.success(res.message+'. Your OTP is : '+res.OTP);
           this.signupObject.userDetails = res;
           this.signupObject.signup_step=2;
        }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
          this.alertService.error(res.message);
          this.signupObject.signup_step=1;
        }
        else{
          this.alertService.error(res.message);
          this.signupObject.signup_step=1;
        }
      },error=>{
        this.alertService.error(error);
        this.signupObject.signup_step=1;
      }
      );

    }
  }
  // go a step back
  goBack(step){ this.signupObject.signup_step=step;}
   // validate otp
   otpValidation(){
    this.submitted = true;
    if (this.verifyOTP.invalid) {
      console.log('form invalid');
      this.signupObject.otpError = true; 
        }
    else{
      this.signupObject.otpError = false; 
   var input = {"mobile":this.f.mobileOremail.value,"otp":this.verifyOTP.value.otpcode}
    this.authservice.validateotp(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.signupObject.userDetails = res.data;
        this.signupObject.signup_step=3;
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        this.alertService.error(res.message);
        this.signupObject.signup_step=2;
      }
      else{
        this.alertService.error(res.message);
        this.signupObject.signup_step=2;
      }
    },error=>{
      this.alertService.error(error);
      this.signupObject.signup_step=2;
    });
    }
  }
  // resend otp
  otpResend(){
    var input = {"area_code":"+91","mobile":this.f.mobileOremail.value}
    this.authservice.resendotp(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.signupObject.userDetails.OTP = res.otp;
        this.alertService.success(res.message+'. Your OTP is : '+res.otp);
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        this.alertService.error(res.message);
      }else{
        this.alertService.error(res.message);
      }
      },error=>{
        this.alertService.error(error);
      });
    }
  
  
  // create password
  generatePassword(){
    this.submitted = true;
    if (this.createPassword.invalid) {
      console.log('form invalid'); 
      this.signupObject.passwordError = true;
      this.signupObject.cpasswordError = true;
        }else{
        this.signupObject.passwordError = false;
        this.signupObject.cpasswordError = false;
    var input = {"mobile":this.f.mobileOremail.value,"password":this.createPassword.value.password,"password_confirmation":this.createPassword.value.confirmPassword}
    this.authservice.createPassword(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.signupObject.userDetails = res.data;
        window.location.reload();
        this.router.navigate(['/home']);
      }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        this.alertService.error(res.error);
        this.signupObject.signup_step=3;
      }
      else{
        this.alertService.error(res.error);
        this.signupObject.signup_step=3;
      }
    },error=>{
      this.alertService.error(error);
      this.signupObject.signup_step=3;
    });
  
  }
  }
}
