import { NgModule } from '@angular/core';
import { PreloadAllModules, Router,Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// components
// profile
import { MyreportComponent } from './profile/myreport/myreport.component';
// Landing Screen
import { LandingScreenComponent } from './landing-screen/landing-screen.component';
import { FeedbackComponent } from './landing-screen/feedback/feedback.component';
// global
import { KnowareaComponent } from './knowarea/knowarea.component';
import { PinredflagComponent } from './pinredflag/pinredflag.component';
import { HelplineComponent } from './helpline/helpline.component';
import { QuickreportComponent } from './quickreport/quickreport.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { GlobalactivityComponent } from './globalactivity/globalactivity.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FireComponent } from './contact/fire/fire.component';
import { AreaComponent } from './community-service/area/area.component';
import { AdviceComponent } from './community-service/advice/advice.component';
import { CommunityfeedbackComponent } from './community-service/communityfeedback/communityfeedback.component';
import { CitizenalertComponent } from './police-service/citizenalert/citizenalert.component';
import { PermitComponent } from './police-service/permit/permit.component';
import { SafetyComponent } from './police-service/safety/safety.component';
import { FindpshComponent } from './police-service/findpsh/findpsh.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {PermitCategoryComponent} from './police-service/permit/permit-category/permit-category.component';
// carousel slider
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import {ReportAnonymousComponent} from './report-anonymous/report-anonymous.component';
// report categories
import { KidnapComponent } from './report-categories/kidnap/kidnap.component';
// material
import {MaterialModule} from './material-module';
import {MatNativeDateModule} from '@angular/material/core';
import {PshContactsComponent} from './police-service/psh-contacts/psh-contacts.component';
import { AuthGuard } from './_guards';
import { MyprofileComponent } from './profile/myprofile/myprofile.component';//my profile
import { SearchBarComponent } from './search-bar/search-bar.component';

const desktop_routes: Routes = [
  {
    path: '',
    component: LandingScreenComponent
  },
  {
    path: 'home',
    component: LandingScreenComponent
  },
  {
    path: 'about',
    component: AboutComponent,
    data:{breadcrumb:'About We Police'}
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'helpLine',
    component: HelplineComponent,
    data:{breadcrumb:'Helplines'}
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'knowArea',
    component: KnowareaComponent,
    data:{breadcrumb:'Know your area'}
  },
   // report categories
   { path: 'reportCategories', component: KidnapComponent, data: { breadcrumb: 'Report Categories'}}, 
   { path: 'policeServices/alert', component: CitizenalertComponent, data: { breadcrumb: 'Police Services > Alerts & Notifications'}}, 
   { path: 'policeServices/safety', component: SafetyComponent, data: { breadcrumb: 'Police Services > Safety Tips'}}, 
   {
    path: 'communityServices/knowArea',
    component: KnowareaComponent,
    data:{breadcrumb:'Community Services > Know Your Area'}
  },
  {
  path: 'SearchBar',
  component: SearchBarComponent,
  data:{breadcrumb:'search response'}
  },
  {
    path: 'anonymousReport',
    component: ReportAnonymousComponent,
    data:{breadcrumb:'Report Anonymous'}
  },
  {
    path: 'contacts',
    component: FireComponent,
    data:{breadcrumb:'Contacts'}
  },
    { path: 'policeServices/fpsh', component: FindpshComponent, data: { breadcrumb: 'Police Services > Find a Police station/Hospital'}},
    { path: 'policeServices/officerView', component: PshContactsComponent, data: { breadcrumb: 'Police Services > Police stations and Contacts'}},
   //for logged in users.
   { 
    path: '',
    canActivate: [AuthGuard], 
    children: [
  {
    path: 'feedback',
    component: FeedbackComponent,
    data:{breadcrumb:'Write a Feedback'}
  },
  {
    path: 'pinRedFlag',
    component: PinredflagComponent,
    data:{breadcrumb:'Pin a red flag'}
  },
  {
    path: 'quickreport',
    component: QuickreportComponent,
    data:{breadcrumb:'Report a problem'}
  },
  {
    path: 'notification',
    component: GlobalactivityComponent,
    data:{breadcrumb:'Notification'}
  },
 
  // Community Services 
  {
    path: 'communityServices/advice',
    component: AdviceComponent,
    data:{breadcrumb:'Community Services > Get Legal Advice'}
  },
  {
    path: 'communityServices/feedback',
    component: FeedbackComponent,//CommunityfeedbackComponent,
    data:{breadcrumb:'Community Services > Give Feedback to police'}
  },

  { path: 'policeServices/permit', component: PermitComponent, data: { breadcrumb: 'Police Services > Apply for permit'}}, 
  { path: 'policeServices/permitCategory', component: PermitCategoryComponent, data: { breadcrumb: 'Police Services > Apply for permit > Permit Category'}}, 
/*  { path: 'policeServices/fpsh', component: FindpshComponent, data: { breadcrumb: 'Police Services > Find a Police station/Hospital'}},
  { path: 'policeServices/officerView', component: PshContactsComponent, data: { breadcrumb: 'Police Services > Police stations and Contacts'}},*/
  {
    path: 'profile/myprofile',
    component: MyprofileComponent,
    data:{breadcrumb:'Profile > My Profile'},
  },
  {
    path: 'profile/myreport',
    component: MyreportComponent,
    data:{breadcrumb:'Profile > My Reports'}
  },
  ]},
  // otherwise redirect to home
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [FormsModule, ReactiveFormsModule ,Ng2CarouselamosModule,MaterialModule,MatNativeDateModule,
    BrowserModule,NgxSmartModalModule.forRoot(),BrowserAnimationsModule,  
    RouterModule.forRoot(desktop_routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  public constructor(private router: Router) {  
    router.resetConfig(desktop_routes);   
  }
 }
