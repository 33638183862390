import { Component, OnInit } from '@angular/core';
import {UserAuthService,AlertService } from '../../_services';
import { GlobalVariable } from '../../globalvariables';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router'
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl, NgForm } from '@angular/forms';
import { StarRatingComponent } from 'ng-starrating';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  landingObject:{[k: string]: any} = {};
  feedbackForm: FormGroup;formSubmit: FormGroup;userfeedbackForm: FormGroup;
  submitted = false;
   totalstar = 5;
   feedback_error=0;

  /*Textbox*/
  description:string="";
  description_any:string="";
  selectedSuggestion:string="";
  error:any=[];
  type_checkbox_array=[];

  constructor(public userauth:UserAuthService,private as: AlertService,public ngxSmartModalService: NgxSmartModalService,private formBuilder: FormBuilder,private router: Router) {
    this.landingObject.star_val="";
    this.landingObject.feedback_step=1;
    this.landingObject.error=false;
    this.landingObject.feedback=false;
   }
   get f() { return this.feedbackForm.controls; }
   get h() { return this.userfeedbackForm.controls; }
   get g() { return this.formSubmit.controls; }

  ngOnInit() {
    this.getQuestions();
    this.getSuggestionTopics();
    this.getQuestionOptions();


    this.feedbackForm = this.formBuilder.group({
      qid: ['', ''],      
      answer: ['',''],
      //answer: ['', Validators.required],
  });
  this.userfeedbackForm = this.formBuilder.group({
    qid: ['', ''],      
    //answer: ['',''],
    desc: ['', Validators.required],
    answer: ['', Validators.required],
});
  this.formSubmit = this.formBuilder.group({
    topic: ['', ''],
    suggestion: ['', '']
  });
  

  }

  
  getQuestions(){
    this.userauth.getFeedbackQuestionarie().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        console.log(res.questions);
        this.landingObject.questions = res.questions;
        this.landingObject.questions[0].options=this.getSuggestionTopics();
        this.landingObject.questions[1].options=this.getQuestionOptions();
        /*adding static Questions */
        this.landingObject.questions[res.questions.length]="";
      /*  this.landingObject.questions[2]=this.createQuestion(2);
        this.landingObject.questions[3]=this.createQuestion(3);*/
        this.landingObject.length = res.questions.length;
        console.log(this.landingObject.questions,this.landingObject.length);
      }else{ 
        this.as.error(res.message);
        }
    }, error=>{
      this.as.error(error);          
    });
  }
  /*Question1*/
  getSuggestionTopics(){
    this.landingObject.suggestionTopics = [
      {id:1 ,name:'Application Performance'},{id:2 ,name:'Content Related'},{id:3 ,name:'User Interaction'}
    ];
    return this.landingObject.suggestionTopics;
  }
  /*Question2*/
  getQuestionOptions(){
    this.landingObject.questionOptions = [
      {id:1 ,name:'Extremley Useful'},{id:2 ,name:'Very Well'},{id:3 ,name:'Somewhat Well'},{id:4 ,name:'Not So Well'},{id:5 ,name:'Not at Well'}
    ];
    return this.landingObject.questionOptions;
  }
  webServicesFeedback(){
    this.landingObject.feedback_step=2;
  }

  writeFeedback(form:NgForm){
    console.log(form);
    this.submitted = true;
    if (this.feedbackForm.invalid) {
      console.log('form invalid'); 
        }
    else{
      console.log(form);
    this.landingObject.feedback_step=2;
    }
   }
   writeUserFeedback(form:NgForm){
    console.log(form);
    this.submitted = true;
    if (this.userfeedbackForm.invalid) {
      console.log('form invalid'); 
      this.landingObject.error=true;
        }
    else{
      console.log(form);
      this.landingObject.error=false;
      this.landingObject.desc = this.userfeedbackForm.value.desc;
    this.landingObject.feedback_step=3;
    }
   }
   giveFeedback(sid){
    this.landingObject.feedback=true;
    return sid;
   }
   
  closeModal(id:string){
    this.ngxSmartModalService.getModal(id).close();
  }
  
  goBack(step){ 
    console.log(step);
    this.landingObject.feedback_step=step;
    console.log(this.landingObject.feedback_step);
  }

/*Next step*/
  nextQuestion(i){
    console.log(this.landingObject.questions,i)
    // Validation
    if(this.landingObject.questions[i-1].answer=="" || this.landingObject.questions[i-1].answer == undefined){
      this.feedback_error=i;
      return true;
    }else{
      this.feedback_error=0;
      this.landingObject.feedback_step=i+1; 
    }
    
  }
  /*Back*/
  backOneStep(){
    this.landingObject.feedback_step-=1;
  }


/*static Question for Timebeing*/

createQuestion(qid){
   //rating
  if(qid==2){
   return {"question_id":3,"question_type":"rating","question":"Rate wepolice APP?","sequence_no":3,"options":"","answer":"3"};
  }
  //Text Box
   if(qid==3){
   return {"question_id":4,"question_type":"text","question":"Provide Your words about App?","sequence_no":4,"options":"","answer":""};
  }

}


 /*Check Box Value */
  updateSome(event,index) {
    if(this.type_checkbox_array[index]==undefined){
       this.type_checkbox_array[index]=[];
      this.type_checkbox_array[index].push(+event.target.value);
    }
    else{
        let i=this.type_checkbox_array[index].indexOf(+event.target.value)

        if(i>-1) {
          this.type_checkbox_array[index].splice(i, 1);
        }else{
          this.type_checkbox_array[index].push(+event.target.value);
        }
    }
   this.landingObject.questions[index]['answer']=this.type_checkbox_array[index];
   // console.log(this.type_checkbox_array[index]);
  } 

/*Rating Onchange*/
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent},index) {
   this.landingObject.star_val=$event.newValue;
   
  }

  /*Submit and Preview*/
  submitFeedback(){
  this.validation();
     let qid=[];
     let answer=[];
     this.landingObject.questions.map((q,i)=>{
       //console.log(i);
       if(this.landingObject.length-1>i){
         qid.push(q.question_id);
         answer.push(q.answer);
       }
        
     })
 
    var input={ "question_id":qid, "answer":answer,"description_any":this.description_any,"rating":this.landingObject.star_val,"suggestions":this.selectedSuggestion, "feedback_description":this.description }
    this.userauth.writeUserFeedback(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.as.success(res.status);
        this.router.navigate(['home']);
      }else{ 
        this.as.error(res.error);
        }
    }, error=>{
      this.as.error(error);          
    });
    
   }
  //  preview 
   preview(id){
     this.validation();
    this.ngxSmartModalService.getModal(id).open();
   }

   validation(){
     if(this.landingObject.star_val=="" || this.description_any==""){
       this.error=[];
        this.feedback_error=this.landingObject.length;

        if(this.landingObject.star_val=="") this.error.push(1);

        if(this.description_any=="") this.error.push(2);

        console.log(this.error);
        return ;
        /*if(this.selectedSuggestion!="" && this.landingObject.description=="") this.error.push(3);
        || (this.selectedSuggestion!="" && this.description=="")
       */
     }

   }

}
