import { Component, OnInit } from '@angular/core';
import {CommonService } from '../../app/_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../globalvariables';
@Component({
  selector: 'app-globalactivity',
  templateUrl: './globalactivity.component.html',
  styleUrls: ['./globalactivity.component.css']
})
export class GlobalactivityComponent implements OnInit {

  constructor(public commonservice:CommonService) { }

  ngOnInit() {
    //this.notifyDetails();
    this.notifications();
  }
  notifyDetails(){
    var input = {"notify_id":2};
    this.commonservice.notificationDetails(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        console.log(res.notifications);
      }
    });
  }
  notifications(){
    this.commonservice.getnotifications().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        console.log(res.notifications);
      }
    });
  }
}
