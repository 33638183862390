import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HelperService} from '../_helpers/helper'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public hs:HelperService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentUser =this.hs.getObj("currentuser");
    //console.log(currentUser);
    if (currentUser && currentUser['token']){
        req = req.clone({
        setHeaders: {
            'Content-Type' : 'application/json',
            'Accept'       : 'application/json',
            'Authorization': `Bearer ${currentUser['token']}`,
        },
        });
    }
    return next.handle(req);
  }
}