import { Component, OnInit } from '@angular/core';
import {PoliceServiceService, AlertService,CommonService} from '../../_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../../globalvariables';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import * as _ from 'lodash';
@Component({
  selector: 'app-psh-contacts',
  templateUrl: './psh-contacts.component.html',
  styleUrls: ['./psh-contacts.component.css']
})
export class PshContactsComponent implements OnInit {
  public psOfficerObject:{[k: string]: any} = {};
  ciyslist:any=[];
  rolls:any=[];
  default_city:any="";
  searchKey : string="";
  public dataSource :Array<any>=[];
  public sortBy: string = '';
  public sortDirection: string = 'desc';
  public columnsToDisplay = ['PS_name', 'officer_name', 'officer_number','station_number','station_email'];
  ofiicers_list :Array<any>=[];
  constructor(public cs:CommonService,public ps:PoliceServiceService,public alertService:AlertService) {
   this.searchKey="";
   }

  ngOnInit(): void {
    this.getAllRolls();
    this.getlocationDetails();

  }
  getAllRolls(){
   this.cs.getRolls().pipe(first()).subscribe( res=>{
     console.log(res);
      if(res.status==GlobalVariable.api_success){
         this.rolls = res.roles;
      }else {
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error( error); 
    });
  }
  getPSDetails(){
  var input=  {"city_id":this.default_city }  
    this.ps.policeStations(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.psOfficerObject.psdetails = res.Stations;
        this.dataSource=res.Stations;
      }else {
      
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error( error); 
    });
  }
  
  getOfficersDetails(){
    var input= {"city_id":this.default_city,"role_id":"4"  }; 
    this.ps.policeOfficers(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.psOfficerObject.podetails = res.Officer_Details;
        this.ofiicers_list=res.Officer_Details;
        this.psOfficerObject.podetails_path=res.file_path;
      }else{
        //console.log(res.message); 
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error( error); 
    });
  }

getlocationDetails(){
    var input=  {"latitude":GlobalVariable.latitude, "longitude":GlobalVariable.longitude };
    this.cs.getDefaultLocation(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        //console.log(res);
        this.getCityList(res.location.country_id);
      }else{
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error( error); 
    });
  }

    getCityList(cid){
    let req={"country_id":cid }
    //"country_id":160 
    this.cs.getcitiesList(req).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.ciyslist=res.cities;
        this.default_city=this.ciyslist[0].city_id;
    this.getPSDetails();
    this.getOfficersDetails();
      }else{
        this.alertService.error(res.message);
       }
      },error => {                  
      this.alertService.error( error); 
    });
  }


columnSortClick(column,col_id){

this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
this.sortBy = col_id;

  let data=this.dataSource;
  this.dataSource=this.sortValue(data,column,this.sortDirection);
}

sortValue(value, column: string, order:any){
   return _.orderBy(value, [column], [order]);
}

isSorting(name: string) {
    return this.sortBy !== name && name !== '';
};

isSortAsc(name: string) {
    var isSortAsc: Boolean = this.sortBy === name && this.sortDirection === 'asc';
    return isSortAsc;
};

isSortDesc(name: string) {
    var isSortDesc: any = this.sortBy === name && this.sortDirection === 'desc';
    return isSortDesc;
};

searchResult(){

  if(this.searchKey === ''){
   this.dataSource = this.psOfficerObject.psdetails;
 }

  let data=this.psOfficerObject.psdetails;
  this.dataSource=data.filter(list=>{
  const string:string=`${list.PS_name} ${list.officer_name}`;
  return string.toLowerCase().includes(this.searchKey.toLowerCase());
  });
}

officerDicignation(val){
 let data = this.psOfficerObject.podetails;
  console.log(val);
  this.ofiicers_list=data.filter(list=>{
  const string:string=`${list.role}`;
  return string.toLowerCase().includes(val.toLowerCase());
  });
}

changeCity(val){
  console.log(val);
  this.searchKey="";
  this.default_city=val;
  this.getPSDetails();
  this.getOfficersDetails();
}

}
