export const GlobalVariable = Object.freeze({
    title:'Welcome to We-police!!!',
    login:'Login',
    api_success:'200',
    api_nodata:'203',
    api_error:'500',
    api_token_error:'201',
    multipleUser_login:'406',
    api_unregisteredEmail:'501',
    api_invalidToken:'404',
    api_inputvalid_error:'222',
    serverError:'422',
    unauthorizedError:'401',
    serviceKey:'AIzaSyDU-mVNbIpzUPktj8bawWQWxgka_1UY3IU',
    device_platform_a:'Android',
    device_platform_i:'IOS',
    limit_page:10,
    unknown_error:'OOPS!Something went wrong.',
    token_error:'Token error,Please try again',
    multiuserlogin_error:'User already logged in another device',
    status:'Status',
    latitude:'9.0820° N',
    longitude:'8.6753° E',
    lat:9.0820,
    long:8.6753
});
import { Injectable } from '@angular/core';

@Injectable() 
export class DataService {
    deviceInfo:{};
    isMobile:boolean;
    device:string;
    Appversion:string;
    //deviceinfor:{}; //to store mobile device details
    userId:string;
    accessToken:string;
    site_settings:any={};
    currentUser:{};
    reportViewCaseNumber:string;
    suspectActivity:any;
    witnessActive:any;
    reportType:any;
    public tempdata=[];
    constructor(){
       
    }
}
