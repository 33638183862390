import { Component, OnInit } from '@angular/core';
import {CommonService,AlertService } from '../../app/_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../globalvariables';

@Component({
  selector: 'app-helpline',
  templateUrl: './helpline.component.html',
  styleUrls: ['./helpline.component.css']
})
export class HelplineComponent implements OnInit {
  helplineObject:{[k: string]: any} = {};

  constructor(public commonservice:CommonService,public as: AlertService) { }

  ngOnInit() {
    this.getHelpLine();
  }
  // help line
  getHelpLine(){
    this.commonservice.gethelpline().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.helplineObject.data = res.success;
        this.helplineObject.enumbers = res.Emergency_Numbers;
      }else if(res.status == GlobalVariable.api_token_error || res.status == GlobalVariable.multipleUser_login || res.status == GlobalVariable.unauthorizedError || res.status == GlobalVariable.serverError){
        this.as.error('No helpline numbers found,Please try again');
      }else{ 
        this.as.error('OOPS!Something went wrong,Please try again');
        }
    }, error=>{
      this.as.error(error);          
    });
  }
}
