import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalactivityRoutingModule } from './globalactivity-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GlobalactivityRoutingModule
  ]
})
export class GlobalactivityModule { }
