import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalVariable } from '../../globalvariables';
import {CommonService, AlertService,PoliceServiceService} from '../../_services';
import { first, map } from 'rxjs/operators';
@Component({
  selector: 'app-findpsh',
  templateUrl: './findpsh.component.html',
  styleUrls: ['./findpsh.component.css']
})
export class FindpshComponent implements OnInit {
  public fpshObject:{[k: string]: any} = {}; 

hospitalList:any;
 map:any;
infowindow:any;
ciyslist:any=[];
selected:number;
markers:any=[];
 @ViewChild("search",{static:true})
  public searchElementRef: ElementRef;
  
  @ViewChild('map-canvas',{static:true}) mapElement:ElementRef;

  latitude: number;
  longitude: number;
  zoom: number;
  constructor(public router: Router,private ngZone: NgZone,public cs:CommonService,public alertService:AlertService,public ps:PoliceServiceService) {  
    this.fpshObject.hospitals=[];
    this.fpshObject.police_stations=[];
    this.fpshObject.fps=true;
    this.fpshObject.fhospital=false;
  }

  ngOnInit() {
    this.markers=[];
    this.fpshObject.viiii= true;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude =  GlobalVariable.lat; //position.coords.latitude;
        this.longitude = GlobalVariable.long;//position.coords.longitude;
        let info={lat: GlobalVariable.lat,lng: GlobalVariable.long};
        this.markers.push(info);
        this.zoom = 20;
       // console.log(this.latitude,this.longitude);
      });
    }
    
    // this.addressSearchbox();
    // this.getMyLocation();
    this.getlocationDetails();
  }
  findPoliceStation($event){
    //console.log($event.target.value);
    this.fpshObject.fps=true;
    this.fpshObject.fhospital=false;
   // this.initialize(this.latitude,this.longitude);
  }
  findHospitals($event){
    this.fpshObject.fps=false;
    this.fpshObject.fhospital=true;

    this.addressSearchbox();
    this.initialize(this.latitude,this.longitude);
   
  }

  viewAll(){
     this.router.navigate(['policeServices/officerView']);
  }


/*my code*/

 initialize(lat,long) {
   //console.log(lat,long);
  var pyrmont = new google.maps.LatLng(lat, long); // sample location to start with: Mumbai, India
  this.map = new google.maps.Map(document.getElementById('map-canvas'), {
    center: pyrmont,
    zoom: 15
  });

  var request = {
    location: pyrmont,
    radius: 1000,
    types: ['hospital'] 
  };
  this.infowindow = new google.maps.InfoWindow();
  var service = new google.maps.places.PlacesService(this.map);
service.nearbySearch(request, (results, status)=>{
  if (status == google.maps.places.PlacesServiceStatus.OK) {
    this.fpshObject.hospitals=results;
    //console.lo
    for (var i = 0; i < results.length; i++) {
  this.createMarker(results[i]);
}
}
});
}

/*mark Hospital*/
 createMarker(place) {
  var placeLoc = place.geometry.location;
  var marker = new google.maps.Marker({
    map: this.map,
    position: place.geometry.location
  });

  google.maps.event.addListener(marker, 'click',() => {
   // console.log(this);
    this.infowindow.setContent(place.name);
    this.infowindow.open(this.map, marker);
  });
}
/*end My code*/


 addressSearchbox() {
  let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            types: ["address"] });
          autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
            
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
    
              //set latitude, longitude and zoom
              this.latitude = place.geometry.location.lat();
              this.longitude = place.geometry.location.lng();
              this.zoom = 12;
//console.log(this.latitude, this.longitude);
              this.initialize(this.latitude, this.longitude);
            });
          });
}

getlocationDetails(){
    var input=  {"latitude":GlobalVariable.latitude, "longitude":GlobalVariable.longitude };
    this.cs.getDefaultLocation(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        //console.log(res);
        this.getCityList(res.location.country_id);
      }else{
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error( error); 
    });
  }
  getCityList(cid){
    let req={"country_id":cid }
    //"country_id":160 
    this.cs.getcitiesList(req).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        //console.log(res.cities);
        this.ciyslist=res.cities;
      }else{
        this.alertService.error(res.message);
       }
      },error => {                  
      this.alertService.error( error); 
    });
  }
  selectCityName(val){
    //console.log(val);
    var list=this.ciyslist.filter(c=>c.city_name==val);
    
    this.getPoliceStations(list[0]);
  
    console.log(list);
  }
  getPoliceStations(val){

    let req={"city_id":val.city_id};
    this.ps.policeStations(req).pipe(first()).subscribe( res=>{
      //console.log(res.Stations);
      if(res.status==GlobalVariable.api_success){
        this.fpshObject.police_stations=res.Stations;
          if(res.Stations.length>0){
            this.latitude =  this.fpshObject.police_stations[0].latitude;
            this.longitude = this.fpshObject.police_stations[0].longitude;
            //console.log( this.longitude , this.latitude);
            this.markers=[];
              res.Stations.forEach(item => { 
               this.markers.push({lat: item.latitude,lng: item.longitude})
               });
              this.zoom = 5;
          }
          //console.log(this.markers);
       }else{
        this.alertService.error(res.message);
       }
      },error => {                  
      this.alertService.error( error); 
    });
  }
}
