import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import {CommonService,PoliceServiceService,AlertService } from '../../app/_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../globalvariables';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl } from '@angular/forms';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { google } from "google-maps";
import * as $ from 'jquery';
declare var google : google;
import { Router } from '@angular/router';

@Component({
  selector: 'app-pinredflag',
  templateUrl: './pinredflag.component.html',
  styleUrls: ['./pinredflag.component.css']
})
export class PinredflagComponent implements OnInit {
  reportObject:{[k: string]: any} = {};
  pinRedFlagForm: FormGroup;
  submitted=false;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;  public fileuploadsource:any;
  @ViewChild("search",{static:true})
  public searchElementRef: ElementRef;
  selectedSubCategory:any;selectedCategory:any;place:any;mobile:any;desc:any;terms:any;
  category: any;
  subcategory: any;
  constructor(public commonservice:CommonService,private as: AlertService,public psService:PoliceServiceService,private formBuilder: FormBuilder,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,public router: Router) { }
  get f() { return this.pinRedFlagForm.controls; }

  ngOnInit() {
    this.getreportCategory();
    this.pinRedFlagForm = this.formBuilder.group({
      category : ['', Validators.required],
      subcategory : ['', Validators.required],
      place : ['',Validators.required],
      desc : ['',Validators.required],
      terms : ['',Validators.required],
      imgupload :['',Validators.required]
    });
      // maps start
        //load Places Autocomplete
        this.mapsAPILoader.load().then(() => {
          this.setCurrentLocation();
          this.geoCoder = new google.maps.Geocoder;
    
          let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            types: ["address"]
            
          });
          autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
              //get the place result
              let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    
              //verify result
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
    
              //set latitude, longitude and zoom
              this.latitude = place.geometry.location.lat();
              this.longitude = place.geometry.location.lng();
              this.zoom = 12;
              this.getAddress(this.latitude, this.longitude);
            });
          });
        });
        //map end
  }

      //map start
         // Get Current Location Coordinates
         private setCurrentLocation() {
          if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
              this.latitude = position.coords.latitude;
              this.longitude = position.coords.longitude;
              this.zoom = 8;
              this.getAddress(this.latitude, this.longitude);
              this.geoCoder.geocode({ 'location': { lat: this.latitude, lng: this.longitude } }, (results, status) => {
                // console.log(results);
                // console.log(status);
                if (status === 'OK') {
                  if (results[0]) {
                    this.zoom = 12;
                    this.address = results[0].formatted_address;
                    this.getmapDetails(this.latitude,this.longitude,this.address);
                    $('.maplocinput').val(this.address);
                  } else {
                    window.alert('No results found');
                  }
                } else {
                  window.alert('Geocoder failed due to: ' + status);
                }
          
              });
            });
          }
        }
      
      //draggable events part
        markerDragEnd($event: MouseEvent) {
          //console.log($event);
          this.latitude = $event.coords.lat;
          this.longitude = $event.coords.lng;
          this.getAddress(this.latitude, this.longitude);
        }
      
        getAddress(latitude, longitude) {
          //console.log(latitude,longitude);
          this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
            // console.log(results);
            // console.log(status);
            if (status === 'OK') {
              if (results[0]) {
                this.zoom = 12;
                this.address = results[0].formatted_address;
                this.getmapDetails(latitude,longitude,this.address);
                $('.maplocinput').val(this.address);
              } else {
                window.alert('No results found');
              }
            } else {
              window.alert('Geocoder failed due to: ' + status);
            }
      
          });
        }
        getmapDetails(latitude,longitude,address){
          this.reportObject.latitude = latitude;
          this.reportObject.longitude = longitude;
          this.reportObject.address = address;
          //console.log(this.reportObject.latitude,this.reportObject.longitude);
        }
        // maps end
     // report category
     getreportCategory(){
      this.psService.reportCategory().pipe(first()).subscribe( res=>{
        if(res.status==GlobalVariable.api_success){
          this.reportObject.category = res.data;
          //console.log(res.data );
          // console.log(res.message);
        }
      });
    }
    getCategoryID(cid){  
      this.reportObject.cid = cid;
      this.getReportSubCategory(this.reportObject.cid );
      //return cid;
  }
        // report type
      getReportSubCategory(sub_cat_id){
        var input= {"category_id":sub_cat_id};
        this.psService.getreportSubCategory(input).pipe(first()).subscribe( res=>{
          if(res.status==GlobalVariable.api_success){
            this.reportObject.subcategory = res.data;
           // console.log(res.data);
          }
        });
      }
   
  getSubCategoryID(scid){  
    this.reportObject.scid = scid;
    //return scid;
    }
     // file upload
     processFile(inputValue: any): void {
      var file:File = inputValue.files[0];
      var myReader:FileReader = new FileReader();
      myReader.onloadend = (e) => {
         var image = myReader.result;
         //console.log(image);
         this.fileuploadsource = image;
        // this.someService.uploadImage(image).subscribe(
        //   (res) => {
        //     if(res.statusCode==200){
        //      console.log(res);
        //     }          
        //   },
        //   (err) => {
        //     console.log(err);
        //   });
      }
      myReader.readAsDataURL(file);
    }
    changeListener($event) : void {
      const file = $event.target.files[0];
      if(file.size >  5242880)  
      // validation according to file size
      //5242880 - 5MB //1048576 - 1MB
      {
       this.as.error('Please upload image below 5 MB '); 
      }else{
        this.processFile($event.target);
      } 
     }
    // preview
  previewReport(form:NgForm){
    console.log(form); 
    this.category = form['category'] ;  
    this.subcategory = form['subcategory'] ;  
    this.reportObject.place = form['place'];  
    this.desc = form['desc'];      
    this.reportObject.proofs = this.fileuploadsource;//form['imgupload'];
    this.terms = form['terms']  == true?'Accepted terms & conditions':'User did not accept terms & conditions';
  }
   
  onsubmitflag(form:NgForm){
    //console.log(form);
    this.submitted = true;
    if (this.pinRedFlagForm.invalid) {
      console.log('form invalid'); 
      this.reportObject.forminvalid=true;
        }
  else{
    this.reportObject.forminvalid=false;
    this.pinRedFlagForm.value.place = this.reportObject.address;
    this.pinRedFlagForm.value.imgupload = this.fileuploadsource;
    var input = {"cat_id":this.pinRedFlagForm.value.category,"sub_cat_id":this.pinRedFlagForm.value.subcategory,"location":this.pinRedFlagForm.value.place,"latitude":this.reportObject.latitude,"longitude":this.reportObject.longitude,"upload_file":this.fileuploadsource != ''?this.fileuploadsource:this.pinRedFlagForm.value.imgupload, 
    "description":this.pinRedFlagForm.value.desc,"tc_agree":this.pinRedFlagForm.value.terms == true?'1':'0'};
    //console.log(input);
    this.commonservice.pinredflag(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        //console.log(res);
        this.as.success(res.message);
        this.pinRedFlagForm.reset();
        this.setCurrentLocation();
        this.submitted = false;
      } else if(res.status == GlobalVariable.api_token_error || res.status == GlobalVariable.multipleUser_login || res.status == GlobalVariable.unauthorizedError || res.status == GlobalVariable.serverError){
        this.as.error(res.message);
      }else{ 
        this.as.success(res.message);
        }
    }, error=>{
      this.as.success(error['error']['Message']);          
    });
  }
  }
  

}
