import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {HelperService} from '../_helpers/helper';
import * as $ from 'jquery';
import {CommonService,UserAuthService} from '../_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../globalvariables';
import {Subscription} from 'rxjs';
@Component({
  selector: 'app-login-navbar',
  templateUrl: './login-navbar.component.html',
  styleUrls: ['./login-navbar.component.css']
})
export class LoginNavbarComponent implements OnInit {
  currentuser:object;
  userDetailsObject:{[k: string]: any} = {};
  notificationObject:{[k: string]: any} = {};
  vip_user:boolean=false;

public _picSubscription:Subscription;
public _ProfilePercentageSubscription:Subscription;

  constructor( private route: ActivatedRoute,public hs:HelperService, private router: Router,public cs:CommonService,public authservice:UserAuthService) {
 
    this._picSubscription = authservice.notifyChange.subscribe((value) => { 
        this.userDetailsObject.userDetails = value.user_details;
        this.userDetailsObject.userDetailsIdproof = value.idproof_filepath;
        this.userDetailsObject.userDetailsFilepath = value.photo_filepath;
    });
this._ProfilePercentageSubscription = authservice.profilePercentageChange.subscribe((val)=>{
  if(val.profile=='100%')
    this.vip_user=true;
  else
    this.vip_user=false;
})
      }

  ngOnInit() {
    this.currentuser=this.hs.getObj("currentuser");
    // this.openNav();
    // this.router.navigate(['/home']);
    this.notificationCount();
    this.getlatestNews();
    this.savedUserDetails();
  }
  openNav() {
     $("#myNav").css('width','100%');
  }
  logout(){
    this.hs.removeObj('currentuser');
    localStorage.removeItem('currentuser');
    window.sessionStorage.clear();
    window.location.reload();
    // console.log(this.currentuser);
    this.router.navigate(['/login']);
  }
  notificationCount(){
    this.cs.notifyCount().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.notificationObject.count = res.notifications_count;
      }
    });
  }
  getlatestNews(){
    this.cs.newsUpdates().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.notificationObject.newsUpdates = res.latest_news;
        this.notificationObject.filepath = res.file_path;
      }
    });
  }
  getAlerts(){
    this.router.navigate(['/policeServices/alert']);
  }
  gotoNotifications(){
    this.notificationCount();
    this.router.navigate(['/profile/myprofile'],{ queryParams: { response: this.notificationObject.count } });
  }
  // get user details
  savedUserDetails(){
    this.authservice.getUserDetails().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.userDetailsObject.userDetails = res.user_details;
        // console.log(this.userDetailsObject.userDetails);
        this.userDetailsObject.userDetailsIdproof = res.idproof_filepath;
        this.userDetailsObject.userDetailsFilepath = res.photo_filepath;
      }
    });
  }
  values = '';
  searchOpen(event: any){
    if (event.keyCode == 13){
      this.values = event.target.value;
      this.authservice.rechange(this.values);
      this.router.navigate(['/SearchBar']);
    }
  }
}
