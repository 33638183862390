import { Component, OnInit, ViewChild } from '@angular/core';
import {PoliceServiceService, AlertService,CommunityService } from '../../_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../../globalvariables';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import {HelperService} from '../../_helpers/helper';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl,NgForm } from '@angular/forms';
import moment from 'moment';
import { saveAs} from 'file-saver';
import { DatePipe } from '@angular/common';
import {animate, state, style, transition, trigger} from '@angular/animations';
export interface PeriodicElement {
  case_filed: string;
  case_number: string;
  case_type: number;
  team_name:string;
  submitted_docs: string;
  status:number;
  team_id:number;
  file_date:string;
}

@Component({
  selector: 'app-myreport',
  templateUrl: './myreport.component.html',
  styleUrls: ['./myreport.component.css'],
  providers: [DatePipe],
  animations: [
   trigger('detailExpand', [
     state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
     state('expanded', style({height: '*'})),
     transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
   ]),
 ]
})
export class MyreportComponent implements OnInit {
  currentuser:any;  panelOpenState = false;  submitted = false;
  myreportObject:{[k: string]: any} = {};
  public members = [];public files=[];
  public categoryArray = [];
  public casetypeDetails = [];
  public result= [];
  public teamDetails = [];
  public memberrole = [];
  public daterange = '';
  public reportKey = '';
  selected:any;
  public catArray =[];
  public inputData:any;
  public searchCaseNo:any;
  public caseNo:any='';
  public report_length:any;
  selectedMember:any;selectedDate:any;minDate:any;maxDate:any;
  time1:any;time2:any;uploadedImagesObj:any;folderObj:any;
  requestAppointmentForm: FormGroup;submitproofsForm: FormGroup;
  locale: any = {
    format: 'YYYY-MM-DD',
    displayFormat: 'DD MMMM YYYY',   
    applyLabel: 'Okay',
};

public displayedColumns = ['case_filed', 'case_number', 'case_type','status', 'team_name','team_id','submitted_docs'];
public expandedElement: PeriodicElement;
public dataSource :Array<any>=[];
step : number;
openCaseNO(){
  this.step=1;
}
openDocs(){
  this.step=2;
}
openCase(){
this.step=3;
console.log(this.step);
}
openDocuments(){
 this.step=4;
 console.log(this.step);

}
openTeam(){
  this.step=5;
 // console.log(this.step);
}
openOldTeam(){
  this.step=6;
//  console.log(this.step);

}


  constructor(public psService:PoliceServiceService,private formBuilder: FormBuilder,private route: ActivatedRoute,public alertService:AlertService,private router: Router,public ngxSmartModalService: NgxSmartModalService,public hs:HelperService,public cts:CommunityService) {    
   // this.selected= new Date();
   let date = new Date();
   this.selected={
      startDate:  new Date(new Date().setDate(new Date().getDate()-5)),
      endDate: date
    }
    
    this.selectedDate= moment().format('DD MMMM YYYY');
   this.minDate = moment().subtract(60, 'days');
   this.maxDate = moment();
    this.searchCaseNo=this.hs.getObj("viewReportCaseNo");
    this.caseNo=this.hs.getObj("viewReportCaseNo"); 
    //console.log(this.caseNo);
    this.searchCaseNo=this.hs.removeObj("viewReportCaseNo");
   
    this.myreportObject.schedulevalid=false;
    this.myreportObject.datevalid=false;
    this.myreportObject.membervalid=false;
    this.myreportObject.Advice_fromdate="";
    this.myreportObject.Advice_todate="";
    this.myreportObject.advice_number="";
    this.myreportObject.advice_view_data="";
  }
  get f() { return this.requestAppointmentForm.controls; }
  get g() { return this.submitproofsForm.controls; }
  ngOnInit() {
    this.currentuser=this.hs.getObj("currentuser");
    //this.getTeamDetails();
    //this.getreportCategory();
    this.getViewReport(this.daterange,'recent',this.caseNo,'');
   // this.getAllAdvicesList();
    
    // this.myreportObject.myreportview=true;          
    // this.myreportObject.olderReports=false;this.myreportObject.recentReports=true;
    this.myreportObject.rid=0;
    //this.getTeamDetails();
  //  this.getreportCategory();
    //this.getReportType(this.myreportObject.rid);
    //console.log(this.myreportObject.rid);
     // selected report views
    if(this.myreportObject.rid==0){
    this.myreportObject.myreportview=true;this.myreportObject.permitview=false;
    this.myreportObject.adviceview=false;this.myreportObject.flagview=false;
    }

    $('.modal-backdrop').hide();
    $('.modal-open').css('overflow','auto');
    this.myreportObject.responseMessage = this.route
      .queryParams
      .subscribe(params => {
        //console.log(params['response']);
        if(params['response'] == undefined){
          this.alertService.success('Report submitted successfully');
        }
        //this.myreportObject.response = +params['response'] || 0;
        this.router.navigate(['profile/myreport']);
      });
      this.requestAppointmentForm = this.formBuilder.group({
        caseNumber: ['', ''],
        caseType : ['',''],
        member: ['', Validators.required],
        date: ['',  Validators.required],
        time: ['',  Validators.required],
      });
      this.submitproofsForm = this.formBuilder.group({
        caseNumber: ['', ''],
        image : ['', ''],
        date : ['','']
      });
      
  }
  

  // report category
  // getreportCategory(){
  //   this.psService.reportCategory().pipe(first()).subscribe( res=>{
  //     if(res.status==GlobalVariable.api_success){
  //       this.myreportObject.category = res.data;
  //       this.categoryArray = this.myreportObject.category;
  //       this.catArray = this.categoryArray;
  //       //return this.categoryArray;
  //       //console.log(this.catArray);
  //     }
  //   });
  // }
  getcrimereportDetails(id){
    //alert(id);
    this.myreportObject.crimeNumber=id;
        
    this.psService.viewReport(this.inputData).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
       // alert("hi");
        this.myreportObject.crimereport = res.reports;
        this.myreportObject.applylength=res.reports.length;
    this.myreportObject.applyfile_path=res.file_path;

     //   console.log(res.reports);
    
    this.ngxSmartModalService.getModal('crimeModal').open();
  }
  else if(res.status == GlobalVariable.api_token_error || res.status == GlobalVariable.multipleUser_login || res.status == GlobalVariable.unauthorizedError){
    this.alertService.error(res.error);
  }else{ 
    this.alertService.error(res.error);
    }
},
error=>{
  this.alertService.error(error);          
});

}

  // 
  getReportType(rid){
    //console.log(rid);
    if(rid == 0){
      this.myreportObject.rid=rid;
      this.myreportObject.myreportview=true;this.myreportObject.permitview=false;
      this.myreportObject.adviceview=false;this.myreportObject.flagview=false;
      this.myreportObject.recentReports=true;this.myreportObject.olderReports=false;
      this.myreportObject.permitolderReports=false;this.myreportObject.permitrecentReports=false;
      this.myreportObject.adviceolderReports=false;this.myreportObject.advicerecentReports=false;
      this.myreportObject.flagolderReports=false;this.myreportObject.flagrecentReports=false;
    }else if(rid == 1){
      this.myreportObject.rid=rid;
      this.myreportObject.myreportview=false;this.myreportObject.permitview=true;
      this.myreportObject.adviceview=false;this.myreportObject.flagview=false;
      this.myreportObject.recentReports=false;this.myreportObject.olderReports=false;
      this.myreportObject.permitolderReports=false;this.myreportObject.permitrecentReports=true;
      this.myreportObject.adviceolderReports=false;this.myreportObject.advicerecentReports=false;
      this.myreportObject.flagolderReports=false;this.myreportObject.flagrecentReports=false;
      //console.log(this.myreportObject.permitolderReports,this.myreportObject.permitrecentReports);
      this.getPermitViewReport('','recentpermit');
    }else if(rid == 2){
      this.myreportObject.rid=rid;
      this.myreportObject.myreportview=false;this.myreportObject.permitview=false;
      this.myreportObject.adviceview=true;this.myreportObject.flagview=false;
      this.myreportObject.recentReports=false;this.myreportObject.olderReports=false;
      this.myreportObject.permitolderReports=false;this.myreportObject.permitrecentReports=false;
      this.myreportObject.adviceolderReports=false;this.myreportObject.advicerecentReports=true;
      this.myreportObject.flagolderReports=false;this.myreportObject.flagrecentReports=false;
    }else{
      this.myreportObject.rid=rid;
      this.myreportObject.myreportview=false;this.myreportObject.permitview=false;
      this.myreportObject.adviceview=false;this.myreportObject.flagview=true;
      this.myreportObject.recentReports=false;this.myreportObject.olderReports=false;
      this.myreportObject.permitolderReports=false;this.myreportObject.permitrecentReports=false;
      this.myreportObject.adviceolderReports=false;this.myreportObject.advicerecentReports=false;
      this.myreportObject.flagolderReports=false;this.myreportObject.flagrecentReports=true;
    }
  }

  // working team
  memberDetails(team,role,members){
    //console.log(team,role);
    this.teamDetails = team.split(',');
    this.memberrole = role.split(',');
    this.members = members.split(',');
    //console.log(this.memberrole,this.members);
  }
  getWorkingTeamDetails(team_id){
 //  console.log(team_id);
    if(team_id != 0) {
      this.getTeamDetails(team_id);
    }
   
  }

  // date conversion
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      //console.log([date.getFullYear(), mnth, day].join("-"));
    return [date.getFullYear(), mnth, day].join("-");
  }
        // view report
      getViewReport(daterange,reportKey,caseNo,reportType){
        // console.log(daterange,reportKey,caseNo);
        // console.log(reportType);
        this.myreportObject.myreportview=true;this.myreportObject.permitview=false;
        this.myreportObject.adviceview=false;this.myreportObject.flagview=false;
        if(reportType=='olderReport'){
          this.myreportObject.reportType=reportType;
          this.myreportObject.olderReports=true;this.myreportObject.recentReports=false;
        }else{
          this.myreportObject.reportType=reportType;
          this.myreportObject.recentReports=true;this.myreportObject.olderReports=false;
        }
        //console.log(this.myreportObject.reportType);
        if(daterange != '' && reportKey == 'daterange' && caseNo == ''){
          this.inputData = {'from':this.convert(daterange.startDate), 'to':this.convert(daterange.endDate) };
          this.myreportObject.workTeamDetails=false;this.myreportObject.fileViewDownload=false;          
        }else if(daterange == '' && reportKey == 'recent' && caseNo != null){
          this.inputData = {"case_no":caseNo};//caseNo;
          this.caseNo = caseNo;
          this.myreportObject.workTeamDetails=false;this.myreportObject.fileViewDownload=false;

        }else if(daterange == '' && reportKey == 'recent' && caseNo == null){
          this.inputData = daterange;
          this.myreportObject.workTeamDetails=false;this.myreportObject.fileViewDownload=false;
        }
        //console.log(this.inputData);
        this.psService.viewReport(this.inputData).pipe(first()).subscribe( res=>{
          if(res.status==GlobalVariable.api_success){
            //console.log(this.dataSource);
            this.dataSource=[];this.files=[];this.myreportObject.fileViewDownload=false;
            this.myreportObject.reportData = res.reports;
            
            this.myreportObject.reportData.forEach(element => {
              //console.log(element);
              if(element.submitted_docs != null){
              if(element.submitted_docs.includes(',')){
                this.files=element.submitted_docs.split(',');
                this.myreportObject.filelength=this.files.length;
                //console.log(this.files.length,this.files);
              }else{
                this.files.push(element.submitted_docs);
                this.myreportObject.filelength=this.files.length;
                this.teamDetails = element.membername;
              this.memberrole = element.titlerole;
              this.members = element.members_list;
                //console.log(this.files.length,this.files);
              }
            }else{
              this.files=[];
            }
              
              var temp={
                'report_id':element.report_id,
                'case_filed':element.case_filed,
                'case_number':element.case_number,
                'case_type':element.case_type,
                'assigned_team':element.assigned_team,
                'team_id':element.team_id,
                'submitted_docs':this.files,
                'status':element.status,
                'created_on':element.created_on,
                'type_name':element.type_name,
                'membername': element.membername,
                'titlerole':element.titlerole,
                'members_list':element.members_list,
                'case_no':element.case_number,
                'fileViewDownload':false,
                'file_length':this.files.length
              }
              this.dataSource.push(temp);
            });
            // this.expandedElement = this.myreportObject.reportData;
            // this.dataSource = this.expandedElement;
            // console.log(this.dataSource);    
            // console.log(typeof(this.expandedElement));
            
            //console.log(this.dataSource);
            this.myreportObject.fileViewDownload=false;
            this.myreportObject.reportType=reportType;
            //console.log(this.myreportObject.reportData);
            this.myreportObject.filepath = res.file_path;
            //console.log(this.myreportObject.reportData);
            //console.log(daterange,reportKey,this.caseNo);
            if(daterange == '' && reportKey == 'recent' && this.caseNo == null ){
              this.myreportObject.reportData.caseNo = this.caseNo;
              this.report_length=this.myreportObject.reportData.length;
              //console.log(this.myreportObject.reportData.caseNo);
              //console.log(this.report_length);
              if(this.report_length == 0){
                this.myreportObject.reportData = [];
                //this.alertService.error('Case number is invalid,Please enter a valid case number.');
                this.alertService.error('No records found,Please enter a valid input.');
                //this.router.navigate(['/home']);
              }
            }else if(daterange == '' && reportKey == 'recent' && caseNo != null){
              this.report_length=this.myreportObject.reportData.length;
              //console.log(this.caseNo);
              if(this.report_length == 0){
                var error_msg= 'No report is filed with case number - '+this.caseNo+' with current login.';
                this.alertService.error(error_msg);
              }
            }else{
              this.report_length=this.myreportObject.reportData.length;
              //console.log(this.caseNo);
              if(this.report_length == 0){
                this.alertService.error('No records found.');
              }
            }
            this.myreportObject.reportData.forEach(element => {
              //this.members =  element.membername.split(',');
            });
           this.myreportObject.reportData.forEach(element => {
            this.casetypeDetails.push(element);
          });
         // this.getreportCategory();
          // console.log(this.catArray);
          // console.log(this.casetypeDetails);

           this.result= this.categoryArray.filter(o1 => this.casetypeDetails.some(o2 => o1.id === o2.case_type));
          return this.result;
          }
        });
      }
    
      // team details
      getTeamDetails(team_id){
        var input= {"team_id":team_id};
        this.psService.teamDetails(input).pipe(first()).subscribe( res=>{
          if(res.status==GlobalVariable.api_success){
            this.myreportObject.workTeamDetails=true;this.myreportObject.fileViewDownload=false;
            this.myreportObject.team_membername = res.membername;
            this.myreportObject.team_profile_pic = res.profile_pic;
            this.myreportObject.team_titlerole = res.titlerole;
            this.myreportObject.team_file_path = res.file_path;

            //console.log(this.myreportObject.team);
          
          }
        });
      }
//get report Details
getreportDetails(rdCaseno){
  //console.log(rdCaseno);
  this.myreportObject.rdcase_number=rdCaseno;
  this.myreportObject.workTeamDetails=false;this.myreportObject.fileViewDownload=false;
}
  // view & download docs
  submittedDocs(date,caseno){
    //console.log(date);
    this.myreportObject.date = date;
    this.myreportObject.caseno = caseno;
    //console.log(this.myreportObject.caseno);
    this.files=[];
    //this.getViewReport(this.daterange,'recent',this.caseNo,'');
    //console.log(this.casetypeDetails);
    this.casetypeDetails.forEach(element => {
      //console.log(element);
      // if(element.case_filed==date ){}
        if(element.case_number==caseno){
        //console.log(element.case_filed);
        if(element.submitted_docs.includes(',')){
          this.files=element.submitted_docs.split(',');
          this.myreportObject.filelength=this.files.length;
          //console.log(this.files.length,this.files);
        }else{
          this.files.push(element.submitted_docs);
          this.myreportObject.filelength=this.files.length;
          //console.log(this.files.length,this.files);
        }
      }
    });
  // var temp={  
  //   'fileViewDownload':true
  // }
  // this.dataSource.push(temp);
  // console.log(this.dataSource);
    this.myreportObject.workTeamDetails=false;this.myreportObject.fileViewDownload=true;
  }
// add proofs
addproofs(caseno){
  //console.log(caseno);
  this.myreportObject.proofCaseno=caseno;
  
  this.ngxSmartModalService.getModal('proofsUploadModal').open();
}
 // file upload
 processFile(inputValue: any): void {
  var file:File = inputValue.files[0];
  var myReader:FileReader = new FileReader();
  myReader.onloadend = (e) => {
     var image = myReader.result;
     //console.log(image);
     this.myreportObject.fileuploadsource = image;
  }
  myReader.readAsDataURL(file);
}
changeListener($event) : void {
  //console.log($event);
  this.myreportObject.file = $event.target.files[0];
  //console.log('file',this.file.type);
  this.myreportObject.filetype = this.myreportObject.file.type;
  if(this.myreportObject.file.size >  5242880)  
  // validation according to file size
  //5242880 - 5MB //1048576 - 1MB
  {
   this.alertService.error('Please upload image below 5 MB '); 
  }else{
    this.processFile($event.target);
  } 
 }
 
// file upload end
submitproofs(){
  if(this.submitproofsForm.invalid) {
    console.log('form invalid'); 
  }else{
    //console.log(this.submitproofsForm.value);
  var input={"file_date":moment(this.submitproofsForm.value.date).format('YYYY-MM-DD hh:mm:ss'),"case_no":this.submitproofsForm.value.caseNumber,"file":this.myreportObject.fileuploadsource != ''?this.myreportObject.fileuploadsource:this.submitproofsForm.value.image };
  this.psService.viewReport(input).pipe(first()).subscribe( res=>{
    if(res.status==GlobalVariable.api_success){
      this.myreportObject.reportData = res.message;
      this.myreportObject.filepath = res.file_path;
      this.myreportObject.date=res.file_date;
      console.log(res);
      if(this.myreportObject.reportType == 'olderReport'){
        this.getViewReport(this.selected,'daterange',this.caseNo,'');
        this.myreportObject.recentReports=false;this.myreportObject.olderReports=true;
      }else{
        this.getViewReport(this.daterange,'recent',this.caseNo,'');
        this.myreportObject.recentReports=true;this.myreportObject.olderReports=false;
      }
      this.ngxSmartModalService.getModal('proofsUploadModal').close();
      this.files=[];
      if(res.message){this.alertService.success(res.message);}
      
    }else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
      //console.log(res.message); 
      this.alertService.error(res.message);
     }
  },error => {                  
    this.alertService.error(error); 
  });
}
} 
  // datepicker keyevent 
  keyDateEvent(event: any) {
    const pattern = /[]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
// request Appointment
requestAppointment(caseNumber){
 // console.log(caseNumber);
  this.getViewReport(this.daterange,'recent',caseNumber,'');
  this.ngxSmartModalService.getModal('ReqappointmentModal').open();
  //this.requestAppointmentForm.value.member = undefined;
}
getMemberID(mId){
  //console.log(mId);
}
requestAppointmentSubmit(data){//form:NgForm
  //console.log(data);
  this.submitted=true;
  this.myreportObject.caseNumber=data.case_number;
  this.myreportObject.caseType=data.case_type;
  // console.log(this.currentuser);
  // console.log(this.requestAppointmentForm.value); 
  if(this.requestAppointmentForm.invalid) {
   if(this.requestAppointmentForm.value.time == undefined || this.requestAppointmentForm.value.time == null || this.requestAppointmentForm.value.time == ''){
    this.myreportObject.schedulevalid=true;
   }else if(this.requestAppointmentForm.value.member == undefined || this.requestAppointmentForm.value.member == null || this.requestAppointmentForm.value.member == ''){
    this.myreportObject.membervalid=true;
   }else{
    this.myreportObject.datevalid=true;
   }
    console.log('form invalid'); 
  }else{
    var input={ "case_no":this.myreportObject.caseNumber, "case_type":this.myreportObject.caseType, "team_id":this.requestAppointmentForm.value.member, "emp_id":this.currentuser['user'].id,"appointment_date":moment(this.requestAppointmentForm.value.date).format('YYYY-MM-DD'), "schedule_time":this.requestAppointmentForm.value.time=='time1'?'10:00':'3:00' }
    //console.log(input);
    //return;
    this.psService.requestAppointment(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.alertService.success(res.message);
        //console.log(res.details);
        this.myreportObject.detailsId=res.details.app_id;
        $('.modal-backdrop').hide();
        $('.modal-open').css('overflow','auto');
        //this.requestAppointmentForm.reset();
        this.submitted=false;
        this.requestAppointmentForm.value.member = undefined;
        this.myreportObject.membervalid=false; this.myreportObject.datevalid=false;this.myreportObject.schedulevalid=false;
        console.log(this.myreportObject.reportType);
        // if(this.myreportObject.reportType == 'olderReport'){
        //   this.getViewReport(this.selected,'daterange','','olderReport');
        //   this.myreportObject.recentReports=false;this.myreportObject.olderReports=true;
        // }else{
        //   this.getViewReport(this.daterange,'recent','','recentReport');
        //   this.myreportObject.recentReports=true;this.myreportObject.olderReports=false;
        // }
        this.getViewReport(this.daterange,'recent',this.caseNo,'');
        this.ngxSmartModalService.getModal('ReqappointmentModal').close();
        // this.getViewReport(this.daterange,'recent',this.caseNo,'');
        //this.getViewReport(this.daterange,'recent','','');
      }
      else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
        this.alertService.error(res.message);
      }else{ 
        this.alertService.error(res.message);
        }
  }, error=>{
    this.alertService.error(error);          
  });
  }
}
viewAppointment(detailsId){
  //console.log(detailsId);
  this.viewAppointmentDetails(detailsId);
  this.ngxSmartModalService.getModal('viewappointmentModal').open();
}
viewAppointmentDetails(data){
  //console.log(data);
  var input={"case_no":data}; 
  this.myreportObject.detailsId=data;
  //console.log(input);
  this.psService.viewAppointment(input).pipe(first()).subscribe( res=>{
    if(res.status==GlobalVariable.api_success){
     // console.log(res.details);
      this.myreportObject.appointmentView = res.details;
      this.myreportObject.viewlength=res.details.length;
      //console.log(this.myreportObject.viewlength);
    }
    else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
      this.alertService.error(res.message);
    }else{ 
      this.alertService.error(res.message);
      }
}, error=>{
  this.alertService.error(error);          
});
}
closeModal(id:string){
  //console.log(id);
  this.ngxSmartModalService.getModal(id).close();
}
// permit report
getPermitViewReport(report,selected){
  //console.log(report,selected);
  this.myreportObject.myreportview=false;this.myreportObject.permitview=true;
  this.myreportObject.adviceview=false;this.myreportObject.flagview=false;
  //console.log(this.convert(selected.startDate));
  if(report=='permit' && selected.startDate !=''){
    this.myreportObject.permitolderReports=true;this.myreportObject.permitrecentReports=false;
    this.inputData = {'from':this.convert(selected.startDate), 'to':this.convert(selected.endDate) };
  }else if((report=='' || report == 'permit') && selected=='recentpermit'){
    this.myreportObject.permitolderReports=false;this.myreportObject.permitrecentReports=true;
    this.inputData='';
  }
  //console.log(this.inputData);
  this.psService.getviewPermit(this.inputData).pipe(first()).subscribe( res=>{
    if(res.status==GlobalVariable.api_success){
      this.myreportObject.application = res.applications;
      //console.log(res.applications);
      //console.log(this.myreportObject.application);
      this.myreportObject.applylength=res.applications.length;
      this.myreportObject.applyfile_path=res.file_path;
      //console.log(this.myreportObject.applylength);
      }else if(res.status == GlobalVariable.api_token_error || res.status == GlobalVariable.multipleUser_login || res.status == GlobalVariable.unauthorizedError){
        this.alertService.error(res.error);
      }else{ 
        this.alertService.error(res.error);
        }
    },
    error=>{
      this.alertService.error(error);          
    });

}
getpermitreportDetails(pId){
  this.myreportObject.permitNumber=pId;
  this.ngxSmartModalService.getModal('permitModal').open();
}
// legal advice report
getLegalAdviceReport(report,selected){
  //console.log(report,selected);
  this.myreportObject.myreportview=false;this.myreportObject.permitview=false;
  this.myreportObject.adviceview=true;this.myreportObject.flagview=false;
  this.myreportObject.adviceolderReports=true;this.myreportObject.advicerecentReports=false;
  if(selected=='olderadvice'){
    this.myreportObject.adviceolderReports=true;this.myreportObject.advicerecentReports=false;
  }else{
    this.myreportObject.adviceolderReports=false;this.myreportObject.advicerecentReports=true;
  }
  this.getAllAdvicesList();
}

// pin a red flag report
getPinRedFlagReport(report,selected){
  //console.log(report,selected);
  this.myreportObject.myreportview=false;this.myreportObject.permitview=false;
  this.myreportObject.adviceview=false;this.myreportObject.flagview=true;
  this.myreportObject.flagolderReports=true;this.myreportObject.flagrecentReports=false;
  if(selected=='olderflag'){
    this.myreportObject.flagolderReports=true;this.myreportObject.flagrecentReports=false;
  }else{
    this.myreportObject.flagolderReports=false;this.myreportObject.flagrecentReports=true;
  }
}
downloadFile(path,item){
  console.log(item,path);
  this.psService.downloadFile(item,path).subscribe(
    (data) => {
      if(data && data != undefined && data != null){
              saveAs(data);
            }
    })
  
}

/*Get Legal Advices*/
   getAllAdvicesList(){
     let req={}; 
     if(this.myreportObject.adviceolderReports){
         req={ "from":this.convert(this.selected.startDate), "to":this.convert(this.selected.endDate)}; 
     }else if(this.myreportObject.advice_number!=""){
       req={"advice_no": this.myreportObject.advice_number };
     }
    this.cts.getAllLegalIssues(req).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        if(this.myreportObject.advice_number==""){
                this.myreportObject.adviceList=res.legaladvice;
              }else{
                this.myreportObject.advice_number='';
                this.myreportObject.advice_view_data=res.legaladvice[0];
              }
      }else{ 
        this.alertService.error(res.message);
        }
    });
  }
  /*view Advice*/
  viewadviceData(no){
  this.myreportObject.advice_number=no;
  this.getAllAdvicesList();
  }

}
