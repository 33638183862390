import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
//import {  MatAdvancedAudioPlayerComponent } from 'ngx-audio-player';
import {PoliceServiceService ,AlertService} from '../_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../globalvariables';
import { Router, ActivatedRoute } from '@angular/router';
import {HelperService} from '../_helpers/helper';
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl, NgForm } from '@angular/forms';
import moment from 'moment';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { google } from "google-maps";
import * as $ from 'jquery';
// import * as localization from 'moment/locale/fr';
// import { LocaleConfig } from '../../../../src/daterangepicker';
// moment.locale('fr', localization);
declare var google : google;

@Component({
  selector: 'app-quickreport',
  templateUrl: './quickreport.component.html',
  styleUrls: ['./quickreport.component.css']
})
export class QuickreportComponent implements OnInit {
  reportObject:{[k: string]: any} = {};
  currentuser:object;
  createReportForm: FormGroup;
  submitted = false;
  selected:any;
  minDate:any;
  maxDate:any;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  public image:any;
  public fileuploadsource:any;
  @ViewChild("search",{static:true})
  public searchElementRef: ElementRef;
  selectedSubject:any;selectedSubCategory:any;mobile:any;
  selectedCategory:any;public file:any;
  locale: any = {
    format: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
    displayFormat: 'DD MMMM YYYY HH:mm:ss',   
    applyLabel: 'Okay',
};
  selectedDate: moment.Moment;
  constructor(public psService:PoliceServiceService,public router: Router,public hs:HelperService,private formBuilder: FormBuilder,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,public alertService: AlertService,public route: ActivatedRoute ) {
    this.selected={
      startDate:  moment(),
      endDate: moment()
    }
   // console.log(this.selected)
   this.selectedDate= moment()
   this.minDate = moment().subtract('60', 'days');
   this.maxDate = moment();
 // console.log( this.selected.startDate);
 }
  get f() { return this.createReportForm.controls; }

  ngOnInit() {
    this.currentuser=this.hs.getObj("currentuser");
    //console.log(this.currentuser);
    this.reportObject.title = 'Report a Problem';
    this.reportObject.forminvalid=false;
    this.reportObject.filetype='';
    // let date = new Date();
    // this.selected={
    //   startDate:  new Date(new Date().setDate(new Date().getDate()-5)),
    //   endDate: date
    // }
    //  console.log(this.selected.startDate);

    // if (this.currentuser == null || this.currentuser == undefined) {
    //   this.router.navigate(['/home']);
    // }
    this.getreportCategory();
    this.createReportForm = this.formBuilder.group({
      category : ['', Validators.required],
      subcategory : ['', Validators.required],
      subject : ['',Validators.required],
      date : ['',Validators.required],
      place : ['',Validators.required],
      //mobile : ['',Validators.required],
      mobile: ['', [Validators.required, Validators.minLength(10),Validators.pattern('[6-9]\\d{9}')]],
      email : ['',Validators.required],
      imgupload : ['',''],
      desc : ['',Validators.required],
      terms : ['',Validators.required]
    });
    // get category from report categories
    this.reportObject.responseMessage = this.route
    .queryParams
    .subscribe(params => {      
      this.reportObject.catId = params['response'];
      this.selectedCategory =  this.reportObject.catId;
      //console.log(this.selectedCategory);
      if(this.selectedCategory != '' || this.selectedCategory != null || this.selectedCategory != undefined){
      this.getReportSubCategory(this.selectedCategory);
      this.getSubjectList(this.selectedCategory);
      }else{
        this.router.navigate(['/quickreport']);
      }
    });
    
    // maps
        //load Places Autocomplete
        this.mapsAPILoader.load().then(() => {
          this.setCurrentLocation();
          this.geoCoder = new google.maps.Geocoder;
    
          let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            types: ["address"]
            
          });
          autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
              //get the place result
              let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    
              //verify result
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
    
              //set latitude, longitude and zoom
              this.latitude = place.geometry.location.lat();
              this.longitude = place.geometry.location.lng();
              this.zoom = 12;
              this.getAddress(this.latitude, this.longitude);
            });
          });
        });

        $('.maplocinput').val('');
      }
    
      // Get Current Location Coordinates
      private setCurrentLocation() {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.zoom = 8;
            this.getAddress(this.latitude, this.longitude);
            $('.maplocinput').val('');
          });
        }
      }
    
    //draggable events part
      markerDragEnd($event: MouseEvent) {
        //console.log($event);
        this.latitude = $event.coords.lat;
        this.longitude = $event.coords.lng;
        this.getAddress(this.latitude, this.longitude);
      }
    
      getAddress(latitude, longitude) {
        //console.log(latitude,longitude);
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
          // console.log(results);
          // console.log(status);
          if (status === 'OK') {
            if (results[0]) {
              this.zoom = 12;
              this.address = results[0].formatted_address;
              //console.log(this.address);
              this.getmapDetails(latitude,longitude,this.address);
              $('.maplocinput').val(this.address);
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
    
        });
      }
      // maps end
  
    
      // report category
      getreportCategory(){
        this.psService.reportCategory().pipe(first()).subscribe( res=>{
          if(res.status==GlobalVariable.api_success){
            this.reportObject.category = res.data;
            //console.log(this.reportObject.category );
            // console.log(res.message);
          }
        });
      }
      // get category id's
      getCategoryID(cid){  
        this.getReportSubCategory(cid);
        this.getSubjectList(cid);
        //return cid;
    }
        // report type
        getReportSubCategory(sub_cat_id){
          var input= {"category_id":sub_cat_id};
          this.psService.getreportSubCategory(input).pipe(first()).subscribe( res=>{
            if(res.status==GlobalVariable.api_success){
              this.reportObject.subcategory = res.data;
              //console.log(res.data);
            }
          });
        }
        // get sub cateory id's
        getSubCategoryID(scid){  
          return scid;
          }

        
      // subject list
      getSubjectList(sub_id){
        var input= {"reporttype_id":sub_id};
        this.psService.getsubjectList(input).pipe(first()).subscribe( res=>{
          if(res.status==GlobalVariable.api_success){
           // console.log(res.success);
            this.reportObject.subject = res.success;
          }
        });
      }
        // get subject id's
        getSubjectID(sid){  
          return sid;
       }
      // end get id's
      // convert date
      convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(),mnth,day].join("-");
      }
    

    getmapDetails(latitude,longitude,address){
      this.reportObject.latitude = latitude;
      this.reportObject.longitude = longitude;
      this.reportObject.address = address;
      //console.log(this.reportObject.latitude,this.reportObject.longitude);
    }

    // file upload
    processFile(inputValue: any): void {
      var file:File = inputValue.files[0];
      var myReader:FileReader = new FileReader();
      myReader.onloadend = (e) => {
         var image = myReader.result;
         //console.log(image);
         this.fileuploadsource = image;

        // this.someService.uploadImage(image).subscribe(
        //   (res) => {
        //     if(res.statusCode==200){
        //      console.log(res);
        //     }          
        //   },
        //   (err) => {
        //     console.log(err);
        //   });
      }
      myReader.readAsDataURL(file);
    }
    changeListener($event) : void {
      //console.log($event);
      this.file = $event.target.files[0];
      //console.log('file',this.file.type);
      this.reportObject.filetype = this.file.type;
      if(this.file.size >  5242880)  
      // validation according to file size
      //5242880 - 5MB //1048576 - 1MB
      {
       this.alertService.error('Please upload image below 5 MB '); 
      }else{
        this.processFile($event.target);
      } 
     }
    // file upload end

    previewReport(form:NgForm){
      console.log(form);
      if(form['date'] == '' || form['date'] == null){
        this.selected = '';
      }else{
        this.selected = moment(form['date']).format('YYYY-MM-DD hh:mm:ss a');
      }
      this.reportObject.mobile = form['mobile'];
      this.reportObject.email = form['email'];
      //console.log(form['imgupload']);
      this.reportObject.proofs = this.fileuploadsource;//form['imgupload'];
      //console.log(this.reportObject.proofs);
      this.reportObject.desc = form['desc'];      
      this.reportObject.terms = form['terms']  == true?'Accepted terms & conditions':'User did not accept terms & conditions';
    }
    // mobile number validation
   keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // datepicker keyevent 
  keyDateEvent(event: any) {
    const pattern = /[]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  ngModelChange(e){
    this.selected=e;
    //console.log(this.selected);
  }
      // create report
      genReport(form:NgForm){
        //console.log(form);
        //console.log(this.reportObject.latitude,this.reportObject.longitude);
        this.submitted = true;
       // console.log(this.createReportForm.value);
        //console.log(this.image);
        if (this.createReportForm.invalid) {
            console.log('form invalid'); 
            this.reportObject.forminvalid=true;
            this.alertService.error('Please fill the required fields');
            $('.modal-backdrop').hide();
            $('.yessubmit').hide();
            $('#previewreport').hide();
            $('.modal-open').css('overflow','auto');
        }
        else if(this.reportObject.catId != '' && this.currentuser == null){
          console.log(this.currentuser);
          this.router.navigate(['/home']);
          $('.modal-backdrop').hide();$('#previewreport').hide();
          $('.modal-open').css('overflow','auto');
        }
        else{
          this.reportObject.forminvalid=false;
          //console.log(this.createReportForm.value);
          this.createReportForm.value.place = this.reportObject.address;
          this.createReportForm.value.imgupload = this.fileuploadsource;
          //console.log(this.createReportForm.value.place);
          //console.log(this.reportObject.catId);
          var input= {"report_type":this.createReportForm.value.category,"sub_category": this.createReportForm.value.subcategory, "report_subject":this.createReportForm.value.subject, "dateof_occurence":moment(this.createReportForm.value.date).format('YYYY-MM-DD'), "placeof_occurence":this.createReportForm.value.place,"latitude":this.reportObject.latitude,"longitude":this.reportObject.longitude, "mobile_number":this.createReportForm.value.mobile, "email":this.createReportForm.value.email, 
          //"proofs":this.image != ''?this.image:this.createReportForm.value.imgupload, 
          "proofs":this.fileuploadsource != ''?this.fileuploadsource:this.createReportForm.value.imgupload, 
          "details_any":this.createReportForm.value.desc, "tnc":this.createReportForm.value.terms == true?'1':'0'}
          //console.log(input);
          // console.log(this.currentuser);
          
        this.psService.createReport(input).pipe(first()).subscribe( res=>{
          if(res.status==GlobalVariable.api_success){
            this.alertService.success(res.message);
            //console.log(res.report);
            //  this.createReportForm.reset();
            //  this.setCurrentLocation();
            //  this.submitted = false;
            this.router.navigate(['profile/myreport'],{ queryParams: { response: res.message } });
            $('.modal-backdrop').hide();
            $('.yessubmit').hide();
            $('#previewreport').hide();
            $('.modal-open').css('overflow','auto');
            //$(".modal-backdrop").remove(); 
          }
          else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error){
            this.alertService.error(res.message);
          }else{ 
            this.alertService.error(res.message);
            }
        }, error=>{
          this.alertService.error(error['error']['Message']);          
        });
        }
        
      }

      // media upload
        // image to base 64
      onSelectFile(event) {
        let reader = new FileReader();
        var self = this;
        reader.onload = function(){
          let output: any = document.getElementById('fileupload');
          self.image = output.src = reader.result;
        }
        if(event.target.files[0]){
          reader.readAsDataURL(event.target.files[0]);
        }
      }
     
}
