import { Component, OnInit } from '@angular/core';
import { GlobalVariable } from '../../globalvariables';
import { Router, ActivatedRoute } from '@angular/router';
import {HelperService} from '../../_helpers/helper'
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl } from '@angular/forms';
/*import { FormsModule, ReactiveFormsModule } from '@angular/forms';*/
import {UserAuthService, AlertService,CommonService, CommunityService } from '../../../app/_services';
import { first, map } from 'rxjs/operators';
import { NgxSmartModalService } from 'ngx-smart-modal';
@Component({
  selector: 'app-advice',
  templateUrl: './advice.component.html',
  styleUrls: ['./advice.component.css']
})
export class AdviceComponent implements OnInit {
  csObject:{[k: string]: any} = {};
  countryId:number=0;
   stateslist:any=[];
   citylist:any=[];
   adviceForm: FormGroup;
   submitted:boolean=false;
   state:any='';
   cityvalue:any='';
  constructor(private route: ActivatedRoute,private router: Router,public hs:HelperService,public authservice:UserAuthService,public alertService: AlertService,public cs:CommonService,public ngxSmartModalService: NgxSmartModalService,public cts:CommunityService,private formBuilder: FormBuilder) {
    this.csObject.advice_step=1;
    this.csObject.level=1;
    this.csObject.formInvalid=false;
   }

  ngOnInit() {
    this.getlocationDetails();
    this.getLeagalAdviceTypes();

    this.adviceForm = this.formBuilder.group({
      city:['', Validators.required],
      state:['', Validators.required],
      issuetype:['', Validators.required],
      issue:['', Validators.required],
      comments:['', Validators.required],
      tc:['', Validators.required],
    });
  }
  get f() { return this.adviceForm.controls; }
  goBack(step){ this.csObject.advice_step=step;}

profileInfo(val){
this.submitted = true;
    if(this.adviceForm.invalid) {
      this.csObject.formInvalid=true;
      console.log('form invalid'); 
    }else{
      console.log(this.adviceForm.value);
      }
}
  legalIssueCategory(){
    this.profileInfo(1);
    if(this.adviceForm.value.city!='' && this.adviceForm.value.state!='')
   { this.submitted = false; this.csObject.advice_step=2; }
  }
  issueQuestionarie(){
    this.profileInfo(2);
    if(this.adviceForm.value.issuetype!='')
    this.getLeagalAdviceSubTyps(this.adviceForm.value.issuetype);
  }
  giveFeedback(){
    this.profileInfo(3);
    if(this.adviceForm.value.issue!=''){
      this.submitted = false;
      this.csObject.advice_step=4;
    }
    
  }
  getConfirm(){
  console.log('dsfsafds');
    this.profileInfo(4);
    if(this.adviceForm.value.comments!='' && this.adviceForm.value.tc!=''){
    this.submitted = false;
    this.ngxSmartModalService.getModal('confirm').open();
   }
  }

  confirmData(){
  this.ngxSmartModalService.getModal('confirm').close();
  this.saveLegalAdviceRequest(this.adviceForm.value);

  }


  reviewData(){
  this.csObject.previw_list=this.adviceForm.value;
  }

  getlocationDetails(){
    var input=  {"latitude":GlobalVariable.latitude, "longitude":GlobalVariable.longitude };
    this.cs.getDefaultLocation(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
         this.countryId=res.location.country_id;
         this.getStatesList();
      }else {
        this.alertService.error(res.message);
       }
    },error => {                  
      this.alertService.error( error); 
    });
  }

   getStatesList(){
    var input = {"country_id":this.countryId};
    this.cs.getcities(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.stateslist = res.success;
      }else{ 
        this.alertService.error(res.message);
        }
    });
  }

   getCitysList(val){
    var input = {"state_id":+val};
    this.cs.getcitiesList(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        //console.log(res.cities);
         this.citylist = res.cities;
      }else{ 
        this.alertService.error(res.message);
        }
    });
  }

  getLeagalAdviceTypes(){
    this.cts.getLeagalIssueTypes().pipe(first()).subscribe( res=>{
     if(res.status_code==GlobalVariable.api_success){
        this.csObject.issue_type=res.issues;
     }else{
       this.alertService.error(res.message);
     }
    });
  }
  getLeagalAdviceSubTyps(type){
    let req={"issue_id":+type};
    this.cts.getLeagalSubCat(req).pipe(first()).subscribe( res=>{
   if(res.status_code==GlobalVariable.api_success){
     this.csObject.issue_sub_type=res.regards;
     this.submitted = false;
     this.csObject.advice_step=3;
     }else{
       this.alertService.error(res.message);
     }
    });
  }

  saveLegalAdviceRequest(data){
    let req={"state":data.state,"city":data.city,"issue_type":data.issuetype,"issue_regards":data.issue,"details_any":data.comments,"tnc":data.tc};
   
    this.cts.saveLegalAdvice(req).pipe(first()).subscribe( res=>{
     
      this.ngxSmartModalService.getModal('success').open();
       if(res.status==GlobalVariable.api_success){
     }else{
       this.alertService.error(res.message);
     }
    });
  }
  // saveLegalAdvice

  redirectTo(){
    this.router.navigate(['profile/myreport']);
  }
}
