import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

const routes: Routes = [
  
];

@NgModule({
  imports: [RouterModule.forChild(routes),BrowserModule,NgxSmartModalModule.forRoot(),BrowserAnimationsModule,  
  ],
  exports: [RouterModule]
})
export class KnowareaRoutingModule { }
