import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../_services';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;
alerts:any = [];
    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.getAlert().subscribe(message => { 
            this.message = message; 
            this.alerts=[];
              // add alert to array
      this.alerts.push(message);
      setTimeout(() => this.removeAlert(message), 5000);
        });
    }
    
     removeAlert(alert) {
   this.alerts = this.alerts.filter(x => x !== alert);
  }

     ngOnDestroy() {
       // this.subscription.unsubscribe();
    }
}