
export const environment = {
  production: false,
  // apiUrl:'https://wpproduct.appstekcorp.com/api', //public
  //apiUrl:'http://localhost:81/api', //public
  //apiUrl:'http://3.80.219.190:81/api', //public
  apiUrl:'https://wepolicedev.appstekcorp.com/api/api',
  paths:{
    register:'/register',
    otpValidate:'/otp-validation',
    resendOTP:'/reSendOtp',
    createPassword:'/create-password',
    login:'/login',
    logout:'/logout',
    forgetPassword:'/forgot-password',
    changePassword:'/change-password',
    termsAndConditions:'/agreement',
    countriesList:'/getcountries',
    statesList:'/getcities',
    languagesList:'/getlanguages',
    helpline:'/helpLine',
    pinRedFlag:'/create/pinaredflag',
    notifyCount:'/get/notifications',
    notifyDetail:'/detailnotification',
    subjectList:'/get/subjectList',
    reportSubCategory:'/subcategorylist',
    genReport:'/create/report',
    reportCategory:'/get/reportCategory',
    viewReport:'/view/report',
    teamDetails:'/teamdetails',
    crimetrends:'/crimetrends',
    areadetail:'/areadetails',
    anonymousReport:'/create/anonymous',
    UserFeedback:'/get/UserFeedback',
    localactivities:'/localactivities',
    reportWitness:'/reportWitness',
    getfeedbackQuestions:'/get/Feedbackquestions' ,
    writeFeedback:'/givefeedback',
    newsletterSubscribe:'/newsletter_subscribe',
    permitCategory:'/permit_Category',
    notificationCount:'/notifications_count',
    latestNews:'/latest_news',
    topAlerts:'/top_Alerts',
    recentAlerts:'/recent_Alerts',
    appNotifications:'/app_notifications',
    getPoliceStations:'/getPoliceStations',
    getPoliceOfficer:'/getPoliceOfficer',
    safetyTips:'/safety_tips',
    getlocation:'/default_location',
    communityNotifications:'/community_notifications',
    notificationsList:'/notifications_list',
    detailNotificationList:'/detail_notification',
    profileInfo:'/profile_edit',
    getrequestAppointment:'/appointment_Request',
    getviewAppointment:'/appointment_View',
    viewPermit:'/view_permit',
    permitQuestions:'/permit_Questions',
    applyPermit:'/apply_Permit',
    permitOtp:'/permit_appotp',
    permitOtpvalid:'/permit_otpvalid',
    permitAppstatus:'/permit_appstatus',
    userDetails:'/user_details',
    postProfileInfo:'/post_userdetails',
    contactus:'/contacts',
    citiesList:'/cities',
    /*Leagal Advice*/
    legal_issues_type:'/legal_issues',
    issue_regards:'/issue_regards',
    legal_advice:'/legal_advice',
    view_legaladvice:'/view_legaladvice',
    officer_roles:"/officer_roles",
    campaigns:"/campaigns",
    profile_settings:"/profile_settings",
    user_profileinfo:"/user_profileinfo",
    search_bar:"/search_bar",
  }
};

