import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map ,first} from 'rxjs/operators';
import { User } from '../_models';
import {  HelperService } from '../_helpers/helper';
import { AlertService } from '../_services/alert.service';
import { Router } from '@angular/router';
import { GlobalVariable,DataService } from '../globalvariables';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

/*check if Login*/
  get isLoggedIn() {
  return this.loggedIn.asObservable();
  }
  public helpFunction;
  constructor(private http: HttpClient,private alertService: AlertService,private router: Router,public dataService: DataService,
     public helper:HelperService) {
      
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();     
      this.helpFunction=helper;
  }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    var login_url=environment.apiUrl+environment.paths.login;
    return this.http.post<any>(login_url, { email, password  })
        .pipe(map(user => {
            // login successful if there's a jwt token in the response                
            if (user.statusCode.toString() == GlobalVariable.api_success) {
                localStorage.setItem('currentUser', JSON.stringify(user));
                console.log(user);
                this.currentUserSubject.next(user);
                this.dataService.userId=user.user_id;
                this.saveData(user,email);   
                return user;                    
            }
            else{
              return user;
            }
            
        }));

}
saveData(data,input){      
  //  console.log(input);         
    var currUser: {[k: string]: any} = {};
    currUser.accessToken= data.access_token,
    currUser.userId = data.user_id;
    currUser.version= "",// data.version;
    currUser.login_id = data.user_id;
    currUser.login_email = input;
    currUser.onMandatory=0;
    currUser.year=new Date().getFullYear();
    currUser.role=data.role;
    currUser.email = input;
    currUser.month = new Date().getUTCMonth() + 1; //months from 1-12
  var deviceInfo = JSON.parse(localStorage.getItem("deviceInfo"));

  if (this.dataService.isMobile) {
   currUser.device_id = deviceInfo.uuid;
    currUser.device_type = deviceInfo.platform;
    if (deviceInfo !== undefined) {
      currUser.device_Model = deviceInfo.device_Model;
      currUser.device_Manufacturer = deviceInfo.device_Manufacturer;
      currUser.device_Serial = deviceInfo.device_Serial;
      currUser.device_IPAddress = deviceInfo.device_IPAddress;
      currUser.device_MacAddress = deviceInfo.device_MacAddress;
    } else {
      currUser.device_Model = "";
     currUser.device_Manufacturer = "";
      currUser.device_Serial = "";
      currUser.device_IPAddress = "";
      currUser.device_MacAddress = "";
    }
  } else {
    currUser.device_id = "";
    currUser.device_type = "WEB";
    currUser.device_Model = "";
   currUser.device_Manufacturer = "";
    currUser.device_Serial = "";
    currUser.device_IPAddress = "";
    currUser.device_MacAddress = "";
  }
  var compConfig = data.companyConfig;
   // console.log(currUser);
   // currUser.type='retailer';
  this.helpFunction.setObj("currentuser", currUser);
  this.helpFunction.setObj("compConfig", compConfig);
}
logout(onMandatory?:any) {  
  var Userdata=this.helpFunction.getObj('currentuser');
 var input: {[k: string]: any} = {};
 input = JSON.parse(JSON.stringify(Userdata));
   input.Mandatory = (onMandatory==true) ? "YES" : "";
  var logout_url=environment.apiUrl+environment.paths.logout;       
  return this.http.post<any>(logout_url, input)
  .pipe(first())
  .subscribe(
      res=>{                  
          if (res.statusCode == GlobalVariable.api_success || res.dupLogin || res.statusCode == GlobalVariable.api_token_error) {                 
              this.helpFunction.removeObj('currentuser');
              this.helpFunction.removeObj('compConfig')
              localStorage.removeItem('currentUser');                     
              this.currentUserSubject.next(null);
              if(onMandatory == GlobalVariable.api_token_error || onMandatory == GlobalVariable.multipleUser_login){
                  this.router.navigate(['login']);
                  setTimeout(()=>{
                      this.alertService.error('Authorisation failed, Please login again.', true);
                  }), 10000;
              }else{this.router.navigate(['login']);}
          }                
      },
      error=>{
          console.log(error);
          this.alertService.error(error);
          
      });
}
}
