import { Component, Input, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {HelperService} from '../_helpers/helper';
import { Color } from 'ng2-charts';//charts
import {CommonService,UserAuthService} from '../_services';
import { GlobalVariable } from '../globalvariables';
import { Observable, interval } from 'rxjs';
import { first,startWith, take, map } from 'rxjs/operators';
import { NguCarouselModule } from '@ngu/carousel';
import moment from 'moment';
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl } from '@angular/forms';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { StarRatingComponent } from 'ng-starrating';
import * as $ from 'jquery';

@Component({
  selector: 'app-landing-screen',
  templateUrl: './landing-screen.component.html',
  styleUrls: ['./landing-screen.component.css'],
 // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingScreenComponent implements OnInit {
  currentuser:object;searchcasenumber:any;
  myreportObject:{[k: string]: any} = {};
  public reportCategories =[];
  public reportFeedback:{[k: string]: any} = {};
  public filepath :any;
  caseReportForm: FormGroup;
  submitted = false;
  public childReference:any;
  public ctrpt=1;
  public cttimeline=1;
  public yearmonths=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  @Input() name: string;
  imgags = [
    'assets/bg.jpg',
    'assets/car.png',
    'assets/canberra.jpg',
    'assets/holi.jpg'
  ];
  ////feedback carousel
  public carouselTileItems$: Observable<number[]>;
  //feedback carousel
  public carouselTileConfig: NguCarouselModule = {
    grid: { xs: 1, sm: 1, md: 1, lg: 5, all: 0 },
    speed: 5000,
    point: {
      visible: true
    },
    touch: true,
    loop: true,
    interval: { timing: 1500 },
    animation: 'lazy'
  };
  tempData: any[];

  constructor( private route: ActivatedRoute,private router: Router,public hs:HelperService,public cs:CommonService,private cdr: ChangeDetectorRef,private formBuilder: FormBuilder,public authservice:UserAuthService) { }
  public barChartOptions = {
    scaleShowVerticalLines: false,
    scaleShowHorizontalLines: true,
    responsive: true,
  }
  
  public barChartLabels:any =[];
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartColors: Color[] = [
    { backgroundColor: 'rgb(4, 37, 71)' }
  ];
  public barChartData = [
    {  barPercentage: 56,
      barThickness: 42,
      maxBarThickness:52,
      //minBarLength: 50,
      data: [], 
      label: '',
    }
  ];
  get h() { return this.caseReportForm.controls; }

  ngOnInit() {
    this.currentuser=this.hs.getObj("currentuser");   
    //console.log(this.currentuser);
    this.childReference=this.hs.getObj("reportViewCaseNumber");  
    //console.log(this.childReference); 
    this.caseReportForm = this.formBuilder.group({
      searchcasenumber: ['', Validators.required],
    });
    this.getBarInfo(0,this.hs.getQuarter(new Date()));
    
    // test carousel
    this.tempData = [];
    this.carouselTileItems$ = interval(5000).pipe( 
      startWith(-1),
      take(15),
      map(val => {
        const data = (this.tempData = [
          ...this.tempData,
          this.imgags[Math.floor(Math.random() * this.imgags.length)]
        ]);
        return data;
      })
    );
    // end
    this.getreportCategory();
    this.getuserfeedback();
  
    if(localStorage.getItem("report_anonymously")=="1"){
      this.openNav();
    }
  }

  openNav() {
    $("#myNav").css('width','100%');
  }

  /************ second section js End*******/

// report category
getreportCategory(){
  this.cs.reportCategory().pipe(first()).subscribe( res=>{
    if(res.status==GlobalVariable.api_success){
      this.myreportObject.category = res.data;     
      res.data.forEach((element,index) => {
       // this.barChartLabels.push(element.name)
        var temp={url:res.file_path+"/"+element.image,name:element.name,id:element.id};        
        this.reportCategories.push(temp);
      }); 
      //this.reportCategories = res.data;     
      this.filepath = res.file_path;     
    }
  });
}
// case number view report
caseviewReport(caseNo){
  this.submitted = true;
  if (this.caseReportForm.invalid) {
      console.log('form invalid'); 
        }else{
          // console.log(caseNo);
          // console.log(this.currentuser);
          if (this.currentuser == null || this.currentuser == undefined) {
            this.router.navigate(['/login']);
          }else{
            this.hs.setObj('viewReportCaseNo',caseNo);
            this.router.navigate(['/profile/myreport']);
          }
          
        }
}
  getBarInfo(type,qtr){     
    var request={quarter:qtr,reporttype_id:type,year:''};
    if(type>0){     
      request.reporttype_id=type;
    }
    if(qtr==5){
      request.year=moment().format('YYYY');
    }
    var qtrlabels=[];
    var qtrdata=[];
    this.cs.getCrimetrends(request).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.barChartData[0].data=[];
        this.barChartLabels=[];
        
        //console.log(res);                                                                     
      res.CrimeTrends.forEach((element,i) => {        
        if(type > 0){          
          qtrlabels.push(moment().month(element.month-1).format('MMM'));    
          qtrdata.push(element.reports_count); 
        }
        else{
          this.barChartLabels.push(element.name);   
          this.barChartData[0].data.push(element.reports_count);       
        } 
        
      });
      this.barChartData[0].label=moment(res.from).format('MMM Do ')+" - "+moment(res.to).subtract(1, 'days').format('MMM Do YYYY');
      }

      if(type > 0){        
        this.barChartData[0].data=[];
        this.barChartLabels=[];
        var str=0;var limit=3;
        if(qtr==1){
          str=0; limit=2;
        }
        else if(qtr==2){
          str=3; limit=5;
        }
        else if(qtr==3){
          str=6; limit=8;
        }
        else if(qtr==4){
          str=9; limit=11;
        }
        else if(qtr==5){
          str=0; limit=11;
        }
        for(let i=str;i<=limit;i++){          
          if(qtrlabels.includes(this.yearmonths[i])){
            this.barChartLabels.push(this.yearmonths[i]);
            this.barChartData[0].data.push(qtrdata[qtrlabels.indexOf(this.yearmonths[i])]);
          }
          else{
            this.barChartLabels.push(this.yearmonths[i]);
            this.barChartData[0].data.push(0);
          }
        }
      }

    });  
    


  }

  onCategoryChange(event){
    this.ctrpt=event.target.selectedIndex;   
    this.getBarInfo(this.ctrpt,this.cttimeline);
  }

  ontimelineChange(event){
    this.cttimeline=event.target.selectedIndex+1;
    // console.log(this.ctrpt);
    // console.log(this.cttimeline);
    this.getBarInfo(this.ctrpt,this.cttimeline);
  }

 // report a problem
 reportactivity(){
  this.router.navigate(['/quickreport']);
}
  //User Feedback
  getuserfeedback(){
    this.cs.userfeedback().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.reportFeedback=res;
        this.reportFeedback.length = res.Feedback.length
        //console.log(this.reportFeedback.length,this.reportFeedback);
        }
    });
  }
  navigateTo(id) {
    //console.log("click successful  " +id);
    this.router.navigate(['/reportCategories'],{ queryParams: { response: id } });
  }
}
