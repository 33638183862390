import { Component, OnInit } from '@angular/core';
import {PoliceServiceService } from '../../../app/_services';
import { first, map } from 'rxjs/operators';
import { GlobalVariable } from '../../globalvariables';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-safety',
  templateUrl: './safety.component.html',
  styleUrls: ['./safety.component.css']
})
export class SafetyComponent implements OnInit {
  safetyObject:{[k: string]: any} = {};
  constructor(public psService:PoliceServiceService,public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.safetyTipsDetails();
  }
  safetyTipsDetails(){
    this.psService.getsafetyTips().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.safetyObject.tips = res.safety_tips;
        this.safetyObject.file_path=res.file_path;
        //console.log(this.safetyObject.tips);
      }
    });
  }
  moreSafetyTips(tId){
    this.safetyObject.tip_id=tId;
    this.safetyTipsDetails();
    this.ngxSmartModalService.getModal('moreSafetyTipsModal').open();
  }
  closeModal(id:string){
    this.ngxSmartModalService.getModal(id).close();
  }
}
