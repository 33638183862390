import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators , ReactiveFormsModule, AbstractControl } from '@angular/forms';
import { UserAuthService } from '../_services';
import {HelperService} from '../_helpers/helper';
import { GlobalVariable } from '../globalvariables';
import { first } from 'rxjs/operators';
import * as $ from 'jquery';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  SubUser: FormGroup;
  submitted = false;
  messagee: string;
  currentuser:object;
  constructor(private route: ActivatedRoute,private router: Router,public authservice: UserAuthService, private formBuilder: FormBuilder,public hs:HelperService) { }
  ngOnInit()
  { 
     this.currentuser=this.hs.getObj("currentuser");
     console.log('----'+this.currentuser);
    this.SubUser = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z]+@[a-z.-]+\\.[a-z]{2,4}$')]],
});
  }
  get f() { return this.SubUser.controls; }
  registerUser(){
    this.submitted = true;
    if (this.SubUser.invalid) {
      console.log('form invalid'); 
      return;
        }
         else {

         var input = {"email_id": this.f.email.value};
         console.log(input);
           this.authservice.userSubscribe(input).pipe(first()).subscribe( res => {
            if (res.status == GlobalVariable.api_success) {
               this.messagee = "Thanks for Subscribing Our NewsLetter";
               setTimeout(() => {(this.messagee="")}, 3000);
             
            }
          });
         
        }
      }

/*anonymous report*/
   closeNav() {
    $("#myNav").css('width','0%');
    localStorage.removeItem("report_anonymously")
  }
   openNav2() {
    $("#myNavtwo").css('width','100%');
  }
  
   closeNav2() {
    $("#myNavtwo").css('width','0%');
  }
  // suspicious popup
   openNav1() {
    $("#myNavone").css('width','100%');
  }
  
   closeNav1() {
    $("#myNavone").css('width','0%');
  }

  anonymouslogout(suspicious,witness){
    this.hs.removeObj('currentuser');
    localStorage.removeItem('currentuser');
    window.sessionStorage.clear();
    this.hs.setObj('suspiciousReport',suspicious);
    this.hs.setObj('secretWitness',witness);
    //console.log(suspicious,witness);
     // check is Use is login 
      this.authservice.userIsActive();

      this.closeNav();
      this.closeNav2();
      this.closeNav1();
    if(suspicious == '' && witness == ''){
      this.router.navigate(['/quickreport']);
    }else{
      // this.router.navigate(['/anonymousReport']);
      this.redirectTo("/anonymousReport");
    }
    localStorage.removeItem("report_anonymously");
  }
  redirectTo(uri) {
    this.router.navigateByUrl('/',{skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }


 
  reportactivity(suspicious,witness){
    if(this.currentuser != null && suspicious != '' && witness != ''){
    this.hs.removeObj('currentuser');
    localStorage.removeItem('currentuser');
    window.sessionStorage.clear();
    //window.location.reload();
    this.currentuser = this.hs.getObj('currentuser');
    this.hs.setObj('currentuser',this.currentuser);
   // console.log(this.hs.getObj('currentuser'));
    this.hs.setObj('suspiciousReport',suspicious);
    this.closeNav();
    this.closeNav2();
    this.closeNav1();

        this.hs.setObj('secretWitness',witness);
        console.log(suspicious,witness);
        if(suspicious == '' && witness == ''){
          this.router.navigate(['/quickreport']);
        }else if(this.hs.getObj('currentuser')==null){
          console.log('no user exists');
          this.router.navigate(['/anonymousReport']);
        }
        else{
          this.router.navigate(['/anonymousReport']);
        }   
    }else{
    this.hs.setObj('suspiciousReport',suspicious);
    this.hs.setObj('secretWitness',witness);
    //console.log(suspicious,witness);
    if(suspicious == '' && witness == ''){
      this.router.navigate(['/quickreport']);
    }else{
      this.router.navigate(['/anonymousReport']);
    }
    localStorage.removeItem("report_anonymously");
  }
  }

/*anonymous report*/
}
