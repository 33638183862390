import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
// translate
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '././_helpers/auth.interceptor';

// helper service
import {HelperService} from './_helpers/helper';
// global variables
import {DataService} from './globalvariables';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLayoutModule } from './app-layout/app-layout.module';
import { GlobalactivityModule } from './globalactivity/globalactivity.module';
import { ContactModule } from './contact/contact.module';
import { CommunityServiceModule } from './community-service/community-service.module';
import { PoliceServiceModule } from './police-service/police-service.module';

import { NguCarouselModule } from '@ngu/carousel';
// forgot-password
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
// custom navbar
import { LoginNavbarComponent } from './login-navbar/login-navbar.component';

import { FlashMessagesModule } from 'angular2-flash-messages';
import { QuickreportModule } from './quickreport/quickreport.module';
import { KnowareaModule } from './knowarea/knowarea.module';
import { ReportCategoriesModule } from './report-categories/report-categories.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ChartsModule } from 'ng2-charts';//high charts
import {AgmCoreModule} from "@agm/core";//map loc search
// components
import { MyreportComponent } from './profile/myreport/myreport.component';
import { LandingScreenComponent } from './landing-screen/landing-screen.component';
import { FeedbackComponent } from './landing-screen/feedback/feedback.component';
import { KnowareaComponent } from './knowarea/knowarea.component';
import { PinredflagComponent } from './pinredflag/pinredflag.component';
import { HelplineComponent } from './helpline/helpline.component';
import { QuickreportComponent } from './quickreport/quickreport.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { GlobalactivityComponent } from './globalactivity/globalactivity.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FireComponent } from './contact/fire/fire.component';
import { AreaComponent } from './community-service/area/area.component';
import { AdviceComponent } from './community-service/advice/advice.component';
import { CommunityfeedbackComponent } from './community-service/communityfeedback/communityfeedback.component';
import { CitizenalertComponent } from './police-service/citizenalert/citizenalert.component';
import { PermitComponent } from './police-service/permit/permit.component';
import { SafetyComponent } from './police-service/safety/safety.component';
import { FindpshComponent } from './police-service/findpsh/findpsh.component';
import { AlertComponent } from './_components/alert.component';
import {PermitCategoryComponent} from './police-service/permit/permit-category/permit-category.component';

// custom file upload pipe
import { UploadiavPipe } from './uploadiav.pipe';
// carousel slider
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import {MatVideoModule} from 'mat-video';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ReportAnonymousComponent} from './report-anonymous/report-anonymous.component';
// report categories
import { KidnapComponent } from './report-categories/kidnap/kidnap.component';
// rating
import { RatingModule } from 'ng-starrating';
// angular material
import {MaterialModule} from './material-module';
import {MatNativeDateModule} from '@angular/material/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
// datepicker for a month
import { DateTimePickerModule  } from '@syncfusion/ej2-angular-calendars';
//pagination
//import { JwPaginationComponent } from 'jw-angular-pagination';
import {PshContactsComponent} from './police-service/psh-contacts/psh-contacts.component';
import { MyprofileComponent } from './profile/myprofile/myprofile.component';
import { SearchBarComponent } from './search-bar/search-bar.component';//my profile

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    NavbarComponent,
    FooterComponent,
    BreadcrumbComponent,
    LoginNavbarComponent,
    AlertComponent,PshContactsComponent,
    MyreportComponent,
    MyprofileComponent,
    //JwPaginationComponent,
    LandingScreenComponent,FeedbackComponent,
    KnowareaComponent,HelplineComponent,PinredflagComponent,QuickreportComponent,
    AboutComponent,AlertComponent,
    RegisterComponent,LoginComponent,GlobalactivityComponent,ForgotPasswordComponent,
    FireComponent,
    AreaComponent, AdviceComponent, CommunityfeedbackComponent,
    CitizenalertComponent, PermitComponent, SafetyComponent, FindpshComponent,PermitCategoryComponent,
    UploadiavPipe,ReportAnonymousComponent,
    KidnapComponent,
    SearchBarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    MatVideoModule,
    NgxAudioPlayerModule,
    NguCarouselModule,
    DateTimePickerModule ,
     NgxDaterangepickerMd.forRoot({
      format: 'DD/MM/YYYY',
      direction: 'ltr',   
      separator: ' To ', 
      cancelLabel: 'Cancel', 
      applyLabel: 'Okay', 
      clearLabel: 'Clear'   
     }),
    FlashMessagesModule.forRoot(), //flash message
    // ngx-translate and the loader module       
       TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    AppLayoutModule,
    GlobalactivityModule,
    ContactModule,
    CommunityServiceModule,
    PoliceServiceModule,
    ForgotPasswordModule,// forgot-password
    Ng2CarouselamosModule,
    QuickreportModule,
    KnowareaModule, 
    ReportCategoriesModule,
    ChartsModule,
    NgxSmartModalModule.forRoot(),
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA1C4_YZHU9LLFjwCiwFobpgCREkQUv44Q',
    }),
    RatingModule,MaterialModule,MatNativeDateModule
  ],
  providers: [
    HelperService,DataService,
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  //return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}