import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { CommonService,AlertService } from '../_services';
import { first } from 'rxjs/operators';
import { GlobalVariable } from '../globalvariables';
import { FormBuilder, FormGroup, Validators ,ReactiveFormsModule,AbstractControl, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// maps
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { google } from "google-maps";
import * as $ from 'jquery';
import { NgxSmartModalService } from 'ngx-smart-modal';
declare var google : google;

@Component({
  selector: 'app-knowarea',
  templateUrl: './knowarea.component.html',
  styleUrls: ['./knowarea.component.css']
})
export class KnowareaComponent implements OnInit {
  knowAreaObject:{[k: string]: any} = {};
  HarassmentObject:{[k:string]:any} ={};
  knowAreaForm: FormGroup;
  submitted = false;
  Harassment = true;
  Violence = false;
  Accidents = false;
  ciyslist:any=[];
  marker:any=[];
  // map
  latitude: any;
  longitude: any;
  zoom: number;
  address: string;
  size: any;
  length: number;
  cases: number;
  private geoCoder;
  @ViewChild("search",{static:true})
  public searchElementRef: ElementRef;
  public localActivityDetails:{[k: string]: any} = {};

  constructor(public cs:CommonService,private router: Router,private formBuilder: FormBuilder,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,public ngxSmartModalService: NgxSmartModalService,public as:AlertService) {
    this.knowAreaObject.date="";
    this.knowAreaObject.harassCount=0;
    this.knowAreaObject.violenceCount=0;
    this.knowAreaObject.accidentsCount=0;
   }
  get f() { return this.knowAreaForm.controls; }

  ngOnInit() {
    this.marker=[];
    this.getlocationDetails();

     this.knowAreaForm = this.formBuilder.group({
      place : ['', Validators.required],
     });
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude =  GlobalVariable.latitude; 
        this.longitude = GlobalVariable.longitude;
        this.marker=[this.latitude,this.longitude ]
        //console.log(this.marker)
        //alert("hi")
        //console.log(this.latitude,this.longitude)
        this.zoom = 5;
      });
    }
  this.getlocalActivity(); 
  //get local activities
  
   
// //   //           //load Places Autocomplete
// //   //           this.mapsAPILoader.load().then(() => {
// //   //             this.setCurrentLocation();
// //   //             this.geoCoder = new google.maps.Geocoder;
        
// //   //             let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
// //   //               types: ["address"]
                
// //   //             });
// //   //             autocomplete.addListener("place_changed", () => {
// //   //               this.ngZone.run(() => {
// //   //                 //get the place result
// //   //                 let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        
// //   //                 //verify result
// //   //                 if (place.geometry === undefined || place.geometry === null) {
// //   //                   return;
// //   //                 }
        
// //   //                 //set latitude, longitude and zoom
// //   //                 this.latitude = place.geometry.location.lat();
// //   //                 this.longitude = place.geometry.location.lng();
// //   //                 this.zoom = 12;
// //   //                 this.getAddress(this.latitude, this.longitude);
// //   //               });
// //   //             });
// //   //           });
    
// //   //           $('.maplocinput').val('');
// //   //           // map end

             
// //   // }
// // // Get Current Location Coordinates
// // private setCurrentLocation() {
// //   if ('geolocation' in navigator) {
// //     navigator.geolocation.getCurrentPosition((position) => {
// //       this.latitude = position.coords.latitude;
// //       this.longitude = position.coords.longitude;
// //       this.zoom = 8;
// //       this.getAddress(this.latitude, this.longitude);
// //       $('.maplocinput').val('');
// //     });
// //   }
// // }

// //draggable events part
// markerDragEnd($event: MouseEvent) {
//   //console.log($event);
//   this.latitude = $event.coords.lat;
//   this.longitude = $event.coords.lng;
//   this.getAddress(this.latitude, this.longitude);
// }

// getAddress(latitude, longitude) {
//   //console.log(latitude,longitude);
//   this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
//     // console.log(results);
//     // console.log(status);
//     if (status === 'OK') {
//       if (results[0]) {
//         this.zoom = 12;
//         this.address = results[0].formatted_address;
//         //console.log(this.address);
//         this.getmapDetails(latitude,longitude,this.address);
//         $('.maplocinput').val(this.address);
//       } else {
//         window.alert('No results found');
//       }
//     } else {
//       window.alert('Geocoder failed due to: ' + status);
//     }

//   });
// }
// getmapDetails(latitude,longitude,address) {
//   this.knowAreaObject.latitude = latitude;
//   this.knowAreaObject.longitude = longitude;
//   this.knowAreaObject.address = address;
//   this.knowAreaObject.locationaddr = address;
//   console.log(this.knowAreaObject.latitude,this.knowAreaObject.longitude);
}
// // maps end

  // form submit
  // knowAreaSubmit(form:NgForm) {
  //   console.log(form);
  //   this.submitted = true;
  //   //console.log(this.knowAreaForm.value);
  //   //console.log(this.image);
  //   if (this.knowAreaForm.invalid) {
  //       console.log('form invalid'); 
  //   } else {
  //     this.knowAreaForm.value.place = this.knowAreaObject.address;
  //     this.knowAreaObject.locationaddr = this.knowAreaObject.address;
  //     var input={ "latitude":this.knowAreaObject.latitude,"longitude":this.knowAreaObject.longitude};
  //    // console.log(input);
  //     this.cs.getAreaDetails(input).pipe(first()).subscribe( res=>{
  //   //   console.log(res);
  //       if(res.status==GlobalVariable.api_success) {
  //         this.knowAreaObject.data = res.AreaDetails;
  //        // console.log(this.knowAreaObject.data);
  //         this.knowAreaObject.maplocations = res.Reports_data;
  //       //  console.log(res.Reports_data);
  //       } else if(res.status==GlobalVariable.serverError || res.status==GlobalVariable.unauthorizedError || res.status==GlobalVariable.api_error || res.status==GlobalVariable.api_token_error || res.status==GlobalVariable.api_unregisteredEmail || res.status==GlobalVariable.api_invalidToken || res.status==GlobalVariable.api_inputvalid_error) {
  //         this.as.error(res.error);
  //       } else { 
  //         this.as.error(res.error);
  //         }
  //     }, error=>{
  //       this.as.error(error); 
  //     });
  //   }
  // }
  knowAreaSubmit() {
    console.log();
   this.submitted = true;
    //console.log(this.knowAreaForm.value);
   //console.log(this.image);
   if (this.knowAreaForm.invalid) {
       console.log('form invalid'); 
   }
 }
  // get local activities
  getlocalActivity() {
    this.cs.getlocalActivities().pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success) { 
        this.localActivityDetails=res;
        console.log(this.localActivityDetails);
       }
    });
  }
   // ngx-smart-modals
   prevAdvice() {
    this.ngxSmartModalService.getModal('prevAdviceModal').open();
  }
    localActivity(id) {
      this.knowAreaObject.id=id;
      this.ngxSmartModalService.getModal('localActivityModal').open();
    }
    
    closeModal(id:string) {
      this.submitted= false;
      this.ngxSmartModalService.getModal(id).close();
    }
  // ngx-smart-modals end
  getlocationDetails(){
    var input=  {"latitude":GlobalVariable.latitude, "longitude":GlobalVariable.longitude };
    this.cs.getDefaultLocation(input).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.getCityList(res.location.country_id);
       // console.log(res);
      }
    });
  }



  getCityList(cid){
    let req={"country_id":cid }
    this.cs.getcitiesList(req).pipe(first()).subscribe( res=>{
      if(res.status==GlobalVariable.api_success){
        this.ciyslist=res.cities;
        this.address=res.cities[0].city_name;
       // console.log(res);
        if(res.cities[0].city_name!="")
          this.getTopReportedCrimes(res.cities[0]); 

          this.switchTab(1);
      }
    });
  }


  selectCityName(address){
    var list=this.ciyslist.filter(c=>c.city_name==address);
     this.getTopReportedCrimes(list[0]);
  }

   getTopReportedCrimes(address){     
        this.knowAreaObject.harassMarks=[];
         this.knowAreaObject.violenceMarks=[];
         this.knowAreaObject.accidentsMarks=[];
      let input={"city_id":address.city_id};      
       this.cs.getAreaDetails(input).pipe(first()).subscribe( res=> {
         if(res.status==GlobalVariable.api_success) {
           //date 
           this.knowAreaObject.date=new Date(res.year, res.month).toLocaleString('en',{month:"long"})+ '  '+res.year;
           /*Reports Count*/
         this.knowAreaObject.harassCount=res.count.harassment_count;
         this.knowAreaObject.violenceCount=res.count.violence_count;
         this.knowAreaObject.accidentsCount=res.count.accidents_count;
        // alert(this.knowAreaObject.accidentsCount);
         /*Get cordinates*/
         let details=res.area_details; 
         if(this.knowAreaObject.harassCount == 0){ 
          this.knowAreaObject.harassMarks.push({lat: GlobalVariable.lat,lang: GlobalVariable.long})
        }else{              
     
         details.harassment.forEach(item => { 
               this.knowAreaObject.harassMarks.push({lat: item.latitude,lang: item.longitude})
               });
              }
               if(this.knowAreaObject.violenceCount == 0){ 
              this.knowAreaObject.violenceMarks.push({lat: GlobalVariable.lat,lang: GlobalVariable.long})
             }else{              
    
         details.violence.forEach(item => {            
               this.knowAreaObject.violenceMarks.push({lat: item.latitude,lang: item.longitude})

               });
             }
               if(this.knowAreaObject.accidentsCount == 0){  
                this.knowAreaObject.accidentsMarks.push({lat: GlobalVariable.lat,lang: GlobalVariable.long})
              }else{              
    
                  details.accidents.forEach(item => { 
               this.knowAreaObject.accidentsMarks.push({lat: item.latitude,lang: item.longitude})
            });
              }
         }else{
        this.as.error(res.message);
       }
       });
   }


  // // onHarassment(address) {    
  // //   this.Harassment  = true;
  // //   this.Violence = false;
  // //   this.Accidents = false;
  // // //  console.log("harassement");
  // //   let input={"city_id":address.city_id};
  // //  // console.log(input)
  // //      this.cs.getAreaDetails(input).pipe(first()).subscribe( res=> {
  // //        console.log(res);
  // //         if(res.status==GlobalVariable.api_success) {
  // //         // date  localisation 
  // //         this.zoom = 5;
  // //         alert("hi")
  // //          this.knowAreaObject.date=new Date(res.year, res.month).toLocaleString('en',{month:"long"})+ '  '+res.year;

  // //          this.HarassmentObject.data = res.area_details.harassment;  
  // //          this.HarassmentObject.num =res.count.harassment_count; 
  // //         /* console.log(this.HarassmentObject.data);  
  // //          console.log(this.HarassmentObject.num);*/
  // //          if(res.area_details.harassment.length>0){
  // //           this.latitude =  this.HarassmentObject.data[0].latitude;
  // //           this.longitude = this.HarassmentObject.data[0].longitude;
  // //           //console.log( this.longitude , this.latitude);
  // //           this.marker =[];
  // //             res.area_details.harassment.forEach(item => { 
  // //              this.marker.push({lat: item.latitude,lang: item.longitude})
  // //              });
               
  // //             this.zoom = 5;
  // //             alert(this.zoom)
  // //         }
  // //         //console.log(this.marker);
       
 
  // //        }          
  // //      });
  // //   }
  
  
  // onViolence(address) {

  //   this.Harassment= false;
  //   this.Violence = true;
  //   this.Accidents = false;
  //   console.log("Violence")
  //   var input={"city_id":address.city_id };

  //   this.cs.getAreaDetails(input).pipe(first()).subscribe( res=> {
  //      console.log(res);
  //      if(res.status==GlobalVariable.api_success) {
  //       this.HarassmentObject.details = res.area_details.violence;
  //       this.HarassmentObject.size =res.count.violence_count;
  //       console.log(this.HarassmentObject.details); 
  //       console.log(this.HarassmentObject.size);
  //       if(res.area_details.violence.length>0){
  //         this.latitude =  this.HarassmentObject.details[0].latitude;
  //         this.longitude = this.HarassmentObject.details[0].longitude;
  //         console.log( this.longitude , this.latitude);
  //         this.marker=[];
  //           res.area_details.violence.forEach(item => { 
  //            this.marker.push({lat: item.latitude,lng: item.longitude})
  //            });
  //           this.zoom = 5;
  //           alert(this.zoom)
  //       }
  //       console.log(this.marker);
     
        
  //     }          
  //   });
  // }
  // onAccidents(address) {
  //   this.Harassment=false;
  //   this.Violence = false;
  //   this.Accidents = true;
  //   console.log("Accident")
  //   var input={"city_id":address.city_id };

  //   this.cs.getAreaDetails(input).pipe(first()).subscribe( res=> {
  //      console.log(res);
  //      if(res.status==GlobalVariable.api_success) {
          


  //        this.HarassmentObject.cases = res.area_details.accidents;  
  //        this.HarassmentObject.count =res.count.accidents_count; 

  //        if(res.area_details.accidents.length>0){
  //         this.latitude =  this.HarassmentObject.cases[0].latitude;
  //         this.longitude = this.HarassmentObject.cases[0].longitude;
  //         console.log( this.longitude , this.latitude);
  //         this.marker=[];
  //           res.area_details.accidents.forEach(item => { 
  //            this.marker.push({lat: item.latitude,lng: item.longitude})
  //            });
  //           this.zoom = 5;
  //       }
  //       console.log(this.marker);
     
         
  //     }          
  //   });
  // }


  /***switch Tab
     1:Harassment
     2:Violence
     3:Accidents
    */
  switchTab(tabindex){
    this.marker=[];
    this.zoom =1;
    
   if(1 === tabindex){
    this.marker=this.knowAreaObject.harassMarks;
    //console.log(this.marker);
   this.Harassment= true;
    this.Violence = false;
    this.Accidents = false;
   }else if(2 === tabindex){
     this.marker=this.knowAreaObject.violenceMarks;
    // console.log(this.marker);  
    this.Harassment= false;
    this.Violence = true;
    this.Accidents = false;
   }else{
    this.marker=this.knowAreaObject.accidentsMarks;
   // console.log(this.marker);
    this.Harassment= false;
    this.Violence = false;
    this.Accidents = true;
   }

 //console.log(this.marker,this.marker[0].lat,this.marker[0].lang);
 
  }

}

